/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */

const timestampToTime = (timestamp) => {
  let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}
/**
 * 存储localStorage
 */
const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
const getStore = (name) => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
const removeStore = (name) => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
  let date = new Date()
  date.setDate(date.getDate() + day)
  document.cookie = name + '=' + value + ';expires=' + date
}

/**
 * 获取cookie
 **/
function getCookie(name) {
  let reg = RegExp(name + '=([^;]+)')
  let arr = document.cookie.match(reg)
  if (arr) {
    return arr[1]
  } else {
    return ''
  }
}

/**
 * 删除cookie
 **/
function delCookie(name) {
  setCookie(name, null, -1)
}
/**
 * 根据路由name 和 path 来获取自定义的路由数据
 **/
function findRouter(name, path, have) {
  let allmenu = JSON.parse(localStorage.getItem('menu'))
  let url = path,
    findData = {}
  if (have) {
    url = path.substr(1)
  }
  findWantData(allmenu, findData, {
    name,
    url,
  })
  return findData.data
}
/**
 * 递归 路由
 **/
// ！！！！不要用foreach 改一下
function findWantData(datas, findData, wantData) {
  datas.forEach((item) => {
    if (item.children) {
      return findWantData(item.children, findData, wantData)
    } else {
      if (item.text == wantData.name && item.url == wantData.url) {
        findData.data = JSON.parse(JSON.stringify(item))
        return findData
      }
    }
  })
}

const getAllChildren = (list) => {
  return list.map((item) => {
    if (item.children.length) {
      return getAllChildren(item.children)
    }
    return item
  })
}

/**
 * 导出
 **/
export {
  timestampToTime,
  setStore,
  getStore,
  removeStore,
  setCookie,
  getCookie,
  delCookie,
  findRouter,
  findWantData,
  getAllChildren,
}
