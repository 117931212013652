<template>
  <div class="el-main__mdgMainTable" style="width: 100%; height: 100%">
    <div class="mainct">
      <el-table
        :highlight-current-row="true"
        :data="options.dataList"
        default-expand-all
        v-loading="options.tableLoading"
        :height="options.height"
        :tree-props="{ children: 'children' }"
        style="width: 100%"
        @selection-change="__handleSelectionChange"
        @sort-change="__changeTableSort"
        @row-dblclick="__rowDblclick"
        @row-click="__rowClick"
        @select="__select"
        @select-all="__selectAll"
        @row-contextmenu="handleRightClick"
        size="mini"
        ref="table"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        :row-key="(row) => row.id"
      >
        <el-table-column
          v-if="multiSelect"
          :key="9999"
          type="selection"
          header-align="center"
          align="center"
          width="50"
          :reserve-selection="memberTypeFlag ? true : false"
        >
        </el-table-column>

        <template v-for="(item, index) in options.theads">
          <template v-if="item.slotName != null">
            <el-table-column
              v-if="item.label == '操作'"
              fixed="right"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              :show-overflow-tooltip="true"
              :formatter="item.formatter"
              :sortable="item.sortName != null"
              :width="item.width"
            >
              <template slot-scope="scope">
                <div @click.stop="__clickStop" style="display: inline">
                  <slot :name="item.slotName" v-bind:item="scope.row"></slot>
                </div>
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column
              :key="index"
              :label="item.label"
              :prop="item.prop"
              :show-overflow-tooltip="true"
              :formatter="item.formatter"
              :sortable="item.sortName != null"
              :min-width="item.width"
              align="center"
            >
              <template v-slot="scope">
                {{ scope.row[item.prop] }}
              </template>
            </el-table-column>
          </template>
        </template>
        <el-table-column
          type="expand"
          v-if="options.expandHtml != null"
          align="center"
        >
          <template>
            <div v-html="options.expandHtml"></div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- Footer Area -->
    <div v-if="!hidePage">
      <el-pagination
        style="text-align: right; margin-top: 20px"
        @size-change="__sizeChangeHandle"
        @current-change="__currentChangeHandle"
        :current-page="options.page"
        :page-sizes="[20, 50, 100]"
        :page-size="options.size"
        :total="options.total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <div
      v-if="contextMenuVisible"
      ref="contextMenu"
      class="custom-context-menu"
      @click.stop="hideContextMenu"
      :style="{
        top: contextMenuPosition.top + 'px',
        left: contextMenuPosition.left + 'px',
      }"
    >
      <!-- 自定义菜单内容 -->
      <div class="menu" @click="reset()">刷新</div>
      <!-- <div class="menu" @click="addUpdate">编辑</div> -->
      <!-- <div class="menu" @click="handleRow">删除</div> -->
      <div class="menu" @click="handleCopy">复制</div>
      <div class="menu" @click="handleAffix">粘贴</div>
      <!-- 其他菜单项 -->
    </div>
  </div>
</template>

<script>
// import http from "../../utils/aixos";
// import { userListList, getAllList } from '@/api/ajax/index'
import http from '@/api/ajax/aixos'
import { getUserMenuBtn } from '@/api/ajax/request'
export default {
  data() {
    return {
      text: null,
      copyText: null,
      event: null,
      row: null,
      contextMenuVisible: false, // 控制自定义菜单的显示与隐藏
      contextMenuPosition: { top: 0, left: 0 }, // 自定义菜单的位置
      searchForm: {},
      options: {
        tableLoading: true,
        data: {},
        dataList: [],
        dataListSelected: [], //选中添加的会员类型
        expendHtml: null,
        theads: [],
        page: 1,
        limit: 20,
        order: null,
        orderKey: null,
        total: 0,
        url: '',
        multipleSelection: [],
        height: null,
        minHeight: null,
      },
      searchParams: {},
    }
  },
  props: {
    tableData: {
      type: Object,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    hidePage: {
      type: Boolean,
      default: false,
    },
    afterSelect: {
      type: Function,
    },
    auth: {
      type: Boolean,
      default: true,
    },
    memberTypeFlag: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '350px',
    },
  },
  components: {},
  created() {
    this.getUserMenuBtn()
    this.__initTable(this.tableData)
    this.options.tableLoading = false
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  },
  mounted() {},
  methods: {
    // 获取按钮权限
    async getUserMenuBtn() {
      let path = ''
      if (this.$route.fullPath.indexOf('?') > 0) {
        path = this.$route.fullPath.split('?')[0]
      } else {
        path = this.$route.fullPath
      }
      const res = await getUserMenuBtn({
        path: path,
      })
      if (res.code == 200) {
        let btnData = {
          btnStr: res.data.btnStr || '',
          menuId: res.data.menuId || '',
        }
        localStorage.setItem('btnManage', JSON.stringify(btnData))
        this.$store.commit('wdstore/setBtnManage', btnData)
      }
    },
    //右击复制
    handleCopy() {
      console.info(this.event.target.innerText)
      // 拼接要复制的内容
      const copyText = `${this.event.target.innerText}` // 根据需要调整格式
      this.copyText = this.event.target.innerText
      this.copyToClipboard(copyText)
      // 复制内容到剪贴板
    },
    //粘贴
    handleAffix() {
      console.info(this.copyText) //粘贴文本
      console.info(this.text) //粘贴字段
      this.$nextTick(() => {
        this.searchForm[this.text] = this.copyText
        console.info(this.searchForm, 'this.searchForm')
        this.searchForm = { ...this.searchForm }
        // this.this.copyText = ''
      })
    },
    //input 点击
    handleFocus(e, text) {
      this.text = text
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        // 检查浏览器是否支持剪贴板 API
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.$message({ message: '内容已复制到剪贴板', type: 'success' })
          })
          .catch((err) => {
            console.error('无法复制内容到剪贴板:', err)
          })
      } else {
        // 如果浏览器不支持剪贴板 API，可以使用其他方法，比如创建一个临时的 textarea 并选中复制
        const textArea = document.createElement('textarea')
        textArea.style.position = 'fixed'
        textArea.style.top = 0
        textArea.style.left = 0
        textArea.style.width = '2em'
        textArea.style.height = '2em'
        textArea.style.padding = 0
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'
        textArea.style.background = 'transparent'
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
          const successful = document.execCommand('copy')
          const msg = successful ? '成功复制' : '复制失败'
          this.$message({
            message: msg,
            type: successful ? 'success' : 'warning',
          })
        } catch (err) {}

        document.body.removeChild(textArea)
      }
    },
    handleRightClick(row, column, event) {
      this.event = event
      event.preventDefault()
      this.contextMenuVisible = true // 显示自定义菜单
      const rect = event.target.getBoundingClientRect()
      // this.copyToClipboard(rect)
      this.contextMenuPosition = {
        top: event.clientY + window.scrollY,
        left: event.clientX + window.scrollX,
      }
      this.row = row
      this.$nextTick(() => {})
    },
    hideContextMenu() {
      this.contextMenuVisible = false // 隐藏自定义菜单
    },

    clearSearchItem() {
      // searchForm某一项已经清楚了，直接调用enter方法携带剩余参数请求接口
      this.handleEnter()
    },
    reset() {
      this.searchForm = {}
      this.refresh(this.searchForm)
      this.contextMenuVisible = false
    },
    handleEnter() {
      let params = {
        ...this.searchForm,
        limit: this.options.limit,
        page: this.options.page,
      }
      this.refresh(params)
    },
    __calculateHeight() {
      // 根据屏幕高度算表格高度
      if (window.innerHeight < 800) {
        this.options.maxHeight = 350
        this.options.height = '100%'
        this.options.minHeight = '350px'
      } else {
        this.options.maxHeight = 500
        this.options.height = '100%'
        this.options.minHeight = '446px'
      }
    },
    __initTable(tableData) {
      this.__calculateHeight()
      let data = JSON.parse(JSON.stringify(tableData))
      data.theads.forEach((ele) => {
        let length = ele.label.length
        if (!ele.width) {
          if (length >= 6) {
            ele.width = 130
          } else {
            ele.width = 90
          }
        }
      })
      Object.assign(this.options, data)
    },
    __sizeChangeHandle(val) {
      this.options.limit = val
      if (this.options.dataListSelected.length != 0) {
        if (val == 10) {
          this.options.dataList = this.options.dataListSelected.slice(0, 10)
        } else if (val == 20) {
          this.options.dataList = this.options.dataListSelected.slice(0, 20)
        } else if (val == 30) {
          this.options.dataList = this.options.dataListSelected.slice(0, 30)
        }
      }
      this.options.page = 1
      this.options.order = null
      this.options.orderKey = null
      this.refresh()
    },
    __handleSelectionChange(val) {
      this.options.multipleSelection = val
      if (this.afterSelect != null) {
        this.afterSelect()
      }
    },
    // 当前页
    __currentChangeHandle(val) {
      this.options.page = val
      if (this.options.dataListSelected.length != 0) {
        //已选会员类型列表不为空
        if (this.options.size == 10) {
          this.options.dataList = this.options.dataListSelected.slice(
            (val - 1) * 10,
            (val - 1) * 10 + 10
          )
        } else if (this.options.size == 20) {
          this.options.dataList = this.options.dataListSelected.slice(
            (val - 1) * 20,
            (val - 1) * 20 + 20
          )
        } else if (this.options.size == 30) {
          this.options.dataList = this.options.dataListSelected.slice(
            (val - 1) * 30,
            (val - 1) * 30 + 30
          )
        }
      }
      this.refresh()
    },
    // 排序
    __changeTableSort(val) {
      // 获取字段名称和排序类型
      this.options.page = 1
      if (val.order === 'ascending') {
        this.options.orderKey = val.sortName
        this.options.order = 'asc'
      } else if (val.order === 'descending') {
        this.options.orderKey = val.sortName
        this.options.order = 'desc'
      } else {
        this.options.order = null
        this.options.orderKey = null
      }
      this.refresh()
    },
    // 双击
    __rowDblclick(row) {
      if (this.options.dblclick) {
        this.options.dblclick(row)
      }
    },
    rowHandle(row, column, event) {
      console.info(row)
    },
    __rowClick(row, column, event) {
      this.contextMenuVisible = false
      this.$emit('dataReceived', row)

      if (!this.multiSelect) {
        // 单选
        if (
          this.$refs.table.selection == null ||
          this.$refs.table.selection[0] !== row
        ) {
          this.$refs.table.clearSelection()
          this.$refs.table.toggleRowSelection(row)
        } else {
          if (this.$refs.table.selection.length > 0) {
            this.$refs.table.setCurrentRow()
          }
          this.$refs.table.toggleRowSelection(row)
        }
      } else {
        // 多选
        this.$refs.table.toggleRowSelection(row)
      }
    },
    __select(selection, row) {
      if (!this.multiSelect) {
        // 单选
        this.$refs.table.clearSelection()
        if (selection.length === 0) {
          return
        }
        this.$refs.table.setCurrentRow(row)
        this.$refs.table.toggleRowSelection(row, true)
      } else {
        // 多选
        this.$refs.table.setCurrentRow(row)
      }
      if (this.afterSelect != null) {
        this.afterSelect()
      }
    },
    __selectAll(selection) {
      if (!this.multiSelect) {
        // 单选
        if (selection.length === 0) {
          return
        }
        let row = selection[0]
        this.$refs.table.clearSelection()
        this.$refs.table.setCurrentRow(row)
        this.$refs.table.toggleRowSelection(row, true)
      }
    },
    __clickStop: function () {
      // 该方法为了阻止冒泡事件，没什么软用
    },
    // 获取当前选项
    getSelectRow() {
      return this.$refs.table.selection
    },
    // 只获取一条，用于单选表格
    getSingleSelectRow() {
      return this.$refs.table.selection[0]
    },
    query(params) {
      if (this.$listeners['beforeQuery']) {
        this.$emit('beforeQuery')
      }
      this.options.tableLoading = true
      let searchParams = {}
      if (params != null) {
        // 这里要clone下对象，不能直接操作外部数据
        searchParams = JSON.parse(JSON.stringify(params))
      }
      if (this.options.url == null) {
        this.options.tableLoading = false
        return
      }
      for (const key in searchParams) {
        if (searchParams[key] === '' || searchParams[key] === null) {
          delete searchParams[key]
        }
      }
      this.searchParams = searchParams

      Object.assign(params)
      // console.info({ ...params, limit: 10, page: 1 }, "params---------");
      let paramss = {
        ...params,
        ...this.searchForm,
        limit: this.options.limit,
        page: this.options.page,
        visitPath: this.$route.path,
      }
      http
        .post(this.options.url, paramss)
        .then((data) => {
          if (data && data.code == 200) {
            this.options.dataList = data.data.list || data.data
            this.options.total = data.data.total
            this.options.data = data.data.list
          } else {
            this.options.dataList = []
            this.options.totalPage = 0
            // this.$alert(data.msg);
          }
          this.options.tableLoading = false
          if (this.$listeners['afterQuery']) {
            this.$emit('afterQuery')
          }
          // 修改错位问题
          this.$nextTick(() => {
            this.$refs.table.doLayout()
          })
        })
        .catch((e) => {
          if (e !== null && e.toString().endsWith('exceeded')) {
            // this.$message.warning("请求超时");
          } else if (e.message !== '前端认证取消') {
            // this.$message.warning("请求异常");
          }
          this.options.tableLoading = false
        })
    },
    refresh(params) {
      // 对象为空刷新，就是找上一次的查询条件刷
      if (params == null) {
        this.query(this.searchParams)
        return
      }
      this.options.page = 1
      this.$nextTick(() => {
        this.query(params)
      })
    },
    showColSetPage() {
      this.$refs.tableColSetDialog.show()
      this.$nextTick(() => {
        this.$refs.tableColSetPage.init()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-table {
  ::v-deep tr:nth-child(1) {
    td {
      padding: 0 0;
      height: 40px;
      .cell {
        padding: 0;
        .el-input--mini {
          border-radius: 0;
          .el-input__inner {
            border-radius: 0;
            height: 40px;
            border: 0;
            &:focus {
              border-radius: 0;
              border: 0;
            }
          }
        }
      }
    }
    td:last-child {
      padding: 0 10px !important;
    }
  }
}
::v-deep .el-date-editor {
  .el-input__inner {
    border: 1px solid #fff !important;
  }
}
.custom-context-menu {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background: #fff;
  border-radius: 4px;
  padding: 8px 0;
  .menu {
    color: #606266;
    cursor: pointer;
    font-size: 13px;
    line-height: 23px;
    padding: 0 30px;
  }
  .menu:hover {
    // background: #ecf5ff;
    color: #409eff;
  }
}

.custom-context-menu.visible {
  display: block;
}
.mainct {
  height: calc(100% - 100px);
}
::v-deep .el-table__column-resize-proxy {
  border: 1px solid rgb(63, 158, 255) !important;
}
</style>
