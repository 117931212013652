<template>
  <div class="multipleSelect">
    <el-select
      v-model="value"
      placeholder=""
      multiple
      clearable
      filterable
      collapse-tags
      popper-class="poppers"
      ref="select"
      @clear="clear"
    >
      <div style="height: 180px; overflow: scroll">
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <div v-for="item in selectOptions" :key="item.id">
            <el-option
              :label="item[deployObj.label]"
              :value="item[deployObj.value]"
            >
              <el-checkbox :label="item[deployObj.value]" style="width: 100%">{{
                item[deployObj.label]
              }}</el-checkbox>
            </el-option>
          </div>
        </el-checkbox-group>
      </div>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'multipleSelect',
  components: {},
  props: {
    selectOptions: {
      type: Array,
    },
    deployObj: {
      type: Object,
      default: () => {
        return {
          value: 'id',
          label: 'name',
        }
      },
    },
  },
  data() {
    return {
      checkedCities: [],
      value: '',
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    this.value = []
    this.checkedCities = []
  },
  methods: {
    handleCheckedCitiesChange() {
      this.value = this.checkedCities
      this.$emit('visibleChange', this.checkedCities)
    },
    clear() {
      this.value = []
      this.checkedCities = []
      this.$emit('visibleChange', [])
    },
  },
}
</script>

<style lang="scss">
.multipleSelect {
  // height: 40px;
  display: flex;
  align-items: center;
}
.poppers {
  margin: 0 !important;
}
.popper__arrow {
  display: none !important;
}
.el-tag__close {
  display: none !important;
}
</style>
