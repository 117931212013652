// 导入组件 目录名后带星号的标识 暂时无用 - 首页*
import Vue, { KeepAlive } from 'vue'
import Router from 'vue-router'
// 登录
import login from '@/views/login/index.vue'
// 主页面
import index from '@/views/index'

// 启用路由
Vue.use(Router)

// 导出路由
export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: '',
      component: index,
      hidden: true,
      meta: {
        requireAuth: false,
      },
      children: [
        {
          path: '/',
          name: 'defaultPage',
          component: (resolve) =>
            require(['@/views/defaultPage/defaultPage'], resolve),
          meta: {
            requireAuth: true,
            title: '首页',
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login', // 登录
      component: login,
      hidden: true,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: '/index',
      name: 'index',
      component: index,
      iconCls: 'el-icon-tickets',
      children: [
        {
          path: '/defaultPage/defaultPage',
          name: 'defaultPage',
          component: (resolve) =>
            require(['@/views/defaultPage/defaultPage'], resolve),
          meta: {
            requireAuth: true,
            title: '首页',
          },
        },
        /** 我的事项 **/
        {
          path: '/allAtters/pendMatters',
          name: 'pendMatters',
          component: (resolve) =>
            require(['@/views/allAtters/pendMatters/index.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '待办事项',
          },
        },
        {
          path: '/allAtters/processedMatters',
          name: '/allAtters/processedMatters',
          component: (resolve) =>
            require(['@/views/allAtters/processedMatters/index.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '已处理事项',
          },
        },
        {
          path: '/allAtters/copyForMe',
          name: '/allAtters/copyForMe',
          component: (resolve) =>
            require(['@/views/allAtters/copyForMe/index.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '抄送我',
          },
        },
        {
          path: '/allAtters/initiatedMatter',
          name: '/allAtters/initiatedMatter',
          component: (resolve) =>
            require(['@/views/allAtters/initiatedMatter/index.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '我发起',
          },
        },
        {
          path: '/views/examineAuthorize',
          name: '/views/examineAuthorize',
          component: (resolve) =>
            require(['@/views/examineAuthorize'], resolve),
          meta: {
            requireAuth: true,
            title: '委托审核',
          },
        },

        /** 系统管理 **/
        {
          path: '/system/organizationalP',
          name: '/system/organizationalP',
          component: (resolve) =>
            require(['@/views/system/organizationalP'], resolve),
          meta: {
            requireAuth: true,
            title: '机构管理',
          },
        },
        {
          path: '/system/menuSystem',
          name: '/system/menuSystem',
          component: (resolve) =>
            require(['@/views/system/menuSystem/index.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '菜单管理',
          },
        },
        {
          path: '/system/roleP',
          name: '/system/roleP',
          component: (resolve) => require(['@/views/system/roleP'], resolve),
          meta: {
            requireAuth: true,
            title: '角色管理',
          },
        },
        {
          path: '/system/userP',
          name: '/system/userP',
          component: (resolve) => require(['@/views/system/userP'], resolve),
          meta: {
            requireAuth: true,
            title: '用户管理',
          },
        },
        {
          path: '/system/miniMenuSystem',
          name: '/system/miniMenuSystem',
          component: (resolve) =>
            require(['@/views/system/miniMenuSystem/index.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '小程序菜单管理',
          },
        },

        /** 考勤管理 -- 考勤配置 **/
        {
          path: '/attendanceManage/attendanceConfig/cardReplaceConfig',
          name: '/attendanceManage/attendanceConfig/cardReplaceConfig',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/cardReplaceConfig',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工补卡配置',
          },
        },
        {
          path: '/attendanceManage/attendanceConfig/holidayConfig',
          name: '/attendanceManage/attendanceConfig/holidayConfig',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/holidayConfig',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '节假日配置',
          },
        },
        {
          path: '/attendanceManage/attendanceConfig/holidayConfig/edit',
          name: '/attendanceManage/attendanceConfig/holidayConfig/edit',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/holidayConfig/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '节假日配置表单',
          },
        },
        {
          path: '/attendanceManage/attendanceConfig/sectorWorkSet',
          name: '/attendanceManage/attendanceConfig/sectorWorkSet',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/sectorWorkSet',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '部门工作时间设置',
          },
        },
        {
          path: '/attendanceManage/attendanceConfig/punchRules',
          name: '/attendanceManage/attendanceConfig/punchRules',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/punchRules',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '打卡规则配置',
          },
        },
        {
          path: '/attendanceConfig/punchRules/punchRulesForm',
          name: '/attendanceConfig/punchRules/punchRulesForm',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/punchRules/puchRulesForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '打卡规则配置表单',
          },
        },
        {
          path: '/attendanceManage/attendanceConfig/attendanceMachines',
          name: '/attendanceManage/attendanceConfig/attendanceMachines',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/attendanceMachines',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '考勤设备',
          },
        },
        {
          path: '/attendanceManage/attendanceConfig/attendanceMachinesForm',
          name: '/attendanceManage/attendanceConfig/attendanceMachinesForm',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/attendanceConfig/attendanceMachines/attendanceMachinesForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '考勤设备表单',
          },
        },

        /** 考勤管理 -- 汇总查询 **/
        {
          path: '/attendanceManage/gatherQuery/AdjustmentSummary',
          name: '/attendanceManage/gatherQuery/AdjustmentSummary',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/gatherQuery/AdjustmentSummary',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工调休汇总',
          },
        },
        {
          path: '/attendanceManage/gatherQuery/outingApplication',
          name: 'outingApplication',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/gatherQuery/outingApplication',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工公差外出汇总',
          },
        },
        {
          path: '/attendanceManage/gatherQuery/holiday',
          name: '/attendanceManage/gatherQuery/holiday',
          component: (resolve) =>
            require(['@/views/attendanceManage/gatherQuery/holiday'], resolve),
          meta: {
            requireAuth: true,
            title: '员工请假汇总',
          },
        },
        {
          path: '/attendanceManage/gatherQuery/workOvertime',
          name: '/attendanceManage/gatherQuery/workOvertime',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/gatherQuery/workOvertime',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工加班汇总',
          },
        },
        {
          path: '/attendanceManage/gatherQuery/annualLeave',
          name: '/attendanceManage/gatherQuery/annualLeave',
          component: (resolve) =>
            require([
              '@/views/attendanceManage/gatherQuery/annualLeave',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工年假汇总',
          },
        },
        /** 考勤管理 -- 申请单据 **/
        {
          path: '/applyBill/overtimeWork',
          name: '/applyBill/overtimeWork',
          component: (resolve) =>
            require(['@/views/applyBill/overtimeWork'], resolve),
          meta: {
            requireAuth: true,
            title: '加班申请',
          },
        },
        {
          path: '/overtimeWork/overtimeWorkForm',
          name: '/overtimeWork/overtimeWorkForm',
          component: (resolve) =>
            require([
              '@/views/applyBill/overtimeWork/overtimeWorkForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '加班申请表单',
          },
        },
        {
          path: '/applyBill/toleranceGoout',
          name: '/applyBill/toleranceGoout',
          component: (resolve) =>
            require(['@/views/applyBill/toleranceGoout/index'], resolve),
          meta: {
            requireAuth: true,
            title: '公差外出',
          },
        },
        {
          path: '/toleranceGoout/toleranceGooutForm',
          name: '/toleranceGoout/toleranceGooutForm',
          component: (resolve) =>
            require([
              '@/views/applyBill/toleranceGoout/toleranceGooutForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '公差外出表单',
          },
        },
        {
          path: '/applyBill/adjustRest',
          name: '/applyBill/adjustRest',
          component: (resolve) =>
            require(['@/views/applyBill/adjustRest/index'], resolve),
          meta: {
            requireAuth: true,
            title: '调休申请',
          },
        },
        {
          path: '/adjustRest/adjustRestForm',
          name: '/adjustRest/adjustRestForm',
          component: (resolve) =>
            require(['@/views/applyBill/adjustRest/adjustRestForm'], resolve),
          meta: {
            requireAuth: true,
            title: '调休申请表单',
          },
        },
        {
          path: '/applyBill/makeCard',
          name: '/applyBill/makeCard',
          component: (resolve) =>
            require(['@/views/applyBill/makeCard'], resolve),
          meta: {
            requireAuth: true,
            title: '补卡申请',
          },
        },
        {
          path: '/makeCard/makeCardForm',
          name: '/makeCard/makeCardForm',
          component: (resolve) =>
            require(['@/views/applyBill/makeCard/makeCardForm'], resolve),
          meta: {
            requireAuth: true,
            title: '补卡申请表单',
          },
        },
        {
          path: '/applyBill/askLeave',
          name: '/applyBill/askLeave',
          component: (resolve) =>
            require(['@/views/applyBill/askLeave'], resolve),
          meta: {
            requireAuth: true,
            title: '请假申请',
          },
        },
        {
          path: '/askLeave/askLeaveForm',
          name: '/askLeave/askLeaveForm',
          component: (resolve) =>
            require(['@/views/applyBill/askLeave/askLeaveForm'], resolve),
          meta: {
            requireAuth: true,
            title: '请假申请表单',
          },
        },
        {
          path: '/applyBill/annualLeave',
          name: '/applyBill/annualLeave',
          component: (resolve) =>
            require(['@/views/applyBill/annualLeave'], resolve),
          meta: {
            requireAuth: true,
            title: '年假申请',
          },
        },
        {
          path: '/annualLeave/annualLeaveForm',
          name: '/annualLeave/annualLeaveForm',
          component: (resolve) =>
            require(['@/views/applyBill/annualLeave/annualLeaveForm'], resolve),
          meta: {
            requireAuth: true,
            title: '年假申请表单',
          },
        },
        {
          path: '/applyBill/leaveRevocation',
          name: '/applyBill/leaveRevocation',
          component: (resolve) =>
            require(['@/views/applyBill/leaveRevocation'], resolve),
          meta: {
            requireAuth: true,
            title: '休假撤销',
          },
        },
        {
          path: '/leaveRevocation/leaveRevocationForm',
          name: '/leaveRevocation/leaveRevocationForm',
          component: (resolve) =>
            require([
              '@/views/applyBill/leaveRevocation/leaveRevocationForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '休假撤销申请表单',
          },
        },
        {
          path: '/applyBill/annualLeaveBatch',
          name: '/applyBill/annualLeaveBatch',
          component: (resolve) =>
            require(['@/views/applyBill/annualLeaveBatch'], resolve),
          meta: {
            requireAuth: true,
            title: '年假批处理',
          },
        },
        {
          path: '/annualLeaveBatch/annualLeaveBatchForm',
          name: '/annualLeaveBatch/annualLeaveBatchForm',
          component: (resolve) =>
            require([
              '@/views/applyBill/annualLeaveBatch/annualLeaveBatchForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '年假批处理表单',
          },
        },

        /** 考勤管理 -- 考勤记录 **/
        {
          path: '/applyBill/employeeAttendanceRecords',
          name: '/applyBill/employeeAttendanceRecords',
          component: (resolve) =>
            require(['@/views/applyBill/employeeAttendanceRecords'], resolve),
          meta: {
            requireAuth: true,
            title: '员工考勤记录',
          },
        },
        {
          path: '/employeeAttendanceRecords/employeeAttendanceRecordsForm',
          name: '/employeeAttendanceRecords/employeeAttendanceRecordsForm',
          component: (resolve) =>
            require([
              '@/views/applyBill/employeeAttendanceRecords/employeeAttendanceRecordsForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工考勤记录表单',
          },
        },
        {
          path: '/applyBill/clockInRecord',
          name: '/applyBill/clockInRecord',
          component: (resolve) =>
            require(['@/views/applyBill/clockInRecord'], resolve),
          meta: {
            requireAuth: true,
            title: '打卡记录',
          },
        },
        /** 基础资料 **/
        {
          path: '/basicMaterial/oaDictionary',
          name: 'oaDictionary',
          component: (resolve) =>
            require(['@/views/basicMaterial/oaDictionary'], resolve),
          meta: {
            requireAuth: true,
            title: 'OA字典',
          },
        },
        {
          path: '/basicMaterial/classFiles',
          name: '/basicMaterial/classFiles',
          component: (resolve) =>
            require(['@/views/basicMaterial/classFiles'], resolve),
          meta: {
            requireAuth: true,
            title: '班组主档',
          },
        },
        {
          path: '/basicMaterial/postAbility',
          name: '/basicMaterial/postAbility',
          component: (resolve) =>
            require(['@/views/basicMaterial/postAbility'], resolve),
          meta: {
            requireAuth: true,
            title: '岗位能力',
          },
        },
        /** 班次管理 **/
        {
          path: '/shiftManagement/classOrder',
          name: '/shiftManagement/classOrder',
          component: (resolve) =>
            require(['@/views/shiftManagement/classOrder'], resolve),
          meta: {
            requireAuth: true,
            title: '班次配置',
          },
        },
        {
          path: '/shiftManagement/classType',
          name: '/shiftManagement/classType',
          component: (resolve) =>
            require(['@/views/shiftManagement/classType'], resolve),
          meta: {
            requireAuth: true,
            title: '班次类型配置',
          },
        },
        /** 会议室管理 **/
        {
          path: '/meetingRoom/meetingRoomManage',
          name: '/meetingRoom/meetingRoomManage',
          component: (resolve) =>
            require(['@/views/meetingRoom/meetingRoomManage'], resolve),
          meta: {
            requireAuth: true,
            title: '会议室信息',
          },
        },
        {
          path: '/meetingRoom/queryMeetingRoomReservation',
          name: '/meetingRoom/queryMeetingRoomReservation',
          component: (resolve) =>
            require([
              '@/views/meetingRoom/queryMeetingRoomReservation',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '会议室预约',
          },
        },
        /** 宿舍管理 宿舍中心 **/
        {
          path: '/dormitoryManage/dormitoryHandleRegister/checkIn',
          name: '/dormitoryManage/dormitoryHandleRegister/checkIn',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryHandleRegister/checkIn',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍办理登记-入住',
          },
        },
        {
          path: '/dormitoryHandleRegister/checkIn/edit',
          name: '/dormitoryHandleRegister/checkIn/edit',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryHandleRegister/checkIn/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '入住登记表单',
          },
        },
        {
          path: '/dormitoryManage/dormitoryHandleRegister/checkOut',
          name: '/dormitoryManage/dormitoryHandleRegister/checkOut',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryHandleRegister/checkOut',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍办理登记-退房',
          },
        },
        {
          path: '/dormitoryHandleRegister/checkOut/edit',
          name: '/dormitoryHandleRegister/checkOut/edit',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryHandleRegister/checkOut/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '退房登记表单',
          },
        },
        {
          path: '/dormitoryManage/dormitoryHandleRegister/changeHouse',
          name: '/dormitoryManage/dormitoryHandleRegister/changeHouse',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryHandleRegister/changeHouse',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍办理登记-调房',
          },
        },
        {
          path: '/dormitoryHandleRegister/changeHouse/edit',
          name: '/dormitoryHandleRegister/changeHouse/edit',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryHandleRegister/changeHouse/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '调房登记表单',
          },
        },
        {
          path: '/dormitoryManage/dormitoryGlanceOver',
          name: '/dormitoryManage/dormitoryGlanceOver',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryGlanceOver',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍一览',
          },
        },
        {
          path: '/dormitoryManage/dormitoryGlanceOverForm',
          name: '/dormitoryManage/dormitoryGlanceOverForm',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryGlanceOver/dormitoryGlanceOverForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍一览表单',
          },
        },
        {
          path: '/dormitoryManage/dormitoryQuery',
          name: '/dormitoryManage/dormitoryQuery',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryQuery',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍员工一览',
          },
        },
        {
          path: '/dormitoryManage/dormitoryInfo',
          name: '/dormitoryManage/dormitoryInfo',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryInfo',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍房间居住信息',
          },
        },
        {
          path: '/dormitoryManage/dormitoryAssets',
          name: '/dormitoryManage/dormitoryAssets',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryAssets',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍资产盘点',
          },
        },
        {
          path: '/dormitoryManage/dormitoryAssetsForm',
          name: '/dormitoryManage/dormitoryAssetsForm',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryAssets/dormitoryAssetsForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍资产盘点明细',
          },
        },
        /** 宿舍管理 资源管理 **/
        {
          path: '/dormitoryHandleRegister/hydropowerMeter',
          name: '/dormitoryHandleRegister/hydropowerMeter',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/resources/hydropowerMeter',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍水电抄表',
          },
        },
        /** 宿舍管理 费用管理 **/
        {
          path: '/costManage/stayCostDetail',
          name: '/costManage/stayCostDetail',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/costManage/stayCostDetail',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '住宿费用明细',
          },
        },
        {
          path: '/costManage/stayCostDetail/edit',
          name: '/costManage/stayCostDetail/edit',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/costManage/stayCostDetail/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '住宿费用明细表单',
          },
        },
        {
          path: '/costManage/costUseRecord',
          name: '/costManage/costUseRecord',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/costManage/costUseRecord',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍水电费使用记录',
          },
        },
        {
          path: '/costManage/costUseRecord/edit',
          name: '/costManage/costUseRecord/edit',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/costManage/costUseRecord/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍水电费使用记录表单',
          },
        },
        /** 车辆管理 基础信息 **/
        {
          path: '/foundationInfo/vehicleInfo',
          name: '/foundationInfo/vehicleInfo',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/foundationInfo/vehicleInfo',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '车辆信息',
          },
        },
        {
          path: '/foundationInfo/vehicleInfo/vehicleInfoEdit',
          name: '/foundationInfo/vehicleInfo/vehicleInfoEdit',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/foundationInfo/vehicleInfo/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '车辆信息表单',
          },
        },
        {
          path: '/foundationInfo/driverInfo',
          name: '/foundationInfo/driverInfo',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/foundationInfo/driverInfo',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '司机信息',
          },
        },
        /** 车辆管理 车辆申请单据 **/
        {
          path: '/applicationDocument/carUse',
          name: '/applicationDocument/carUse',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/applicationDocument/carUse',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '用车申请单',
          },
        },
        {
          path: '/carUse/carUseForm',
          name: '/carUse/carUseForm',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/applicationDocument/carUse/carUseForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '用车申请表单',
          },
        },
        {
          path: '/applicationDocument/carDispatch',
          name: '/applicationDocument/carDispatch',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/applicationDocument/carDispatch',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '派送任务',
          },
        },
        {
          path: '/carDispatch/carDispatchForm',
          name: '/carDispatch/carDispatchForm',
          component: (resolve) =>
            require([
              '@/views/vehicleManage/applicationDocument/carDispatch/carDispatchForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '派送任务表单',
          },
        },
        /** 证书管理 **/
        {
          path: '/certificateManage/certificateQuery',
          name: '/certificateManage/certificateQuery',
          component: (resolve) =>
            require(['@/views/certificateManage/certificateQuery'], resolve),
          meta: {
            requireAuth: true,
            title: '证书查询',
          },
        },
        /** 档案信息 个人信息 **/
        {
          path: '/filesInfo/employeeFiles',
          name: '/filesInfo/employeeFiles',
          component: (resolve) =>
            require(['@/views/filesInfo/personalInfo/employeeFiles'], resolve),
          meta: {
            requireAuth: true,
            title: '员工档案',
          },
        },
        {
          path: '/filesInfo/employeeForm',
          name: '/filesInfo/employeeForm',
          component: (resolve) =>
            require([
              '@/views/filesInfo/personalInfo/employeeFiles/employeeForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工档案信息',
          },
        },
        {
          path: '/filesInfo/employeePic',
          name: '/filesInfo/employeePic',
          component: (resolve) =>
            require([
              '@/views/filesInfo/personalInfo/employeePic/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工照片',
          },
        },
        {
          path: '/filesInfo/signaturePic',
          name: '/filesInfo/signaturePic',
          component: (resolve) =>
            require([
              '@/views/filesInfo/personalInfo/signaturePic/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '签名照片信息',
          },
        },
        {
          path: '/filesInfo/employeeCarNo',
          name: '/filesInfo/employeeCarNo',
          component: (resolve) =>
            require([
              '@/views/filesInfo/personalInfo/employeeCarNo/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工车牌信息',
          },
        },
        {
          path: '/filesInfo/employeeLocation',
          name: '/filesInfo/employeeLocation',
          component: (resolve) =>
            require([
              '@/views/filesInfo/personalInfo/employeeLocation/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工档案位置',
          },
        },
        {
          path: '/filesInfo/employeeLocationDetails',
          name: '/filesInfo/employeeLocationDetails',
          component: (resolve) =>
            require([
              '@/views/filesInfo/personalInfo/employeeLocation/employeeLocationDetails.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工档案位置详情',
          },
        },
        {
          path: '/shiftManagement/staffHoliday',
          name: '/shiftManagement/staffHoliday',
          component: (resolve) =>
            require(['@/views/shiftManagement/staffHoliday'], resolve),
          meta: {
            requireAuth: true,
            title: '员工节假日配置',
          },
        },
        {
          path: '/shiftManagement/addressBookRecord',
          name: '/shiftManagement/addressBookRecord',
          component: (resolve) =>
            require(['@/views/shiftManagement/addressBookRecord'], resolve),
          meta: {
            requireAuth: true,
            title: '员工通讯录',
          },
        },
        {
          path: '/addressBookRecord/addressBookRecordForm',
          name: '/addressBookRecord/addressBookRecordForm',
          component: (resolve) =>
            require([
              '@/views/shiftManagement/addressBookRecord/addressBookRecordForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工通讯录表单',
          },
        },
        {
          path: '/shiftManagement/staffHistoryClass',
          name: '/shiftManagement/staffHistoryClass',
          component: (resolve) =>
            require(['@/views/shiftManagement/staffHistoryClass'], resolve),
          meta: {
            requireAuth: true,
            title: '员工班次',
          },
        },
        /** 档案信息 工作记录 **/
        {
          path: '/filesInfo/employeeContractInfo',
          name: '/filesInfo/employeeContractInfo',
          component: (resolve) =>
            require([
              '@/views/filesInfo/workRecord/employeeContractInfo/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工合同信息',
          },
        },
        {
          path: '/workRecord/entryRecord',
          name: '/workRecord/entryRecord',
          component: (resolve) =>
            require(['@/views/filesInfo/workRecord/entryRecord'], resolve),
          meta: {
            requireAuth: true,
            title: '员工任职记录',
          },
        },
        {
          path: '/workRecord/abilityRenewal',
          name: '/workRecord/abilityRenewal',
          component: (resolve) =>
            require(['@/views/filesInfo/workRecord/abilityRenewal'], resolve),
          meta: {
            requireAuth: true,
            title: '员工岗位能力更新',
          },
        },
        {
          path: '/filesInfo/trainRecord',
          name: '/filesInfo/trainRecord',
          component: (resolve) =>
            require([
              '@/views/filesInfo/workRecord/trainRecord/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工培训记录',
          },
        },
        {
          path: '/workRecord/bankCardInfo',
          name: '/workRecord/bankCardInfo',
          component: (resolve) =>
            require(['@/views/filesInfo/workRecord/bankCardInfo'], resolve),
          meta: {
            requireAuth: true,
            title: '员工银行卡信息',
          },
        },
        {
          path: '/workRecord/editBankCardInfo',
          name: '/workRecord/editBankCardInfo',
          component: (resolve) =>
            require([
              '@/views/filesInfo/workRecord/bankCardInfo/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '员工银行卡信息表单',
          },
        },
        {
          path: '/workRecord/retiredEmployee',
          name: '/workRecord/retiredEmployee',
          component: (resolve) =>
            require([
              '@/views/filesInfo/workRecord/retiredEmployee/index.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '退休返聘记录',
          },
        },
        /** 档案信息 财务信息 **/
        {
          path: '/filesInfo/financeInfo/refereeBonusRecord',
          name: '/filesInfo/financeInfo/refereeBonusRecord',
          component: (resolve) =>
            require([
              '@/views/filesInfo/financeInfo/refereeBonusRecord',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '介绍人奖金发放记录',
          },
        },
        {
          path: '/filesInfo/financeInfo/refereeBonusRecord/bonusRecordInfo',
          name: '/filesInfo/financeInfo/refereeBonusRecord/bonusRecordInfo',
          component: (resolve) =>
            require([
              '@/views/filesInfo/financeInfo/refereeBonusRecord/bonusRecordInfo.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '介绍人奖金发放记录表单',
          },
        },
        {
          path: '/filesInfo/financeInfo/insureInfoRecord',
          name: '/filesInfo/financeInfo/insureInfoRecord',
          component: (resolve) =>
            require([
              '@/views/filesInfo/financeInfo/insureInfoRecord',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '保险信息记录',
          },
        },
        /** 档案信息 介绍人信息 **/
        {
          path: '/introducer/introducerQuery',
          name: '/introducer/introducerQuery',
          component: (resolve) =>
            require(['@/views/filesInfo/introducer/introducerQuery'], resolve),
          meta: {
            requireAuth: true,
            title: '介绍人查询',
          },
        },
        /** 招聘管理 **/
        {
          path: '/recruitManage/recruitApply',
          name: '/recruitManage/recruitApply',
          component: () => import(`@/views/recruitManage/recruitApply`),
          meta: {
            requireAuth: true,
            title: '招聘申请',
          },
        },
        {
          path: '/recruitManage/recruitApplyForm',
          name: '/recruitManage/recruitApplyForm',
          component: () =>
            import(`@/views/recruitManage/recruitApply/recruitApplyForm`),
          meta: {
            requireAuth: true,
            title: '招聘申请表单',
          },
        },
        {
          path: '/recruitManage/recruitStation',
          name: '/recruitManage/recruitStation',
          component: () => import(`@/views/recruitManage/recruitStation`),
          meta: {
            requireAuth: true,
            title: '招聘岗位',
          },
        },
        {
          path: '/recruitManage/recruitStationForm',
          name: '/recruitManage/recruitStationForm',
          component: () =>
            import(`@/views/recruitManage/recruitStation/recruitStationForm`),
          meta: {
            requireAuth: true,
            title: '招聘岗位表单',
          },
        },
        {
          path: '/recruitManage/talentWarehouse',
          name: '/recruitManage/talentWarehouse',
          component: () => import(`@/views/recruitManage/talentWarehouse`),
          meta: {
            requireAuth: true,
            title: '人才库',
          },
        },
        {
          path: '/recruitManage/blacklist',
          name: '/recruitManage/blacklist',
          component: () => import(`@/views/recruitManage/blacklist`),
          meta: {
            requireAuth: true,
            title: '黑名单',
          },
        },
        {
          path: '/recruitManage/applicant',
          name: '/recruitManage/applicant',
          component: () => import(`@/views/recruitManage/applicant`),
          meta: {
            requireAuth: true,
            title: '应聘者信息',
          },
        },
        {
          path: '/recruitManage/applicantForm',
          name: '/recruitManage/applicantForm',
          component: () =>
            import(`@/views/recruitManage/applicant/applicantForm`),
          meta: {
            requireAuth: true,
            title: '应聘者信息表单',
          },
        },
        /** 人事管理 入职与离职 **/
        {
          path: '/entryAndExit/entryApplication',
          name: '/entryAndExit/entryApplication',
          component: (resolve) =>
            require([
              '@/views/personalManager/entryAndExit/entryApplication',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '入职申请',
          },
        },
        {
          path: '/entryAndExit/entryApplicationForm',
          name: '/entryAndExit/entryApplicationForm',
          component: () =>
            import(
              `@/views/personalManager/entryAndExit/entryApplication/entryApplicationForm`
            ),
          meta: {
            requireAuth: true,
            title: '入职申请表单',
          },
        },
        {
          path: '/entryAndExit/reEntryApplication',
          name: '/entryAndExit/reEntryApplication',
          component: (resolve) =>
            require([
              '@/views/personalManager/entryAndExit/reEntryApplication',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '再入职申请',
          },
        },
        {
          path: '/entryAndExit/recruitStationForm',
          name: '/entryAndExit/recruitStationForm',
          component: () =>
            import(
              `@/views/personalManager/entryAndExit/reEntryApplication/recruitStationForm`
            ),
          meta: {
            requireAuth: true,
            title: '再入职申请表单',
          },
        },
        {
          path: '/entryAndExit/exitApplication',
          name: '/entryAndExit/exitApplication',
          component: (resolve) =>
            require([
              '@/views/personalManager/entryAndExit/exitApplication',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '离职申请',
          },
        },
        {
          path: '/entryAndExit/exitStationForm',
          name: '/entryAndExit/exitStationForm',
          component: () =>
            import(
              `@/views/personalManager/entryAndExit/exitApplication/exitStationForm`
            ),
          meta: {
            requireAuth: true,
            title: '离职申请表单',
          },
        },
        {
          path: '/entryAndExit/exitStationTemplate',
          name: '/entryAndExit/exitStationTemplate',
          component: (resolve) =>
            require([
              '@/views/contact/contactBill/exitStationTemplate',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '离职申请打印模版',
          },
        },
        /** 人事管理 职位变动 **/
        {
          path: '/jobChange/entryRegularization',
          name: '/jobChange/entryRegularization',
          component: (resolve) =>
            require([
              '@/views/personalManager/jobChange/entryRegularization',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '转正申请',
          },
        },
        {
          path: '/entryRegularization/entryRegularizationForm',
          name: '/entryRegularization/entryRegularizationForm',
          component: (resolve) =>
            require([
              '@/views/personalManager/jobChange/entryRegularization/entryRegularizationForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '转正申请表单',
          },
        },
        {
          path: '/jobChange/entryRegularizationTemplate',
          name: '/jobChange/entryRegularizationTemplate',
          component: (resolve) =>
            require([
              '@/views/contact/contactBill/entryRegularizationTemplate',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '转正申请打印模版',
          },
        },
        {
          path: '/jobChange/applicationPost',
          name: '/jobChange/applicationPost',
          component: (resolve) =>
            require([
              '@/views/personalManager/jobChange/applicationPost',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '调岗申请',
          },
        },
        {
          path: '/applicationPost/applicationPostForm',
          name: 'applicationPostForm',
          component: (resolve) =>
            require([
              '@/views/personalManager/jobChange/applicationPost/applicationPostForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '调岗申请表单',
          },
        },
        {
          path: '/jobChange/applicationPostTemplate',
          name: '/jobChange/applicationPostTemplate',
          component: (resolve) =>
            require([
              '@/views/contact/contactBill/applicationPostTemplate',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '调岗申请打印模版',
          },
        },
        {
          path: '/jobChange/applicationDismissal',
          name: '/jobChange/applicationDismissal',
          component: (resolve) =>
            require([
              '@/views/personalManager/jobChange/applicationDismissal',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '解聘申请',
          },
        },
        /** 人事管理 薪资与合同 **/
        {
          path: '/salariesAndContracts/applicationSalary',
          name: '/salariesAndContracts/applicationSalary',
          component: (resolve) =>
            require([
              '@/views/personalManager/salariesAndContracts/applicationSalary',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '调薪申请',
          },
        },
        {
          path: '/applicationSalary/applicationSalaryForm',
          name: '/applicationSalary/applicationSalaryForm',
          component: (resolve) =>
            require([
              '@/views/personalManager/salariesAndContracts/applicationSalary/applicationSalaryForm',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '调薪申请表单',
          },
        },
        /** 联络通知 联络通知单 **/
        {
          path: '/contact/contactBill',
          name: '/contact/contactBill',
          component: (resolve) =>
            require(['@/views/contact/contactBill'], resolve),
          meta: {
            requireAuth: true,
            title: '联络通知单',
          },
        },
        {
          path: '/contact/contactBillForm',
          name: '/contact/contactBillForm',
          component: (resolve) =>
            require(['@/views/contact/contactBill/contactBillForm'], resolve),
          meta: {
            requireAuth: true,
            title: '联络通知表单',
          },
        },
        /** 办公设备管理 **/
        {
          path: '/officeManage/officeSupply',
          name: '/officeManage/officeSupply',
          component: (resolve) =>
            require(['@/views/officeManage/officeSupply'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备请购单',
          },
        },
        {
          path: '/officeSupply/officeSupplyForm',
          name: '/officeSupply/officeSupplyForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeSupply/officeSupplyForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备申请表单',
          },
        },
        {
          path: '/officeManage/officeStorage',
          name: '/officeManage/officeStorage',
          component: (resolve) =>
            require(['@/views/officeManage/officeStorage'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备入库申请',
          },
        },
        {
          path: '/officeStorage/officeStorageForm',
          name: '/officeStorage/officeStorageForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeStorage/officeStorageForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备入库申请表单',
          },
        },
        {
          path: '/officeManage/officeRequisitionApply',
          name: '/officeManage/officeRequisitionApply',
          component: (resolve) =>
            require(['@/views/officeManage/officeRequisitionApply'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备领用申请',
          },
        },
        {
          path: '/officeRequisitionApply/officeRequisitionApplyForm',
          name: '/officeRequisitionApply/officeRequisitionApplyForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeRequisitionApply/officeRequisitionApplyForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备领用申请表单',
          },
        },
        {
          path: '/officeManage/officeReturnApply',
          name: '/officeManage/officeReturnApply',
          component: (resolve) =>
            require(['@/views/officeManage/officeReturnApply'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备归还申请',
          },
        },
        {
          path: '/officeReturnApply/officeReturnApplyForm',
          name: '/officeReturnApply/officeReturnApplyForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeReturnApply/officeReturnApplyForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备归还申请表单',
          },
        },
        {
          path: '/officeManage/officeRepairApply',
          name: '/officeManage/officeRepairApply',
          component: (resolve) =>
            require(['@/views/officeManage/officeRepairApply'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备报修申请',
          },
        },
        {
          path: '/officeRepairApply/officeRepairApplyForm',
          name: '/officeRepairApply/officeRepairApplyForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeRepairApply/officeRepairApplyForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备报修申请表单',
          },
        },
        {
          path: '/officeManage/officeScrapApply',
          name: '/officeManage/officeScrapApply',
          component: (resolve) =>
            require(['@/views/officeManage/officeScrapApply'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备报废申请',
          },
        },
        {
          path: '/officeScrapApply/officeScrapApplyForm',
          name: '/officeScrapApply/officeScrapApplyForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeScrapApply/officeScrapApplyForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备报废申请表单',
          },
        },
        {
          path: '/officeManage/officeInventory',
          name: '/officeManage/officeInventory',
          component: (resolve) =>
            require(['@/views/officeManage/officeInventory'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备库存',
          },
        },
        {
          path: '/officeInventory/officeInventoryForm',
          name: '/officeInventory/officeInventoryForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeInventory/officeInventoryForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备库存表单',
          },
        },
        {
          path: '/officeManage/officeOverview',
          name: '/officeManage/officeOverview',
          component: (resolve) =>
            require(['@/views/officeManage/officeOverview'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备一览',
          },
        },
        {
          path: '/officeOverview/officeOverviewForm',
          name: '/officeOverview/officeOverviewForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeOverview/officeOverviewForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备一览表单',
          },
        },
        {
          path: '/officeManage/OfficeCheckRecord',
          name: '/officeManage/OfficeCheckRecord',
          component: (resolve) =>
            require(['@/views/officeManage/officeCheckRecord'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备盘点记录',
          },
        },
        {
          path: '/officeManage/officeTransfer',
          name: '/officeManage/officeTransfer',
          component: (resolve) =>
            require(['@/views/officeManage/officeTransfer'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备转移',
          },
        },
        {
          path: '/officeTransfer/officeTransferForm',
          name: '/officeTransfer/officeTransferForm',
          component: (resolve) =>
            require([
              '@/views/officeManage/officeTransfer/officeTransferForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备转移表单',
          },
        },
        /** 数据看板 人员结构看板 **/
        {
          path: '/dataBoard/structurePersonnel',
          name: '/dataBoard/structurePersonnel',
          component: (resolve) =>
            require(['@/views/dataBoard/structurePersonnel'], resolve),
          meta: {
            requireAuth: true,
            title: '人员结构看板',
          },
        },
        /** 数据看板 员工信息管理 **/
        {
          path: '/dataBoard/employeesStartedSameDay',
          name: '/dataBoard/employeesStartedSameDay',
          component: (resolve) =>
            require(['@/views/dataBoard/employeesStartedSameDay'], resolve),
          meta: {
            requireAuth: true,
            title: '当日入职员工信息',
          },
        },
        {
          path: '/dataBoard/separatingEmployee',
          name: '/dataBoard/separatingEmployee',
          component: (resolve) =>
            require(['@/views/dataBoard/separatingEmployee'], resolve),
          meta: {
            requireAuth: true,
            title: '离职人员',
          },
        },
        {
          path: '/dataBoard/employeeInduction',
          name: '/dataBoard/employeeInduction',
          component: (resolve) =>
            require(['@/views/dataBoard/employeeInduction'], resolve),
          meta: {
            requireAuth: true,
            title: '员工入职',
          },
        },
        /** 数据看板 通讯录与查询 **/
        {
          path: '/dataBoard/deptPersonnelSituation',
          name: '/dataBoard/deptPersonnelSituation',
          component: (resolve) =>
            require(['@/views/dataBoard/deptPersonnelSituation'], resolve),
          meta: {
            requireAuth: true,
            title: '部门人员统计',
          },
        },
        {
          path: '/dataBoard/statisticsLeader',
          name: '/dataBoard/statisticsLeader',
          component: (resolve) =>
            require(['@/views/dataBoard/statisticsLeader'], resolve),
          meta: {
            requireAuth: true,
            title: '班组长名单',
          },
        },
        {
          path: '/dataBoard/addressBook',
          name: '/dataBoard/addressBook',
          component: (resolve) =>
            require(['@/views/dataBoard/addressBook'], resolve),
          meta: {
            requireAuth: true,
            title: '通讯录查询',
          },
        },
        /** 数据看板 门禁与安全 **/
        {
          path: '/dataBoard/accessControlData',
          name: '/dataBoard/accessControlData',
          component: (resolve) =>
            require(['@/views/dataBoard/accessControlData'], resolve),
          meta: {
            requireAuth: true,
            title: '门禁数据查询',
          },
        },
        {
          path: '/dataBoard/staffAccessControl',
          name: '/dataBoard/staffAccessControl',
          component: (resolve) =>
            require(['@/views/dataBoard/staffAccessControl'], resolve),
          meta: {
            requireAuth: true,
            title: '员工门禁次数查询',
          },
        },
        /** 数据看板 车辆管理看板 **/
        {
          path: '/dataBoard/staffCarNo',
          name: '/dataBoard/staffCarNo',
          component: (resolve) =>
            require(['@/views/dataBoard/staffCarNo'], resolve),
          meta: {
            requireAuth: true,
            title: '车牌查询',
          },
        },
        /** 数据看板 组织结构与人员档案 **/
        {
          path: '/dataBoard/roster',
          name: '/dataBoard/roster',
          component: (resolve) =>
            require(['@/views/dataBoard/roster'], resolve),
          meta: {
            requireAuth: true,
            title: '花名册',
          },
        },
        /** 公告 **/
        {
          path: '/noticeManage/notice',
          name: '/noticeManage/notice',
          component: (resolve) =>
            require(['@/views/noticeManage/notice'], resolve),
          meta: {
            requireAuth: true,
            title: '公告',
          },
        },
        {
          path: '/notice/noticeForm',
          name: '/notice/noticeForm',
          component: (resolve) =>
            require(['@/views/noticeManage/notice/noticeForm.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '公告表单',
          },
        },
        {
          path: '/notice/noticeDetails',
          name: '/notice/noticeDetails',
          component: (resolve) =>
            require(['@/views/noticeManage/notice/noticeDetails.vue'], resolve),
          meta: {
            requireAuth: true,
            title: '公告详情',
          },
        },
        {
          path: '/noticeManage/myNotice',
          name: '/noticeManage/myNotice',
          component: (resolve) =>
            require(['@/views/noticeManage/myNotice'], resolve),
          meta: {
            requireAuth: true,
            title: '我的公告',
          },
        },
        {
          path: '/myNotice/myNoticeForm',
          name: '/myNotice/myNoticeForm',
          component: (resolve) =>
            require([
              '@/views/noticeManage/myNotice/myNoticeForm.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '我的公告表单',
          },
        },
        {
          path: '/myNotice/myNoticeDetails',
          name: '/myNotice/myNoticeDetails',
          component: (resolve) =>
            require([
              '@/views/noticeManage/myNotice/myNoticeDetails.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '我的公告详情',
          },
        },
        /** 打印模板管理 **/
        {
          path: '/dormitoryManage/dormitoryPdf',
          name: '/dormitoryManage/dormitoryPdf',
          component: (resolve) =>
            require([
              '@/views/dormitoryManage/dormCenter/dormitoryPdf',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '宿舍管理协议模板打印',
          },
        },
        {
          path: '/contact/contactBillPdf',
          name: '/contact/contactBillPdf',
          component: (resolve) =>
            require(['@/views/contact/contactBill/contactBillPdf'], resolve),
          meta: {
            requireAuth: true,
            title: '联络通知打印',
          },
        },
        {
          path: '/contact/contactBillPdfTemplate',
          name: '/contact/contactBillPdfTemplate',
          component: (resolve) =>
            require([
              '@/views/contact/contactBill/contactBillPdfTemplate',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '联络通知打印模板',
          },
        },
        {
          path: '/contact/contactBillNewEmployee',
          name: '/contact/contactBillNewEmployee',
          component: (resolve) =>
            require([
              '@/views/contact/contactBill/contactBillNewEmployee',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '新员工试用员工表',
          },
        },
        {
          path: '/contact/contactBillJobSeeker',
          name: '/contact/contactBillJobSeeker',
          component: (resolve) =>
            require([
              '@/views/contact/contactBill/contactBillJobSeeker',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '求职人员登记表',
          },
        },
        {
          path: '/officeManage/officePdf',
          name: '/officeManage/officePdf',
          component: (resolve) =>
            require(['@/views/officeManage/officePdf'], resolve),
          meta: {
            requireAuth: true,
            title: '办公设备委外保修单',
          },
        },

        {
          path: '/salariesAndContracts/contractManagement',
          name: '/salariesAndContracts/contractManagement',
          component: (resolve) =>
            require([
              '@/views/personalManager/salariesAndContracts/contractManagement',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '合同管理',
          },
        },
        {
          path: '/productionManagement/productionDictionary',
          name: '/productionManagement/productionDictionary',
          component: (resolve) =>
            require([
              '@/views/productionManagement/basicInfo/productionDictionary',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '生产字典',
          },
        },

        {
          path: '/filesInfo/financeInfo/insureInfoRecord/insureInfoRecordEdit',
          name: '/filesInfo/financeInfo/insureInfoRecord/insureInfoRecordEdit',
          component: (resolve) =>
            require([
              '@/views/filesInfo/financeInfo/insureInfoRecord/edit.vue',
            ], resolve),
          meta: {
            requireAuth: true,
            title: '保险信息记录表单',
          },
        },

        {
          path: '/activitiTemplate/index',
          name: '/activitiTemplate/index',
          component: (resolve) =>
            require(['@/views/activitiTemplate/index'], resolve),
          meta: {
            requireAuth: true,
            title: '流程模板',
          },
        },
      ],
    },
  ],
})
