import { queryDicInfoList } from '@/api/ajax/request'
export default {
  // 开启命名空间
  namespaced: true,
  // 放数据的地方
  state: {
    fromName: localStorage.getItem('fromUrl'),
    updateId: null,
    modelMenuList: [],
    btnManage: JSON.parse(localStorage.getItem('btnManage')) || {
      menuId: '',
      btnStr: '',
    },
    code: JSON.parse(localStorage.getItem('_codeData')) || [],
  },
  // 相当于组件中计算属性
  getters: {},
  // 这个是唯一修改state中数据地方
  mutations: {
    // setCount(state) {}
    setBtnManage(state, data) {
      state.btnManage = data || ''
    },
    getDicInfo(state, data) {
      state.code = data
    },
  },
  // 写异步的ajax的地方
  actions: {
    // store.commit()
    // getData(store){}
    async getCode(store) {
      try {
        const res = await queryDicInfoList({ type: 1 })
        if (res.code == 200) {
          localStorage.setItem('_codeData', JSON.stringify(res.data))
          store.commit('getDicInfo', res.data)
        }
      } catch (error) {}
    },
  },
}
