import axios from 'axios'
import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Rsa from '@/utils/rsa.js' //引入RSA加密方法；
import { Message } from 'element-ui'
const http = axios.create({
  baseURL: process.env.VUE_APP_BASEURL + '/CGP-OA', //CGP-OA
  timeout: 1000 * 60 * 3,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})
let whiteList = ['login', 'uploadFile', 'xml', 'insert', 'list', 'exportData']

/**
 * 请求拦截
 */
/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    config.headers['X-Access-Token'] =
      window.localStorage.getItem('accessToken')

    //测试环境不验签直接过
    // config.cancelToken = new CancelToken(c => {
    //   cacheLink = c
    // })
    // if (config.auth !== undefined && !config.auth) {
    //   cacheLink && cacheLink('前端认证取消')
    // }
    // config.headers['token'] = Vue.cookie.get('token') // 请求头带上token
    // if (config.params != null && config.params['t'] == null) {
    //   config.params['t'] = new Date().getTime()
    // }
    // if (config.data != null && config.data['t'] == null) {
    //   if (config.params == null) {
    //     config.params = []
    //   }
    //   config.params['t'] = new Date().getTime()
    // }
    // 如果存在按钮，按钮对象loading，防止重复提交
    if (config.loadBtn) {
      config.loadBtn.loading = true
    }
    console.log('%c%s', 'color: #f9ae3d', 'req:' + config.url, config.data)
    // 过滤白名单不加密
    let lastStr = config.url.split('/')
    let whiteBoolean = whiteList.some(
      (item) => item === lastStr[lastStr.length - 1]
    )
    // 过滤传递数据创建时间修改时间创建人修改人
    let filterList = ['createTime', 'updateTime']
    if (config.method == 'get') {
      if (config.params && JSON.stringify(config.data) !== '{}') {
        Object.keys(config.params).forEach((item) => {
          if (Object.keys(config.params).length) {
            if (filterList.find((ele) => ele == item)) {
              delete config.params[item]
            }
          }
        })
      }
    } else {
      if (config.data && JSON.stringify(config.data) !== '{}') {
        Object.keys(config.data).forEach((item) => {
          if (Object.keys(config.data).length) {
            if (filterList.find((ele) => ele == item)) {
              delete config.data[item]
            }
          }
        })
      }
    }
    if (whiteBoolean) return config
    // rsa加密
    // 随机数
    let aesKey =
      Math.random().toString(36).slice(-8) +
      '' +
      Math.random().toString(36).slice(-8)
    // 源密码插入data中
    if (config.method === 'get') {
      config.params.aesKey = aesKey
      let aaa = Rsa.aesEncrypt(aesKey, 'abcdef1234567890', aesKey)
      let data = {
        encAesKey: aaa,
        data: Rsa.encrypt(JSON.stringify(config.params)),
      }
      config.params = data
    } else {
      if (!config.data) {
        config.data = { isIndex: 111 }
      }
      config.data.aesKey = aesKey
      let aaa = Rsa.aesEncrypt(aesKey, 'abcdef1234567890', aesKey)
      let data = {
        encAesKey: aaa,
        data: Rsa.encrypt(JSON.stringify(config.data)),
      }
      config.data = data
    }
    config.lost = aesKey
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截
http.interceptors.response.use(
  function (config) {
    try {
      NProgress.done()
      config.headers['Access-Control-Allow-Origin'] = '*'
      // aes解密
      // 过滤白名单不加密
      let lastStr = config.config.url.split('/')
      let whiteBoolean = whiteList.some(
        (item) => item === lastStr[lastStr.length - 1]
      )
      if (!whiteBoolean) {
        if (typeof config.data == 'string') {
          let decryptData = Rsa.aesDecrypt(
            config.config.lost,
            'abcdef1234567890',
            config.data
          )
          config.data = JSON.parse(decryptData)
        }
      }

      console.log(
        '%c%s',
        'color: #f9ae3d',
        'res:' + config.config.url,
        config.data
      )
      if (config.data.code == 402) {
        Message({
          type: 'error',
          message: '无权限操作',
        })
        return {
          code: 402,
          data: [],
          msg: '无权限操作,请联系管理员',
        }
      }
      if (config.data.code == 11001) {
        setTimeout(function () {
          window.sessionStorage.clear()
          router.push('/')
        }, 2000)
      }
      if (config.data.code != 200 && config.data.code != 507 && config.data.code != 15004) {
        Message({
          type: 'error',
          message: config.data.msg,
        })
        return { code: 500, data: { list: [] }, msg: config.data.msg }
      }
      return config.data
    } catch (error) {}
  },
  function (error) {
    // 对响应错误做点什么
    if (error.response.status) {
      switch (error.response.status) {
        //未登录,跳转到登录页并携带当前页路径，登录成功后跳转
        case 507:
          localStorage.removeItem('accessToken')
          router
            .push({
              path: '/login',
            })
            .then((result) => {
              Message({
                type: 'error',
                message: '登录失效，请重新登录',
              })
            })
            .catch((err) => console.log(err))
        case 500:
          return {
            code: null,
            data: { list: [] },
            msg: '接口错误，请联系管理员',
          }

        // 请求不存在
        case 404:
          return {
            code: null,
            data: {},
            msg: '请求资源不存在',
          }
      }
    }
    return Promise.reject(error)
  }
)
export default http
