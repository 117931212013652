// 商城相关接口
import http from './aixos'
// 公共
// 查询流程状态
export const searchQuery = (params) =>
  http.post(`/myItem/approvalProcessQuery`, params)

// 查看当前用户是否有审核权限
export const checkAuditAuthority = (params) =>
  http.post(`/approvalProcess/checkAuditAuthority`, params)
// 上传接口
export const uploadFile = (params) =>
  http.post('/common/uploadFile', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
// 执行待审批任务
export const executeCheckTask = (params) =>
  http.post(`/approvalProcess/executeCheckTask`, params)

// ----------------------------------------------------
// 密码登录接口
export const getUserSignInDetailList = (params) =>
  http.post('/user/login', params)
// 菜单树
export const postAction = (params) => http.post('/menu/queryUserMenu', params)
export const userListList = (params) => http.post('/user/list', params)
export const queryMenuInfo = (params) =>
  http.post('/menu/queryMenuInfo', params)
// 机构树图
export const getDepartTree = (params) =>
  http.post('/depart/getDepartTree', params)
// 机构树图获取部门详情
export const getDeptDetail = (params) =>
  http.post('/depart/getDeptDetail', params)
// 获取部门员工树
export const getDepartTreeNew = (params) =>
  http.post('/depart/getDepartTreeNew', params)
// 机构树图新增或删除
export const saveOrUpdateDept = (params) =>
  http.post('/depart/saveOrUpdateDept', params)
// 机构树图部门下拉
export const getDeptDropdown = (params) =>
  http.post('/depart/getDeptDropdown', params)

// 机构树图岗位下拉
export const getPositionDropdown = (params) =>
  http.post('	/depart/getPositionDropdown', params)
// 机构树图岗位新增删除
export const saveOrUpdatePosition = (params) =>
  http.post('/depart/saveOrUpdatePosition', params)
// 根据部门id获取岗位
export const getAllPositionByDeptId = (params) =>
  http.post('/depart/getAllPositionByDeptId', params)

// 物理字典新增
export const saveOrUpdateDicInfo = (params) =>
  http.post('/common/saveOrUpdateDicInfo', params)

//新增或修改班组管理
export const saveOrUpdateTeamInfo = (params) =>
  http.post('/basic/saveOrUpdateTeamInfo', params)

//员工下拉
export const queryStaffDropdown = (params) =>
  http.post('/archive/queryStaffDropdown', params)

//新增或修改员工档案
export const saveOrUpdateStaffInfo = (params) =>
  http.post('/archive/saveOrUpdateStaffInfo', params)
//查询员工档案详情
export const queryStaffInfoDetail = (params) =>
  http.post('/archive/queryStaffInfoDetail', params)

// ----
//新增岗位能力
export const saveOrUpdatePositionCapability = (params) =>
  http.post('/basic/saveOrUpdatePositionCapability', params)
//删除岗位能力
export const deletePositionCapability = (params) =>
  http.post('/basic/deletePositionCapability', params)

// 同步岗位能力
export const syncedPositionCapability = (params) =>
  http.post('/basic/syncedPositionCapability', params)

//删除打卡规则
export const deleteClockIn = (params) =>
  http.post('/clockIn/deleteClockIn', params)
//更新打卡规则
export const updateClockIn = (params) =>
  http.post('/clockIn/updateClockIn', params)
//添加打卡规则
export const saveClockIn = (params) => http.post('/clockIn/saveClockIn', params)
// 根据id查询打卡规则详情
export const queryClockInDetailById = (params) =>
  http.post('/clockIn/queryClockInDetailById', params)

//新增考勤设备
export const saveAttendanceMachines = (params) =>
  http.post('/attendance/saveAttendanceMachines', params)
//修改考勤设备
export const updateAttendanceMachines = (params) =>
  http.post('/attendance/updateAttendanceMachines', params)
// 查询考勤设备
// /attendance/queryAttendanceMachinesPageList
// 考勤设备详情
export const queryAttendanceMachinesDetail = (params) =>
  http.post('/attendance/queryAttendanceMachinesDetail', params)
// 考勤设备下拉
export const queryAttendanceMachinesList = (params) =>
  http.post('/attendance/queryAttendanceMachinesList', params)
// 删除考勤设备
export const deleteAttendanceMachines = (params) =>
  http.post('/attendance/deleteAttendanceMachines', params)

// 批量修改考勤打卡记录
export const batchSaveClockRecord = (params) =>
  http.post('/employeeOaApplyManage/batchSaveClockRecord', params)

// 员工加班汇总查询
export const queryOvertimeSummary = (params) =>
  http.post('/aggregateQuery/queryOvertimeSummary', params)

// 通过时间计算加班小时
export const getOvertimeApplyDayAndHour = (params) =>
  http.post('/employeeOaApplyManage/getOvertimeApplyDayAndHour', params)

// 班次管理
// 班次批量更新
export const batchUpdateShiftManage = (params) =>
  http.post('/shiftmanagement/batchUpdateShiftManage', params)
// 班次批量移除
export const batchRemoveShiftManage = (params) =>
  http.post('/shiftmanagement/batchRemoveShiftManage', params)
// 班次管理新增
export const saveShiftManage = (params) =>
  http.post('/shiftmanagement/saveShiftManage', params)
// 班次管理修改
export const updateShiftManage = (params) =>
  http.post('/shiftmanagement/updateShiftManage', params)
// 班次管理删除
export const deleteShiftManage = (params) =>
  http.post('/shiftmanagement/deleteShiftManage', params)

// 员工节假日配置列表
export const queryStaffHolidayConfigPageList = (params) =>
  http.post('/archive/queryStaffHolidayConfigPageList', params)
// 编辑员工节假日配置
export const updateStaffHolidayConfig = (params) =>
  http.post('/archive/updateStaffHolidayConfig', params)

// 班次类型配置列表
export const queryStaffClassTypePageList = (params) =>
  http.post('/shiftmanagement/queryStaffClassTypePageList', params)
// 编辑班次类型配置
export const UpdateStaffClassType = (params) =>
  http.post('/shiftmanagement/updateStaffClassType', params)

// 查询班次作为下拉字典使用
export const queryAllShfitManage = (params) =>
  http.post('/shiftmanagement/queryAllShfitManage', params)

export const queryEmploymentInfoById = (params) =>
  http.post('/archive/queryEmploymentInfoById', params)

// 新增员工合同信息
export const saveStaffContract = (params) =>
  http.post('/archive/saveStaffContract', params)
// 编辑员工合同信息
export const updateStaffContract = (params) =>
  http.post('/archive/updateStaffContract', params)
// 批量修改合同年限
export const batchSaveStaffContract = (params) =>
  http.post('/archive/batchSaveStaffContract', params)
// 能力更新
export const updateStaffPositionAbility = (params) =>
  http.post('/archive/updateStaffPositionAbility', params)

// 签名照片新增
export const saveSignaturePhoto = (params) =>
  http.post('/archive/saveSignaturePhoto', params)
// 签名照片删除
export const deleteSignaturePhoto = (params) =>
  http.post('/archive/deleteSignaturePhoto', params)
// 签名照片编辑
export const updateSignaturePhoto = (params) =>
  http.post('/archive/updateSignaturePhoto', params)

// 新增司机基础信息
export const saveDriverBasicInfo = (params) =>
  http.post('/carManage/saveDriverBasicInfo', params)
// 编辑司机基础信息
export const updateDriverBasicInfo = (params) =>
  http.post('/carManage/updateDriverBasicInfo', params)
// 删除司机基础信息
export const deleteDriverBasicInfo = (params) =>
  http.post('/carManage/deleteDriverBasicInfo', params)
// 新增会议室
export const saveMeetingRoom = (params) =>
  http.post('/meeting/saveMeetingRoom', params)
// 删除会议室
export const deleteMeetingRoom = (params) =>
  http.post('/meeting/deleteMeetingRoom', params)
// 修改会议室
export const updateMeetingRoom = (params) =>
  http.post('/meeting/updateMeetingRoom', params)
// 会议室列表
export const queryMeetingRoomPageList = (params) =>
  http.post('/meeting/queryMeetingRoomPageList', params)

// 新增会议室预约
export const saveMeetingRoomReservation = (params) =>
  http.post('/meeting/saveMeetingRoomReservation', params)
// 取消会议室预约
export const cancelMeetingRoomReservation = (params) =>
  http.post('/meeting/cancelMeetingRoomReservation', params)
// 修改会议室预约
export const updateMeetingRoomReservation = (params) =>
  http.post('/meeting/updateMeetingRoomReservation', params)

// 新增宿舍
export const saveDormitory = (params) =>
  http.post('/dormitory/saveDormitory', params)

// 更新宿舍
export const updateDormitory = (params) =>
  http.post('/dormitory/updateDormitory', params)

// 查询宿舍详情
export const queryDormitoryDetail = (params) =>
  http.post('/dormitory/queryDormitoryDetail', params)

// 新增水电抄表记录
export const saveDormitoryUtilities = (params) =>
  http.post('/dormitory/saveDormitoryUtilities', params)
// 更新水电抄表记录
export const updateDormitoryUtilities = (params) =>
  http.post('/dormitory/updateDormitoryUtilities', params)
// 删除水电抄表记录
export const deleteDormitoryUtilities = (params) =>
  http.post('/dormitory/deleteDormitoryUtilities', params)
// 班组主档接口
export const queryTeamPageList = (params) =>
  http.post('/basic/queryTeamPageList', params)
// 班组批量新增员工
export const batchInsertTeam = (params) =>
  http.post('/basic/batchInsertTeam', params)
// 批量修改员工班次
export const batchUpdateStaffClasses = (params) =>
  http.post('/shiftmanagement/batchUpdateStaffClasses', params)

// --------
// 银行卡信息详情
export const queryStaffBankCardDetail = (params) =>
  http.post('/archive/queryStaffBankCardDetail', params)
// 编辑银行卡信息
export const updateStaffBankCard = (params) =>
  http.post('/archive/updateStaffBankCard', params)

//创建审批模板
export const createTemplate = (params) =>
  http.post(`/processTemplate/createTemplate`, params)

//查询审批模板列表
export const queryProcessTemplatePageList = (params) =>
  http.post(`/processTemplate/queryProcessTemplatePageList`, params)

//保存模型
export const saveModelXml = (params) =>
  http.post(`/processTemplate/saveModelXml`, params)

// 修改审批模板
export const updateTemplate = (params) =>
  http.post(`/processTemplate/updateTemplate`, params)

// 模板删除
export const deleteTemplate = (params) =>
  http.post(`/processTemplate/deleteTemplate`, params)

// 根据生成的ID获取模型流程编辑器
export const getModelByModelId = (params) =>
  http.post(`/processTemplate/getModelByModelId`, params)

// 部署流程
export const deploymentInterface = (params) =>
  http.post(`/approvalProcess/deploymentInterface`, params)

// 招聘管理
// 招聘申请分页查询
export const queryRecruitmentApplyRecordPageList = (params) =>
  http.post(`/recruitmentManage/queryRecruitmentApplyRecordPageList`, params)
// 保存招聘申请
export const saveRecruitmentApplyRecord = (params) =>
  http.post(`/recruitmentManage/saveRecruitmentApplyRecord`, params)
// 修改招聘申请
export const updateRecruitmentApplyRecord = (params) =>
  http.post(`/recruitmentManage/updateRecruitmentApplyRecord`, params)
// 查询招聘申请详情
export const queryRecruitmentApplyRecordDetail = (params) =>
  http.post(`/recruitmentManage/queryRecruitmentApplyRecordDetail`, params)
// 招聘岗位查询
export const queryRecruitmentPositionPageList = (params) =>
  http.post(`/recruitmentManage/queryRecruitmentPositionPageList`, params)
// 修改招聘岗位
export const updateRecruitmentPosition = (params) =>
  http.post(`/recruitmentManage/updateRecruitmentPosition`, params)
//  保存入职申请
export const saveStaffInductionApply = (params) =>
  http.post(`/personnelManagement/saveStaffInductionApply`, params)
//  保存离职申请信息
export const saveStaffDepartApply = (params) =>
  http.post(`/personnelManagement/saveStaffDepartApply`, params)
//  离职申请详情
export const queryStaffDepartApplyDetail = (params) =>
  http.post(`/personnelManagement/queryStaffDepartApplyDetail`, params)
//  保存再入职申请信息
export const saveStaffReInductionApply = (params) =>
  http.post(`/personnelManagement/saveStaffReInductionApply`, params)
//  查询入职申请信息
export const queryStaffInductionApplyDetail = (params) =>
  http.post(`/personnelManagement/queryStaffInductionApplyDetail`, params)
//  查询再入职申请信息
export const queryStaffReInductionApplyDetail = (params) =>
  http.post(`/personnelManagement/queryStaffReInductionApplyDetail`, params)
//  查询离职申请信息
export const queryStaffDepartApplyPageList = (params) =>
  http.post(`/personnelManagement/queryStaffDepartApplyPageList`, params)
//  删除入职申请信息
export const deleteStaffInductionApply = (params) =>
  http.post(`/personnelManagement/deleteStaffInductionApply`, params)
//  删除再入职申请信息
export const deleteStaffReInductionApply = (params) =>
  http.post(`/personnelManagement/deleteStaffReInductionApply`, params)
//  删除离开职申请信息
export const deleteDepartApply = (params) =>
  http.post(`/personnelManagement/deleteDepartApply`, params)
//  修改入职申请信息
export const updateStaffInductionApply = (params) =>
  http.post(`/personnelManagement/updateStaffInductionApply`, params)
//  修改再入职申请信息
export const updateStaffReInductionApply = (params) =>
  http.post(`/personnelManagement/updateStaffReInductionApply`, params)
//  修改再入职申请信息
export const updateDepartApply = (params) =>
  http.post(`/personnelManagement/updateDepartApply`, params)
//  员工入职申请审核
export const checkStaffInductionApply = (params) =>
  http.post(`/personnelManagement/checkStaffInductionApply`, params)
//  员工再入职申请审核
export const checkStaffReInductionApply = (params) =>
  http.post(`/personnelManagement/checkStaffReInductionApply`, params)
//  员工离职申请审核
export const checkDepartApply = (params) =>
  http.post(`/personnelManagement/checkDepartApply`, params)
//  应聘者信息
export const queryApplicantInfoPageList = (params) =>
  http.post(`/recruitmentManage/queryApplicantInfoPageList`, params)
//  获取员工档案信息
export const queryStaffInfoPageList = (params) =>
  http.post(`/archive/queryStaffInfoPageList`, params)

// 查询审批结果
export const approvalDetailsQuery = (params) =>
  http.post(`/myItem/approvalDetailsQuery`, params)
// 招聘申请审核
export const checkRecruitmentApply = (params) =>
  http.post(`/recruitmentManage/checkRecruitmentApply`, params)

// 保存应聘者信息
export const saveApplicantInfo = (params) =>
  http.post(`/recruitmentManage/saveApplicantInfo`, params)
// 修改应聘者信息
export const updateApplicantInfo = (params) =>
  http.post(`/recruitmentManage/updateApplicantInfo`, params)
// 删除应聘者信息
export const deleteApplicantInfo = (params) =>
  http.post(`/recruitmentManage/deleteApplicantInfo`, params)
// 查询应聘者信息详情
export const queryApplicantInfoDetail = (params) =>
  http.post(`/recruitmentManage/queryApplicantInfoDetail`, params)

// 保存企业黑名单
export const saveEnterpriseBlacklist = (params) =>
  http.post(`/recruitmentManage/saveEnterpriseBlacklist`, params)

// 删除企业黑名单
export const deleteEnterpriseBlacklist = (params) =>
  http.post(`/recruitmentManage/deleteEnterpriseBlacklist`, params)
// 修改企业黑名单
export const updateEnterpriseBlacklist = (params) =>
  http.post(`/recruitmentManage/updateEnterpriseBlacklist`, params)

// 保存企业人才库
export const saveEnterpriseTalentBank = (params) =>
  http.post(`/recruitmentManage/saveEnterpriseTalentBank`, params)
// 修改企业人才库
export const updateEnterpriseTalentBank = (params) =>
  http.post(`/recruitmentManage/updateEnterpriseTalentBank`, params)
// 删除企业人才库
export const deleteEnterpriseTalentBank = (params) =>
  http.post(`/recruitmentManage/deleteEnterpriseTalentBank`, params)

// 转入企业人才库
export const addEnterpriseTalentBank = (params) =>
  http.post(`/recruitmentManage/addEnterpriseTalentBank`, params)

// 修改面试结果
export const updateInterviewResult = (params) =>
  http.post(`/recruitmentManage/updateInterviewResult`, params)
// getMenuAttribute
export const getMenuAttribute = (params) =>
  http.post(`/processTemplate/getMenuAttribute`, params)

// saveMenuAttribute
export const saveMenuAttribute = (params) =>
  http.post(`/processTemplate/saveMenuAttribute`, params)

// 提交申请/重新提交申请
export const approvalProcessApply = (params) =>
  http.post(`/approvalProcess/approvalProcessApply`, params)
// 联络单
// 查看联络通知单详情页
export const queryCommunicationOrderDetail = (params) =>
  http.post(`/communicationOrder/queryCommunicationOrderDetail`, params)
// 删除联络通知单
export const deleteCommunicationOrder = (params) =>
  http.post(`/communicationOrder/deleteCommunicationOrder`, params)
// 修改联络通知单
export const updateCommunicationOrder = (params) =>
  http.post(`/communicationOrder/updateCommunicationOrder`, params)
// 保存联络通知单
export const saveCommunicationOrder = (params) =>
  http.post(`/communicationOrder/saveCommunicationOrder`, params)

// 联络通知单下拉
export const queryCommunicationOrderDropdown = (params) =>
  http.post(`/communicationOrder/queryCommunicationOrderDropdown`, params)

// 保存加班申请
export const saveEmployeeOverTimeApplyRecord = (params) =>
  http.post(`/employeeOaApplyManage/saveEmployeeOverTimeApplyRecord`, params)
// 修改加班申请
export const updateEmployeeOverTimeApplyRecord = (params) =>
  http.post(`/employeeOaApplyManage/updateEmployeeOverTimeApplyRecord`, params)
// 删除加班申请
export const deleteEmployeeOvetTimeApplyRecord = (params) =>
  http.post(`/employeeOaApplyManage/deleteEmployeeOvetTimeApplyRecord`, params)
// 查询加班申请详情
export const queryEmployeeOverTimeApplyRecordDetail = (params) =>
  http.post(
    `/employeeOaApplyManage/queryEmployeeOverTimeApplyRecordDetail`,
    params
  )
// 加班申请审核
export const checkEmployeeOverTimeApply = (params) =>
  http.post(`/employeeOaApplyManage/checkEmployeeOverTimeApply`, params)

/* 补卡模块 */
// 保存补卡申请
export const saveEmployeeCardReplacement = (params) =>
  http.post(`/employeeOaApplyManage/saveEmployeeCardReplacement`, params)
// 修改补卡申请
export const updateEmployeeCardReplacement = (params) =>
  http.post(`/employeeOaApplyManage/updateEmployeeCardReplacement`, params)
// 删除补卡申请
export const deleteEmployeeCardReplacement = (params) =>
  http.post(`/employeeOaApplyManage/deleteEmployeeCardReplacement`, params)
// 查询补卡申请详情
export const queryEmployeeCardReplacementDetail = (params) =>
  http.post(`/employeeOaApplyManage/queryEmployeeCardReplacementDetail`, params)
// 查询所有补卡类型
export const queryAllCardReplacementType = (params) =>
  http.post(`/employeeOaApplyManage/queryAllCardReplacementType`, params)
// 审核补卡
export const checkEmployeeCardReplacement = (params) =>
  http.post(`/employeeOaApplyManage/checkEmployeeCardReplacement`, params)
// 查询补卡分页列表
// employeeOaApplyManage/queryEmployeeCardReplacementPageList

/* 请假模块 */
// 保存请假申请
export const saveEmployeeLeaveApplicationRecord = (params) =>
  http.post(`/employeeOaApplyManage/saveEmployeeLeaveApplicationRecord`, params)
// 修改请假申请
export const updateEmployeeLeaveApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/updateEmployeeLeaveApplicationRecord`,
    params
  )
// 删除请假申请
export const deleteEmployeeLeaveApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/deleteEmployeeLeaveApplicationRecord`,
    params
  )
// 查询请假申请详情
export const queryEmployeeLeaveApplicationRecordDetail = (params) =>
  http.post(
    `/employeeOaApplyManage/queryEmployeeLeaveApplicationRecordDetail`,
    params
  )
// 查询所有请假类型
export const queryAllLeaveType = (params) =>
  http.post(`/employeeOaApplyManage/queryAllLeaveType`, params)
// 审核请假
export const checkEmployeeLeaveApply = (params) =>
  http.post(`/employeeOaApplyManage/checkEmployeeLeaveApply`, params)
// 查询请假分页列表
// employeeOaApplyManage/queryEmployeeLeaveApplicationRecordPageList

/* 年假模块 */
// 保存年假申请
export const saveEmployeeAnnualLeaveApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/saveEmployeeAnnualLeaveApplicationRecord`,
    params
  )
// 修改年假申请
export const updateEmployeeAnnualLeaveApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/updateEmployeeAnnualLeaveApplicationRecord`,
    params
  )
// 删除年假申请
export const deleteEmployeeAnnualLeaveApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/deleteEmployeeAnnualLeaveApplicationRecord`,
    params
  )
// 查询年假申请详情
export const queryEmployeeAnnualLeaveApplicationRecordDetail = (params) =>
  http.post(
    `/employeeOaApplyManage/queryEmployeeAnnualLeaveApplicationRecordDetail`,
    params
  )
// 查询员工年假
export const queryStaffAnnualLeave = (params) =>
  http.post(`/archive/queryStaffAnnualLeave`, params)
// 审核年假
export const checkEmployeeAnnualLeaveApplication = (params) =>
  http.post(
    `/employeeOaApplyManage/checkEmployeeAnnualLeaveApplication`,
    params
  )
// 查询年假分页列表
// employeeOaApplyManage/queryEmployeeAnnualLeaveApplicationRecordPageList

/* 年假批处理模块 */
// 保存年假批处理申请
export const saveAnnualLeaveBatchProcess = (params) =>
  http.post(`/employeeOaApplyManage/saveAnnualLeaveBatchProcess`, params)
// 修改年假批处理申请
export const updateAnnualLeaveBatchProcess = (params) =>
  http.post(`/employeeOaApplyManage/updateAnnualLeaveBatchProcess`, params)
// 删除年假批处理申请
export const deleteAnnualLeaveBatchProcess = (params) =>
  http.post(`/employeeOaApplyManage/deleteAnnualLeaveBatchProcess`, params)
// 查询年假批处理申请详情
export const queryAnnualLeaveBatchProcessDetail = (params) =>
  http.post(`/employeeOaApplyManage/queryAnnualLeaveBatchProcessDetail`, params)
// 审核年假批处理
export const checkAnnualLeaveBatchProcessApply = (params) =>
  http.post(`/employeeOaApplyManage/checkAnnualLeaveBatchProcessApply`, params)
// 查询年假批处理分页列表
// /employeeOaApplyManage/queryAnnualLeaveBatchProcessPageList

/* 休假撤销模块 */
// 保存休假撤销申请
export const saveEmployeeLeaveCancelRecord = (params) =>
  http.post(`/employeeOaApplyManage/saveEmployeeLeaveCancelRecord`, params)
// 修改休假撤销申请
export const updateEmployeeLeaveCancelRecord = (params) =>
  http.post(`/employeeOaApplyManage/updateEmployeeLeaveCancelRecord`, params)
// 删除休假撤销申请
export const deleteEmployeeLeaveCancelRecord = (params) =>
  http.post(`/employeeOaApplyManage/deleteEmployeeLeaveCancelRecord`, params)
// 查询休假撤销申请详情
export const queryEmployeeLeaveCancelRecordDetail = (params) =>
  http.post(
    `/employeeOaApplyManage/queryEmployeeLeaveCancelRecordDetail`,
    params
  )
// 查询所有休假撤销单类型
export const queryAllCancelRecordType = (params) =>
  http.post(`/employeeOaApplyManage/queryAllCancelRecordType`, params)
// 审核休假撤销申请
export const checkEmployeeLeaveCancelRecord = (params) =>
  http.post(`/employeeOaApplyManage/checkEmployeeLeaveCancelRecord`, params)
// 查询休假撤销分页列表
// /employeeOaApplyManage/queryEmployeeLeaveCancelRecordPageList

// 保存公差外出
export const saveEmployeeOutingApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/saveEmployeeOutingApplicationRecord`,
    params
  )
// 更新公差外出
export const updateEmployeeOutingApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/updateEmployeeOutingApplicationRecord`,
    params
  )
// 删除公差外出
export const deleteEmployeeOutingApplicationRecord = (params) =>
  http.post(
    `/employeeOaApplyManage/deleteEmployeeOutingApplicationRecord`,
    params
  )
// 查询公差外出申请详情
export const queryEmployeeOutingApplicationRecordDetail = (params) =>
  http.post(
    `/employeeOaApplyManage/queryEmployeeOutingApplicationRecordDetail`,
    params
  )
// 审核公差外出申请详情
export const checkEmployeeOutingApply = (params) =>
  http.post(`/employeeOaApplyManage/checkEmployeeOutingApply`, params)
// 保存调休申请
export const saveEmployeeLeaveAdjustment = (params) =>
  http.post(`/employeeOaApplyManage/saveEmployeeLeaveAdjustment`, params)
// 修改调休申请
export const updateEmployeeLeaveAdjustment = (params) =>
  http.post(`/employeeOaApplyManage/updateEmployeeLeaveAdjustment`, params)
// 删除调休申请
export const deleteEmployeeLeaveAdjustment = (params) =>
  http.post(`/employeeOaApplyManage/deleteEmployeeLeaveAdjustment`, params)
// 查询调休详情
export const queryEmployeeLeaveAdjustmentDetail = (params) =>
  http.post(`/employeeOaApplyManage/queryEmployeeLeaveAdjustmentDetail`, params)
// 审核调休
export const checkEmployeeLeaveAdjustmentApply = (params) =>
  http.post(`/employeeOaApplyManage/checkEmployeeLeaveAdjustmentApply`, params)
// 保存审批授权
export const saveApprovalAuthorization = (params) =>
  http.post(`/approvalAuthorization/saveApprovalAuthorization`, params)
// 修改审批授权
export const updateApprovalAuthorization = (params) =>
  http.post(`/approvalAuthorization/updateApprovalAuthorization`, params)
// 取消审批授权
export const cancelApprovalAuthorization = (params) =>
  http.post(`/approvalAuthorization/cancelApprovalAuthorization`, params)

// 保存员工转正申请
export const saveRegularizationApply = (params) =>
  http.post(`/personnelManagement/saveRegularizationApply`, params)
// 修改员工转正申请
export const updateRegularizationApply = (params) =>
  http.post(`/personnelManagement/updateRegularizationApply`, params)
// 删除员工转正申请
export const deleteRegularizationApply = (params) =>
  http.post(`/personnelManagement/deleteRegularizationApply`, params)
// 员工转正申请详情
export const queryRegularizationApplyDetail = (params) =>
  http.post(`/personnelManagement/queryRegularizationApplyDetail`, params)
// 员工转正单审核
export const checkRegularizationApply = (params) =>
  http.post(`/personnelManagement/checkRegularizationApply`, params)

// 保存员工调岗申请
export const saveJobTransferApply = (params) =>
  http.post(`/personnelManagement/saveJobTransferApply`, params)
// 修改员工调岗申请
export const updateJobTransferApply = (params) =>
  http.post(`/personnelManagement/updateJobTransferApply`, params)
// 删除员工调岗申请
export const deleteJobTransferApply = (params) =>
  http.post(`/personnelManagement/deleteJobTransferApply`, params)
// 员工调岗申请详情
export const queryJobTransferApplyDetail = (params) =>
  http.post(`/personnelManagement/queryJobTransferApplyDetail`, params)
// 员工调岗单审核
export const checkJobTransferApply = (params) =>
  http.post(`/personnelManagement/checkJobTransferApply`, params)

// 获取流程模板详情
export const queryProcessTemplateDetail = (params) =>
  http.post(`/processTemplate/queryProcessTemplateDetail`, params)

// 获取字典列表
export const queryDicInfoList = (params) =>
  http.post('/common/queryDicInfoList', params)

// 根据编号获取字典
export const queryDicInfoByCode = (params) =>
  http.post('/common/queryDicInfoByCode', params)

/* 员工调薪申请 */
// 保存员工调薪申请
export const saveSalaryAdjustmentApply = (params) =>
  http.post(`/personnelManagement/saveSalaryAdjustmentApply`, params)
// 修改员工调薪申请
export const updateSalaryAdjustmentApply = (params) =>
  http.post(`/personnelManagement/updateSalaryAdjustmentApply`, params)
// 删除员工调薪申请
export const deleteSalaryAdjustmentApply = (params) =>
  http.post(`/personnelManagement/deleteSalaryAdjustmentApply`, params)
// 员工调薪申请详情
export const querySalaryAdjustmentApplyDetail = (params) =>
  http.post(`/personnelManagement/querySalaryAdjustmentApplyDetail`, params)
// 查询员工调薪分页列表
// /personnelManagement/querySalaryAdjustmentApplyPageList
// 员工调薪单审核
export const checkSalaryAdjustmentApply = (params) =>
  http.post(`/personnelManagement/checkSalaryAdjustmentApply`, params)

/*车辆申请*/
// 保存车辆申请
export const saveCarUseApply = (params) =>
  http.post(`/carManage/saveCarUseApply`, params)
// 修改车辆申请
export const updateCarUseApply = (params) =>
  http.post(`/carManage/updateCarUseApply`, params)
// 删除车辆申请
export const deleteCarUseApply = (params) =>
  http.post(`/carManage/deleteCarUseApply`, params)
// 查询车辆申请详情
export const queryCarUseApplyDetail = (params) =>
  http.post(`/carManage/queryCarUseApplyDetail`, params)
// 审核车辆申请
export const checkCarUseApply = (params) =>
  http.post(`/carManage/checkCarUseApply`, params)
// 查询车辆申请分页列表
// /carManage/queryCarUseApplyPageList

/* 派送任务 */
// 查询派送任务详情
export const queryCarDispatchTaskDetail = (params) =>
  http.post(`/carManage/queryCarDispatchTaskDetail`, params)
// 查询车辆申请分页列表
// /carManage/queryCarDispatchTaskPageList

/*办公设备*/
/*办公设备申请*/
// 保存办公设备申请
export const saveOfficeSupplyApply = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyApply`, params)
// 修改办公设备申请
export const updateOfficeSupplyApply = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyApply`, params)
// 删除办公设备
export const deleteOfficeSupplyApply = (params) =>
  http.post(`/officeSupplyManage/deleteOfficeSupplyApply`, params)
// 查询办公设备详情
export const queryOfficeSupplyApplyDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyApplyDetail`, params)
// 审核办公设备
export const checkOfficeSupplyApply = (params) =>
  http.post(`/officeSupplyManage/checkOfficeSupplyApply`, params)
// 查询办公设备分页列表
// /officeSupplyManage/queryOfficeSupplyApplyPageList

/* 办公设备入库 */
export const saveOfficeSupplyWarehousingApply = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyWarehousingApply`, params)
// 修改办公设备入库申请
export const updateOfficeSupplyWarehousingApply = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyWarehousingApply`, params)
// 删除办公设备入库
export const deleteOfficeSupplyWarehousingApply = (params) =>
  http.post(`/officeSupplyManage/deleteOfficeSupplyWarehousingApply`, params)
// 查询办公设备入库详情
export const queryOfficeSupplyWarehousingApplyDetail = (params) =>
  http.post(
    `/officeSupplyManage/queryOfficeSupplyWarehousingApplyDetail`,
    params
  )
// 审核办公设备入库
export const checkOfficeSupplyWarehousingApply = (params) =>
  http.post(`/officeSupplyManage/checkOfficeSupplyWarehousingApply`, params)
// 查询办公设备入库分页列表
// /officeSupplyManage/queryOfficeSupplyWarehousingApplyPageList

/* 办公设备库存 */
export const saveOfficeSupplyInventory = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyInventory`, params)
// 修改办公设备库存
export const updateOfficeSupplyInventory = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyInventory`, params)
// 查询办公设备库存明细
export const queryOfficeSupplyInventoryDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyInventoryDetail`, params)
// 查询办公设备库存详情
export const queryOfficeSupplyInventoryInfo = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyInventoryInfo`, params)
// 查询办公设备库存分页列表
// /officeSupplyManage/queryOfficeSupplyInventoryPageList

/* 办公设备一览 */
// 查询办公设备库存详情
export const queryOfficeSupplyOverviewDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyOverviewDetail`, params)
// 查询办公设备库存分页列表
export const queryOfficeSupplyOverviewPageList = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyOverviewPageList`, params)

/*办公设备领用申请*/
// 保存办公设备领用申请
export const saveOfficeSupplyRequisitionApply = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyRequisitionApply`, params)
// 修改办公设备领用申请
export const updateOfficeSupplyRequisitionApply = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyRequisitionApply`, params)
// 删除办公设备领用
export const deleteOfficeSupplyRequisitionApply = (params) =>
  http.post(`/officeSupplyManage/deleteOfficeSupplyRequisitionApply`, params)
// 查询办公设备领用详情
export const queryOfficeSupplyRequisitionApplyDetail = (params) =>
  http.post(
    `/officeSupplyManage/queryOfficeSupplyRequisitionApplyDetail`,
    params
  )
// 审核办公设备领用
export const checkOfficeSupplyRequisitionApply = (params) =>
  http.post(`/officeSupplyManage/checkOfficeSupplyRequisitionApply`, params)
// 办公设备领用出库
export const officeSupplyOutbound = (params) =>
  http.post(`/officeSupplyManage/officeSupplyOutbound`, params)
// 查询办公设备领用分页列表
// /officeSupplyManage/queryOfficeSupplyRequisitionApplyPageList

/*办公设备归还申请*/
// 保存办公设备归还申请
export const saveOfficeSupplyReturnApply = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyReturnApply`, params)
// 修改办公设备归还申请
export const updateOfficeSupplyReturnApply = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyReturnApply`, params)
// 删除办公设备归还
export const deleteOfficeSupplyReturnApply = (params) =>
  http.post(`/officeSupplyManage/deleteOfficeSupplyReturnApply`, params)
// 查询办公设备归还详情
export const queryOfficeSupplyReturnApplyDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyReturnApplyDetail`, params)
// 审核办公设备归还
export const checkOfficeSupplyReturnApply = (params) =>
  http.post(`/officeSupplyManage/checkOfficeSupplyReturnApply`, params)
// 查询办公设备归还分页列表
// /officeSupplyManage/queryOfficeSupplyReturnApplyPageList

/*办公设备报修申请*/
// 保存办公设备报修申请
export const saveOfficeSupplyRepairApply = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyRepairApply`, params)
// 修改办公设备报修申请
export const updateOfficeSupplyRepairApply = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyRepairApply`, params)
// 删除办公设备报修
export const deleteOfficeSupplyRepairApply = (params) =>
  http.post(`/officeSupplyManage/deleteOfficeSupplyRepairApply`, params)
// 查询办公设备报修详情
export const queryOfficeSupplyRepairApplyDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyRepairApplyDetail`, params)
// 审核办公设备报修
export const checkOfficeSupplyRepairApply = (params) =>
  http.post(`/officeSupplyManage/checkOfficeSupplyRepairApply`, params)
// 查询办公设备报修分页列表
// /officeSupplyManage/queryOfficeSupplyRepairApplyPageList
// 网管修改办公设备报修申请
export const modifyOfficeSupplyRepairApply = (params) =>
  http.post(`/officeSupplyManage/modifyOfficeSupplyRepairApply`, params)

/*办公设备报废申请*/
// 保存办公设备报废申请
export const saveOfficeSupplyScrapApply = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyScrapApply`, params)
// 修改办公设备报废申请
export const updateOfficeSupplyScrapApply = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyScrapApply`, params)
// 删除办公设备报废
export const deleteOfficeSupplyScrapApply = (params) =>
  http.post(`/officeSupplyManage/deleteOfficeSupplyScrapApply`, params)
// 查询办公设备报废详情
export const queryOfficeSupplyScrapApplyDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyScrapApplyDetail`, params)
// 审核办公设备报废
export const checkOfficeSupplyScrapApply = (params) =>
  http.post(`/officeSupplyManage/checkOfficeSupplyScrapApply`, params)
// 查询办公设备报废分页列表
// /officeSupplyManage/queryOfficeSupplyScrapApplyPageList

/* 办公设备盘点记录 */
// 查询办公设备盘点记录分页列表
// /officeSupplyManage/queryOfficeSupplyCheckRecordPageList

/*办公设备转移*/
// 保存办公设备转移
export const saveOfficeSupplyHandOn = (params) =>
  http.post(`/officeSupplyManage/saveOfficeSupplyHandOn`, params)
// 修改办公设备转移
export const updateOfficeSupplyHandOn = (params) =>
  http.post(`/officeSupplyManage/updateOfficeSupplyHandOn`, params)
// 查询办公设备转移详情
export const queryOfficeSupplyHandOnDetail = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyHandOnDetail`, params)
// 查询办公设备转移分页列表
export const queryOfficeSupplyHandOnPageList = (params) =>
  http.post(`/officeSupplyManage/queryOfficeSupplyHandOnPageList`, params)
// 离职时办公设备转移
export const handOnOfficeSupply = (params) =>
  http.post(`/officeSupplyManage/handOnOfficeSupply`, params)

// 修改员工考勤记录
export const updateEmployeeAttendanceRecord = (params) =>
  http.post(`/employeeOaApplyManage/updateEmployeeAttendanceRecord`, params)
// 员工考勤记录详情
export const queryEmployeeAttendanceRecordDetail = (params) =>
  http.post(
    `/employeeOaApplyManage/queryEmployeeAttendanceRecordDetail`,
    params
  )
// 查询员工考勤记录列表
// /employeeOaApplyManage/queryEmployeeAttendanceRecord

// 查询打卡记录
// /clockIn/queryClockInRecordPageList

/*员工通讯录*/
// 保存员工通讯录
export const saveAddressBookRecord = (params) =>
  http.post(`/dataBoard/saveAddressBookRecord`, params)
// 修改员工通讯录
export const updateAddressBookRecord = (params) =>
  http.post(`/dataBoard/updateAddressBookRecord`, params)
// 删除员工通讯录
export const deleteAddressBookRecord = (params) =>
  http.post(`/dataBoard/deleteAddressBookRecord`, params)
// 查询员工通讯录详情
export const queryAddressBookRecordDetail = (params) =>
  http.post(`/dataBoard/queryAddressBookRecordDetail`, params)
// 查询员工通讯录列表
// /dataBoard/queryAddressBookRecordPageList

// 我的班次
// /basic/queryStaffHistoryClassPageList

/*保险信息变更*/
// 保存保险信息变更
export const saveStaffInsuranceChangeApply = (params) =>
  http.post(`/archive/saveStaffInsuranceChangeApply`, params)
// 修改保险信息变更
export const updateStaffInsuranceChangeApply = (params) =>
  http.post(`/archive/updateStaffInsuranceChangeApply`, params)
// 删除保险信息变更
export const deleteStaffInsuranceChangeApply = (params) =>
  http.post(`/archive/deleteStaffInsuranceChangeApply`, params)
// 查询保险信息变更详情
export const queryStaffInsuranceChangeApplyDetail = (params) =>
  http.post(`/archive/queryStaffInsuranceChangeApplyDetail`, params)
// 审核保险信息变更
export const checkStaffInsuranceChangeApply = (params) =>
  http.post(`/archive/checkStaffInsuranceChangeApply`, params)
// 查询保险信息变更列表
// /archive/queryStaffInsuranceChangeApplyPageList

// 数据看板
// 人员结构列表
export const queryStructurePersonnel = (params) =>
  http.post(`/dataBoard/queryStructurePersonnel`, params)
// 当日入职员工信息
// export const queryStaffInfoPageList = (params) =>
//   http.post(`/dataBoard/queryStaffInfoPageList`, params)
// 查询门禁数据
export const queryAccessControlData = (params) =>
  http.post(`/dataBoard/queryAccessControlData`, params)
// 部门人员统计
export const queryStatisticsDeptPersonnelSituation = (params) =>
  http.post(`/dataBoard/queryStatisticsDeptPersonnelSituation`, params)
// 班组长名单
// /dataBoard/queryStatisticsLeader
// 车牌查询
// /dataBoard/queryStaffCarNoPageList
// 修改车牌号
export const updateCarNo = (params) => http.post(`/archive/updateCarNo`, params)
// 员工门禁次数查询
// /dataBoard/queryStaffAccessControlStatisticsPageList

// 公告
// 保存公告
export const saveNoticeManagement = (params) =>
  http.post(`/noticeManagement/saveNoticeManagement`, params)
// 修改公告
export const updateNoticeManagement = (params) =>
  http.post(`/noticeManagement/updateNoticeManagement`, params)
// 删除公告
export const deleteNoticeManagement = (params) =>
  http.post(`/noticeManagement/deleteNoticeManagement`, params)
// 公告列表
export const queryNoticeManagementPageList = (params) =>
  http.post(`/noticeManagement/queryNoticeManagementPageList`, params)
// 我的公告列表
export const queryMyNoticeManagement = (params) =>
  http.post(`/noticeManagement/queryMyNoticeManagement`, params)
// 公告详情
export const queryNoticeManagementDetail = (params) =>
  http.post(`/noticeManagement/queryNoticeManagementDetail`, params)
// 推送公告
export const pushNotice = (params) =>
  http.post(`/noticeManagement/pushNotice`, params)
// 待办条数
export const myTaskCount = (params) => http.post(`/myItem/myTaskCount`, params)

// 事项
// 我的事项
export const myQuery = (params) =>
  http.post(`/myItem/myApprovalProcessQuery`, params)

// base64转oss
export const savePhoto = (params) => http.post(`/archive/savePhoto`, params)

// 招聘岗位详情
export const queryRecruitmentPositionDetail = (params) =>
  http.post(`/recruitmentManage/queryRecruitmentPositionDetail`, params)

// 我的消息
export const queryMyMessage = (params) =>
  http.post(`/myItem/queryMyMessage`, params)

// 工作进度列表
export const queryTaskProgressPageList = (params) =>
  http.post(`/dataBoard/queryTaskProgressPageList`, params)

// 修改员工照片
export const updateStaffPhoto = (params) =>
  http.post(`/archive/updateStaffPhoto`, params)

// 员工银行卡详情
export const queryStaffBankCardPageList = (params) =>
  http.post(`/archive/queryStaffBankCardPageList`, params)

// 获取实际申请天数和小时
export const getActualApplyDayAndHour = (params) =>
  http.post(`/employeeOaApplyManage/getActualApplyDayAndHour`, params)

// 删除班组
export const deleteTeamInfo = (params) =>
  http.post(`/basic/deleteTeamInfo`, params)

// 刷新考勤记录
export const refreshAttendanceRecord = (params) =>
  http.post(`/basic/refreshAttendanceRecord`, params)

// 修改介绍人奖金发放记录
export const updateReferralBonusIssueRecord = (params) =>
  http.post(`/archive/updateReferralBonusIssueRecord`, params)

// 批量修改介绍人奖金发放记录
export const batchUpdateReferralBonusIssueRecord = (params) =>
  http.post(`/archive/batchUpdateReferralBonusIssueRecord`, params)

// 修改密码
export const updatePwd = (params) => http.post(`/user/updatePwd`, params)

// 新增培训记录
export const saveDrillRecord = (params) =>
  http.post(`/archive/saveDrillRecord`, params)

// 修改培训记录
export const updateDrillRecord = (params) =>
  http.post(`/archive/updateDrillRecord`, params)

// 删除培训记录
export const deleteDrillRecord = (params) =>
  http.post(`/archive/deleteDrillRecord`, params)

// 培训记录员工列表
export const queryStaffDrillPageList = (params) =>
  http.post(`/archive/queryStaffDrillPageList`, params)

// 添加培训记录员工
export const batchSaveDrillStaff = (params) =>
  http.post(`/archive/batchSaveDrillStaff`, params)

// 保存员工附件
export const saveStaffFile = (params) =>
  http.post(`/archive/saveStaffFile`, params)

// 删除员工附件
export const deleteOaFile = (params) =>
  http.post(`/common/deleteOaFile`, params)

// 保存部门文档位置
export const saveDeptDocumentInfo = (params) =>
  http.post(`/archive/saveDeptDocumentInfo`, params)

// 删除部门文档位置
export const deleteDeptDocumentInfo = (params) =>
  http.post(`/archive/deleteDeptDocumentInfo`, params)

// 保存部门文档位置详情
export const saveDeptDocumentDetail = (params) =>
  http.post(`/archive/saveDeptDocumentDetail`, params)

// 删除部门文档位置详情
export const deleteDeptDocumentDetail = (params) =>
  http.post(`/archive/deleteDeptDocumentDetail`, params)

// 根据部门id获取部门下面空的文档位置
export const queryDeptDocumentDetailByDetail = (params) =>
  http.post(`/archive/queryDeptDocumentDetailByDetail`, params)

// 根据身份证号码获取员工保险信息
export const queryStaffInsuranceInfoByIdCard = (params) =>
  http.post(`/archive/queryStaffInsuranceInfoByIdCard`, params)

// 更新员工档案位置
export const updateDeptDocumentDetail = (params) =>
  http.post(`/archive/updateDeptDocumentDetail`, params)

// 修改档案位置
export const updateDeptDocumentInfo = (params) =>
  http.post(`/archive/updateDeptDocumentInfo`, params)

// 清空档案位置
export const emptyDeptDocumentDetail = (params) =>
  http.post(`/archive/emptyDeptDocumentDetail`, params)

// 获取员工pdf
export const createRetirementAgeContractPDF = (params) =>
  http.post(`/archive/createRetirementAgeContractPDF`, params)

// 删除临时入住
export const deleteDormitoryTemporaryCheckIn = (params) =>
  http.post(`/dormitory/deleteDormitoryTemporaryCheckIn`, params)

// 宿舍资产盘点列表
export const queryDormitoryAssetInventoryPageList = (params) =>
  http.post(`/dormitory/queryDormitoryAssetInventoryPageList`, params)

// 保存宿舍资产盘点
export const saveDormitoryAssetInventory = (params) =>
  http.post(`/dormitory/saveDormitoryAssetInventory`, params)

// 宿舍资产盘点详情
export const queryDormitoryAssetInventoryDetail = (params) =>
  http.post(`/dormitory/queryDormitoryAssetInventoryDetail`, params)

// 修改宿舍资产盘点
export const updateDormitoryAssetInventory = (params) =>
  http.post(`/dormitory/updateDormitoryAssetInventory`, params)

// 删除宿舍资产盘点
export const deleteDormitoryAssetInventory = (params) =>
  http.post(`/dormitory/deleteDormitoryAssetInventory`, params)

// 宿舍资产库存明细分页查询
export const queryDormitoryAssetInventoryDetailPageList = (params) =>
  http.post(`/dormitory/queryDormitoryAssetInventoryDetailPageList`, params)

// 查询宿舍资产库存明细详情
export const queryDormitoryAssetInventoryDetailDetail = (params) =>
  http.post(`/dormitory/queryDormitoryAssetInventoryDetailDetail`, params)

// 保存宿舍资产库存明细
export const saveDormitoryAssetInventoryDetail = (params) =>
  http.post(`/dormitory/saveDormitoryAssetInventoryDetail`, params)

// 修改宿舍资产库存明细
export const updateDormitoryAssetInventoryDetail = (params) =>
  http.post(`/dormitory/updateDormitoryAssetInventoryDetail`, params)

// 删除宿舍资产库存明细
export const deleteDormitoryAssetInventoryDetail = (params) =>
  http.post(`/dormitory/deleteDormitoryAssetInventoryDetail`, params)

// 上传员工离职单
export const uploadDepartPhoto = (params) =>
  http.post(`/personnelManagement/uploadDepartPhoto`, params)

// 上传员工转正单
export const uploadRegularizationPhoto = (params) =>
  http.post(`/personnelManagement/uploadRegularizationPhoto`, params)

// 上传员工调岗单
export const uploadJobTransferPhoto = (params) =>
  http.post(`/personnelManagement/uploadJobTransferPhoto`, params)

// 上传员工调薪单
export const uploadSalaryAdjustmentPhoto = (params) =>
  http.post(`/personnelManagement/uploadSalaryAdjustmentPhoto`, params)

// 同步员工档案到海康
export const pushStaffInfoToHk = (params) =>
  http.post(`/archive/pushStaffInfoToHk`, params)
