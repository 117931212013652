/** * 头部菜单 */
<template>
  <div>
    <!-- style="display: flex" -->
    <div
      class="el-menu-demo"
      mode="horizontal"
      text-color="#fff"
      active-text-color="#fff"
    >
      <div class="adminTitle">
        <div @click="changeNav" class="adminPic"></div>
        <div class="adminName">荣基OA系统</div>
      </div>
      <div class="adminIcons">
        <el-autocomplete
          width="160"
          class="inline-input"
          v-model="input"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="searchMenu"
          clearable
          v-if="inputShow"
        ></el-autocomplete>
        <div style="color: #fff; font-size: 16px">
          <span style="margin-right: 10px"
            >工号:{{ $store.state.user.loginInfo.code }}
          </span>
          <span style="margin-right: 10px"
            >姓名:{{ $store.state.user.loginInfo.username }}</span
          >
        </div>
        <!-- <i class="iconfont icon-wenhao"></i> -->
        <i
          class="iconfont icon-lingdang-xianxing"
          style="position: relative; cursor: pointer"
          @click="openDialogNotice()"
        >
          <span class="lingdang-xianxing-taskCount"></span>
        </i>
        <el-popover placement="bottom" title="" trigger="click">
          <div class="popover_btn" style="border-bottom: 1px solid #eeeeee">
            <el-button type="text" @click="openDialog">修改密码</el-button>
          </div>
          <div class="popover_btn">
            <el-button type="text" @click="exit">退出登录</el-button>
          </div>
          <i
            slot="reference"
            style="cursor: pointer"
            class="iconfont icon-caidan"
            @click="openMenu"
          ></i>
        </el-popover>
      </div>
    </div>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="ruleForm.oldPassword"
            minlength="6"
            maxlength="18"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="ruleForm.password" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmUpdatePwd" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="消息通知"
      :visible.sync="dialogVisibleNotice"
      width="90%"
      :before-close="handleCloseNotice"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-table
            :data="approvalTable"
            border
            max-height="600"
            style="width: 100%"
          >
            <el-table-column prop="taskRemark" label="任务描述" width="180">
            </el-table-column>
            <el-table-column prop="initiatorStaffName" label="发起人">
            </el-table-column>
            <el-table-column prop="initiatorStaffCode" label="发起人工号">
            </el-table-column>
            <el-table-column prop="sendStartTime" label="发起时间">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="triggerToApproval(scope.row)"
                  size="small"
                  type="primary"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <el-table
            :data="noticeTable"
            border
            max-height="600"
            style="width: 100%"
          >
            <el-table-column prop="theme" label="公告主题"> </el-table-column>
            <el-table-column prop="publisher" label="发布人"> </el-table-column>
            <el-table-column prop="publishDate" label="发布时间">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="handleNoticeDetails(scope.row)"
                  size="small"
                  type="primary"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryMyNoticeManagement,
  myQuery,
  approvalDetailsQuery,
  updatePwd,
} from './../api/ajax/index'
import { findRouter } from '../utils/util'
import { Dialog } from 'element-ui'
export default {
  name: 'navcon',
  data() {
    return {
      collapsed: true,
      imgshow: require('../assets/img/show.png'),
      imgsq: require('../assets/img/sq.png'),
      user: {},
      input: '',
      inputShow: false,
      restaurants: [],
      dialogVisibleNotice: false,
      approvalTable: [],
      noticeTable: [],
      dialogVisible: false,
      ruleForm: {
        oldPassword: '',
        password: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
      },
      isShow: false,
    }
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // 监听路由 更新tabs
    $route: {
      handler: function (val) {
        let data = findRouter(val.name, val.fullPath, true, this.allmenu)
        this.$emit('clickMenu', data)
      },
      // 深度观察监听
      deep: true,
    },
  },
  // 创建完毕状态(里面是操作)
  async created() {
    this.user = JSON.parse(localStorage.getItem('userdata'))
    let params = {
      userId: JSON.parse(localStorage.getItem('loginInfo')).id,
      parentNumber: '0',
    }
    const res = {
      data: this.menuList,
    }
    this.allmenu = res.data
    localStorage.setItem('menu', JSON.stringify(res.data))
    let url = this.$route.path.substring(1)
    let nowTab = {}
    this.findNowTab(this.allmenu, nowTab, url)
    this.$emit('clickMenu', nowTab)
    // 监听
    this.$root.Bus.$on('toggle', (value) => {
      this.collapsed = !value
    })
  },
  mounted() {
    this.restaurants = this.loadAll()
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    handleClose() {
      this.$refs.ruleForm.clearValidate()
      this.dialogVisible = false
    },
    confirmUpdatePwd() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const res = await updatePwd(this.ruleForm)
          if (res.code == 200) {
            this.$store.commit('user/logout', 'false')
            localStorage.removeItem('accessToken')
            this.$router.push({ path: '/login' })
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          }
        } else {
          return false
        }
      })
    },
    openDialogNotice() {
      this.queryMyNoticeManagement()
      this.myQuery()
      this.dialogVisibleNotice = true
    },
    handleCloseNotice() {
      this.dialogVisibleNotice = false
    },
    // 跳转审批详情
    async triggerToApproval(row) {
      const res = await approvalDetailsQuery({
        businessKey: row.businessKey,
      })
      this.handleCloseNotice()
      this.$router.push({
        path: row.menuPath,
        query: {
          type: 'view',
          businessKey: row.businessKey,
          id: JSON.parse(res.data.json).id,
        },
      })
    },
    // 跳转我的公告详情
    handleNoticeDetails(row) {
      this.handleCloseNotice()
      this.$router.push({
        path: '/myNotice/myNoticeDetails',
        query: {
          type: 'view',
          businessKey: row.businessKey,
          id: row.id,
        },
      })
    },
    // 我的公告
    async queryMyNoticeManagement() {
      const res = await queryMyNoticeManagement({
        readFlag: 0,
        // type: '5802',
      })
      this.noticeTable = res.data.list
    },
    // 我的待办事项
    async myQuery() {
      const res = await myQuery({ itemCode: 1 })
      this.approvalTable = res.data.list
    },
    // 我的公告
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) >=
            0 ||
          restaurant.namePinyinAbbr
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0 ||
          restaurant.namePinyinFull
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0
        )
      }
    },
    closeOther() {
      this.$emit('closeOther')
    },
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          setTimeout(() => {
            this.$store.commit('user/logout', 'false')
            localStorage.removeItem('accessToken')
            this.$router.push({ path: '/login' })
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          }, 1000)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 切换显示
    // toggle(showtype) {
    //   this.collapsed = !showtype
    //   this.$root.Bus.$emit('toggle', this.collapsed)
    // },
    openMenu() {},
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    searchMenu(item) {
      this.inputShow = false
      // this.$emit('clickMenu', item)
      this.$nextTick(() => {
        this.clickMenu(item)
        this.$router.push({ path: '' + item.url }, () => {})
        this.input = ''
      })
    },

    clickMenu(data) {
      this.$emit('searchMenu', data)
    },
    loadAll() {
      let list = []
      let fn = (arr) => {
        arr.forEach((item) => {
          if (item.children && item.children.length) {
            fn(item.children)
          } else {
            list.push(item)
          }
        })
      }
      fn(JSON.parse(localStorage.getItem('_menutile')))

      // map变成filter 判断是否showFlag展示显示
      this.restaurants = list.filter((item) => {
        if (item.showFlag) {
          item.value = item.text
          item.address = item.id
          return item
        }
      })
      this.list = this.restaurants

      return this.list
    },
    findNowTab(datas, nowTab, url) {
      datas.forEach((item) => {
        if (item.children) {
          return this.findNowTab(item.children, nowTab, url)
        } else {
          if (item.text == this.$route.name && item.url == `/${url}`) {
            nowTab.text = item.text
            nowTab.id = item.id
            nowTab.url = item.url
            return nowTab
          }
        }
      })
    },
    changeNav() {
      this.$store.commit('nav/changeNav')
    },
  },
}
</script>
<style scoped lang="scss">
.popover_btn {
  text-align: center;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}
.el-menu-demo {
  height: 60px;
  background-color: #2c68f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submenu {
  float: right;
  margin-right: 20px !important;
}

.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
  margin-left: 61px;
  padding: 0;
}

.showimg {
  width: 40px;
  height: 40px;
}

.showimg:active {
  border: none;
}
.adminTitle {
  display: flex;
  /* margin-top: 16px; */
  margin-left: 30px;
}
.adminName {
  width: 100px;
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  /* margin-bottom: 60px; */
}
.adminPic {
  width: 60px;
  height: 25px;
  background-image: url(../assets/img/logo1.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 8px;
  background-position: center center;
}
.adminIcons {
  display: flex;
  float: right;
  align-items: center;
  .iconfont {
    font-size: 24px;
    color: #ffffff;
    margin-left: 8px;
  }
  .icon-caidan {
    margin-right: 24px;
  }
}
.lingdang-xianxing-taskCount {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 8px;
  height: 8px;
  background-color: rgb(253, 62, 2);
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}
</style>
