<template>
  <div class="leftMenu">
    <WebSocket />
    <el-menu
      :collapse="collapsed"
      collapse-transition
      @open="openMenu"
      router
      unique-opened
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#fff"
      text-color="#000"
      active-text-color="#2C68F6"
    >
      <div class="search">
        <el-autocomplete
          class="inline-input"
          :class="showSearch ? '' : 'visible'"
          :style="{ opacity: showSearch ? 1 : 0 }"
          v-model="state2"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          :trigger-on-focus="false"
          @select="handleSelect"
          clearable
        ></el-autocomplete>
        <i
          @click="changeMenu"
          style="font-size: 25px"
          :style="{ 'margin-left': showSearch ? '10px' : '0px' }"
          :class="showSearch ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
        ></i>
      </div>
      <template v-for="menu in allmenu">
        <!-- 一级菜单 -->
        <el-menu-item
          :index="'' + menu.url"
          @click="clickMenu(menu)"
          :key="menu.id"
          v-if="!menu.children"
          v-show="menu.showFlag"
        >
          <i class="iconfont colorstyle" :class="menu.icon"></i>
          <span>{{ menu.text }}</span>
        </el-menu-item>
        <el-submenu :index="'' + menu.id" v-else v-show="menu.showFlag">
          <template slot="title">
            <div :class="menu.id == openMenuId ? 'selBg' : ''">
              <div v-if="menu.id == openMenuId" class="selItem"></div>
              <div>
                <i class="iconfont colorstyle" :class="menu.icon"></i>
                <span>{{ menu.text }}</span>
              </div>
            </div>
          </template>
          <!-- 二级菜单 -->
          <template v-for="chmenu in menu.children">
            <el-menu-item
              :index="'' + chmenu.url"
              @click="clickMenu(chmenu)"
              :key="chmenu.id"
              v-if="!chmenu.children"
              v-show="chmenu.showFlag"
            >
              <i class="iconfont colorstyle" :class="chmenu.icon"></i>
              <span>{{ chmenu.text }}</span>
              <span class="task_count" v-if="chmenu.id == '78'">{{
                $store.state.user.taskCount
              }}</span>
            </el-menu-item>
            <el-submenu :index="'' + chmenu.id" v-else v-show="chmenu.showFlag">
              <template slot="title">
                <i class="iconfont colorstyle" :class="chmenu.icon"></i>
                <span>{{ chmenu.text }}</span>
              </template>
              <!-- 三级菜单 -->
              <template v-for="chmenuo in chmenu.children">
                <el-menu-item
                  :index="'' + chmenuo.url"
                  @click="clickMenu(chmenuo)"
                  :key="chmenuo.id"
                  v-show="chmenuo.showFlag"
                  v-if="!chmenuo.children"
                >
                  <i class="iconfont colorstyle" :class="chmenuo.icon"></i>
                  <span>{{ chmenuo.text }}</span>
                </el-menu-item>
                <!-- 有四级菜单 -->
                <el-submenu
                  :index="chmenuo.text"
                  v-show="chmenuo.showFlag"
                  v-else
                >
                  <template slot="title">
                    <i class="iconfont colorstyle" :class="chmenuo.icon"></i>
                    <span>{{ chmenuo.text }}</span>
                  </template>
                  <template v-for="chmenuoi in chmenuo.children">
                    <el-menu-item
                      :index="'' + chmenuoi.url"
                      @click="clickMenu(chmenuoi)"
                      :key="chmenuoi.id"
                      v-show="chmenuoi.showFlag"
                      v-if="!chmenuoi.children"
                    >
                      <i class="iconfont colorstyle" :class="chmenuoi.icon"></i>
                      <span>{{ chmenuoi.text }}</span>
                    </el-menu-item>
                    <!--五级菜单-->
                    <el-submenu
                      :index="chmenuoi.text"
                      v-show="chmenuoi.showFlag"
                      v-else
                    >
                      <template slot="title">
                        <i class="iconfont" :class="chmenuoi.icon"></i>
                        <span>{{ chmenuoi.text }}</span>
                      </template>
                      <el-menu-item
                        v-for="chilmenu in chmenuoi.children"
                        :index="'' + chilmenu.url"
                        v-show="chilmenu.showFlag"
                        :key="chilmenu.id"
                        @click="clickMenu(chilmenu)"
                      >
                        <i
                          class="iconfont colorstyle"
                          :class="chilmenu.icon"
                        ></i>
                        <span>{{ chilmenu.text }}</span>
                      </el-menu-item>
                    </el-submenu>
                  </template>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>
<script>
import { array } from 'js-md5'
import { findRouter } from '../utils/util'
import WebSocket from '@/components/websocket/websocket.vue'
export default {
  name: 'leftnav',
  components: { WebSocket },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    // asideshow: { type: String, default: () => 'asideshow' },
  },
  data() {
    return {
      collapsed: false,
      showSearch: true,
      allmenu: [],
      restaurants: [],
      state1: '',
      state2: '',
      openMenuId: '',
    }
  },
  async created() {
    // OA字典获取code全部
    this.$store.dispatch('wdstore/getCode')

    // -----
    let url = this.$route.path.substring(1)
    let nowTab = {}
    this.findNowTab(this.allmenu, nowTab, url)
    // 监听
    this.$root.Bus.$on('toggle', (value) => {
      this.collapsed = !value
    })
    // var resize = document.getElementsByClassName('leftMenu')
  },
  methods: {
    changeMenu() {
      let that = this
      that.showSearch = !that.showSearch
      this.$store.commit('nav/changeNav')
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) >=
            0 ||
          restaurant.namePinyinAbbr
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0 ||
          restaurant.namePinyinFull
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0
        )
      }
    },
    loadAll() {
      let list = []
      let fn = (arr) => {
        arr.forEach((item) => {
          if (item.children && item.children.length) {
            fn(item.children)
          } else {
            list.push(item)
          }
        })
      }
      let dataList = JSON.parse(localStorage.getItem('_menutile'))
      fn(dataList || [])

      // map变成filter 判断是否showFlag展示显示
      this.restaurants = list.filter((item) => {
        if (item.showFlag) {
          item.value = item.text
          item.address = item.id
          return item
        }
      })
      this.list = this.restaurants

      return this.list
    },
    handleSelect(item) {
      this.$emit('clickMenu', item)
      this.$nextTick(() => {
        this.clickMenu(item)
        this.$router.push({ path: '' + item.url }, () => {})
      })
    },
    // 点击菜单
    clickMenu(data) {
      this.$emit('clickMenu', data)
    },
    findNowTab(datas, nowTab, url) {
      if (!datas || !datas.length) {
        return
      }
      datas.forEach((item) => {
        if (item.children) {
          return this.findNowTab(item.children, nowTab, url)
        } else {
          if (item.text == this.$route.name && item.url == `/${url}`) {
            nowTab.text = item.text
            nowTab.id = item.id
            nowTab.url = item.url
            return nowTab
          }
        }
      })
    },
    openMenu(id) {
      this.openMenuId = id
    },
  },
  watch: {
    menuList: {
      handler(val) {
        this.allmenu = val || []
      },
      deep: true,
    },
    // 监听路由 更新tabs
    $route: {
      handler: function (val) {
        let data = findRouter(val.name, val.fullPath, true, this.allmenu)
        // this.$emit('clickMenu', data) // 导致菜单权限每次请求两次
      },
      // 深度观察监听
      deep: true,
    },
    '$store.state.nav.isOpenNav': {
      handler(val) {
        this.collapsed = val
      },
    },
  },
  computed: {},
  mounted() {
    this.restaurants = this.loadAll()
  },
}
</script>
<style lang="scss">
.task_count {
  display: inline-block;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  color: red;
  margin-left: 5px;
}
.leftMenu {
  margin-top: 10px;
  height: 98%;

  .search {
    // width: 100%;
    // padding: 0 20px;
    // text-align: center;
    margin-left: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.el-menu-vertical-demo {
  height: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  // width: 290px;
  min-height: 400px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

.el-menu-item-group__title {
  padding: 0px;
}

.el-menu-bg {
  background-color: #fff !important;
}

.el-menu {
  border: none;
}

.logobox {
  height: 40px;
  line-height: 40px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}

.logoimg {
  height: 40px;
}
::v-deep .el-submenu__title {
  color: #fff;
}
.el-submenu :hover {
  background-color: #dfe3ff !important;

  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 15px;
  color: #2c68f6 !important;
  font-style: normal;
  text-transform: none;
  i {
    color: #2c68f6 !important;
  }
}
.el-submenu {
  position: relative;
}
// ::v-deep .el-menu-item {
//   .is-active {
//     .iconfont {
//       color: pink !important;
//       margin-right: 3px;
//     }
//   }
// }

// .iconfont {
//   color: #fff !important;
//   margin-right: 3px;
// }
.colorstyle {
  margin-right: 15px;
  margin-left: 20px;
}
.el-submenu__title {
  // padding: 0 70px;
  color: #252525;

  // font-weight: 500;
  .iconfont {
    color: #252525;
  }
  i {
    margin-right: 12px;
  }
}

.el-menu--inline {
  color: #252525;
  // font-weight: 500;
  .iconfont {
    margin-right: 15px;
    margin-left: 20px;
    color: #252525;
  }
}
// .is-active {
//   .colorstyle {
//     color: pink !important;
//     margin-right: 3px;
//   }
// }
.selItem {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20;
  background-color: #2c68f6;
  width: 4px;
  float: left;
}
.selBg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #dfe3ff !important;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 15px;
  color: #2c68f6 !important;
  font-style: normal;
  text-transform: none;
  i {
    color: #2c68f6 !important;
  }
}
.inline-input {
  transition: all 1s ease-in-out;
}

.visible {
  // width: 0px;
  overflow: hidden;
}
</style>
