import axios from 'axios'
export const downloadFile = (params, filename) => {
  let url = process.env.VUE_APP_BASEURL + '/CGP-OA/common/exportData' //后端的接口
  let param = {
    request: JSON.stringify(params),
    exportName: filename,
  }
  // axios.headers = {
  //   'Content-Type': 'application/json; charset=utf-8',
  // }
  axios
    .post(url, param, {
      responseType: 'blob',
      headers: {
        'X-Access-Token': window.localStorage.getItem('accessToken'),
      },
    })
    .then((res) => {
      if (res.status == 200) {
        filename = filename + '.xlsx'
        let blob = new Blob([res.data], {
          type: res.headers['content-type'],
        })
        let url = window.URL.createObjectURL(blob)
        if (window.navigator.msSaveBlob) {
          //IE
          try {
            window.navigator.msSaveBlob(blob, filename)
          } catch (e) {
            console.log(e)
          }
        } else {
          //非IE
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        }
        URL.revokeObjectURL(url) // 释放内存
      }
    })
}
