<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'recruitApply',
  data() {
    return {
      cacheList: [],
    }
  },
  mounted() {},
  computed: {},
}
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
.el-input.is-disabled .el-input__inner {
  color: #606266;
}
.el-textarea.is-disabled .el-textarea__inner {
  color: #606266;
}
</style>
