<template>
  <div
    class="baseDataList"
    @contextmenu="handleContextmenu"
    v-loading="options.tableLoading"
  >
    <el-button
      type="primary"
      icon="el-icon-download"
      size="mini"
      :disabled="
        exportFlag &&
        btnType.indexOf('6005') !== -1 &&
        options.dataList.length > 0
          ? false
          : true
      "
      @click="exportData()"
      class="export_btn"
      :style="{ left: leftPx }"
    >
      导出数据
    </el-button>
    <vxe-toolbar
      class-name="vxe_toolbar_box"
      ref="xToolbar1"
      custom
    ></vxe-toolbar>
    <vxe-table
      class="mytable-scrollbar"
      :row-style="rowStyle"
      :custom-config="customConfig"
      :keyboard-config="{ isArrow: false }"
      :id="$route.path"
      ref="xTable1"
      border
      :min-height="diaHeight || options.minHeight"
      :height="options.height"
      show-overflow
      :data="options.dataList"
      :column-config="{ resizable: true }"
      :scroll-y="{ enabled: true }"
      align="center"
      :checkbox-config="{
        checkStrictly: true,
      }"
      :row-config="{
        keyField: 'id',
        isCurrent: true,
      }"
      :radio-config="{}"
      :tree-config="
        isTree
          ? {
              transform: true,
              rowField: 'id',
              parentField: 'parentCode',
              reserve: true,
              expandAll: true,
            }
          : {}
      "
      :menu-config="menu >= 0 ? options.tableMenu : {}"
      @menu-click="contextMenuClickEvent"
      @checkbox-change="selectChangeEvent"
      :edit-config="{ trigger: ['dblclick', 'click'], mode: 'cell' }"
      :header-cell-style="headerStyle"
      @radio-change="radioChangeEvent"
      @cell-click="cellClick"
      @cell-dblclick="cellDblclick"
      :span-method="mergeRowMethod"
      :sort-config="{ multiple: true, trigger: 'cell' }"
    >
      >
      <vxe-column
        type="seq"
        title="序号"
        width="60"
        sortable
        align="center"
        :fixed="'left'"
        :edit-render="{ autofocus: '.vxe-input--inner' }"
      >
        <template v-slot="scope">
          <div
            v-if="scope._rowIndex == 0"
            style="display: flex; justify-content: center; align-items: center"
          >
            <el-button style="height: 100%" @click="getTableList"
              >查询</el-button
            >
          </div>
          <div v-else>{{ scope._rowIndex }}</div>
        </template>
      </vxe-column>
      <vxe-column
        v-for="config in tableHeader"
        :key="config.key"
        :type="config.type"
        :field="config.field"
        :title="config.title"
        :width="config.width || ''"
        :show-overflow="config.title != '操作'"
        min-width="90px"
        :sortable="
          config.title.indexOf('日期') >= 0 || config.title.indexOf('时间') >= 0
            ? true
            : false
        "
        :fixed="config.fixedTable"
      >
        <template #default="{ column, row, rowIndex, _rowIndex }">
          <template
            v-if="
              column.title !== '操作' &&
              _rowIndex === 0 &&
              column.type !== 'checkbox' &&
              column.type !== 'radio' &&
              !noSearch
            "
          >
            <template v-if="!config.slotSearch">
              <template v-if="config.title.indexOf('审批结果') >= 0">
                <el-select
                  v-model="inputFrom[config.field]"
                  size="mini"
                  clearable
                  placeholder=""
                  @change="getTableList"
                >
                  <el-option label="通过" :value="1"></el-option>
                  <el-option label="驳回" :value="0"></el-option>
                </el-select>
              </template>
              <template
                v-if="
                  config.title.indexOf('是否') >= 0 &&
                  config.title != '是否购买五险'
                "
              >
                <el-select
                  v-model="inputFrom[config.field]"
                  size="mini"
                  clearable
                  placeholder=""
                  @change="getTableList"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </template>
              <template v-if="config.title == '是否购买五险'">
                <el-select
                  v-model="inputFrom[config.field]"
                  size="mini"
                  clearable
                  placeholder=""
                  @change="getTableList"
                >
                  <el-option label="是" value="1"></el-option>
                </el-select>
              </template>
              <template v-else-if="config.typeTime">
                <template v-if="config.typeTime == 'time'">
                  <el-time-picker
                    size="small"
                    v-model="inputFrom[config.field]"
                    placeholder=""
                    value-format="HH:mm:ss"
                    @change="getTableList"
                  >
                  </el-time-picker>
                </template>
                <template v-else-if="config.typeTime == 'dates'">
                  <el-date-picker
                    v-model="dateList[config.field]"
                    size="small"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="~"
                    start-placeholder=""
                    end-placeholder=""
                    @change="datesChange($event, config.field)"
                  >
                  </el-date-picker>
                </template>
                <template v-else-if="config.typeTime == 'datesSend'">
                  <el-date-picker
                    v-model="dateList[config.field]"
                    size="small"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="~"
                    start-placeholder=""
                    end-placeholder=""
                    @change="datesChangeSend($event, config.field)"
                  >
                  </el-date-picker>
                </template>
                <template v-else-if="config.typeTime == 'datem'">
                  <el-date-picker
                    v-model="inputFrom[config.field]"
                    type="month"
                    value-format="yyyy-MM"
                    placeholder=""
                    @change="getTableList"
                  >
                  </el-date-picker>
                </template>
                <template v-else>
                  <el-date-picker
                    v-model="inputFrom[config.field]"
                    type="date"
                    :value-format="'yyyy-MM-dd'"
                    clearable
                    @change="getTableList"
                    @clear="getTableList"
                  >
                  </el-date-picker>
                </template>
              </template>
              <template v-else-if="config.title == '统计日期'">
                <el-date-picker
                  v-model="useTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '统计日期')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '考勤日期'">
                <el-date-picker
                  v-model="useTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '考勤日期')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '试用到期时间'">
                <el-date-picker
                  v-model="useTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '试用到期时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '用车时间'">
                <el-date-picker
                  v-model="useTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '用车时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '客人到达时间'">
                <el-date-picker
                  v-model="guestArrivalTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd hh:mm"
                  value-format="yyyy-MM-dd hh:mm"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '申请时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '委外日期'">
                <el-date-picker
                  v-model="useTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '委外日期')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '设备返厂时间'">
                <el-date-picker
                  v-model="useTime"
                  size="small"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '设备返厂时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '申请时间'">
                <el-date-picker
                  v-model="applyDate"
                  size="small"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '申请时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '创建时间'">
                <el-date-picker
                  v-model="createDate"
                  size="small"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '创建时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '修改时间'">
                <el-date-picker
                  v-model="updateDate"
                  size="small"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder=""
                  end-placeholder=""
                  @change="getDate($event, '修改时间')"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '开始时间'">
                <el-date-picker
                  v-model="inputFrom.startTime"
                  type="date"
                  :value-format="'yyyy-MM-dd'"
                  clearable
                  @change="getTableList"
                  @clear="getTableList"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '结束时间'">
                <el-date-picker
                  v-model="inputFrom.endTime"
                  type="date"
                  :value-format="'yyyy-MM-dd'"
                  clearable
                  @change="getTableList"
                  @clear="getTableList"
                >
                </el-date-picker>
              </template>
              <template v-else-if="config.title == '状态'">
                <el-select
                  v-model="inputFrom[config.field]"
                  size="mini"
                  clearable
                  placeholder=""
                  @change="getTableList"
                >
                  <el-option
                    v-for="(item, index) in recruitmentMethodList"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="config.title == '节假日配置'">
                <MultipleSelect
                  v-show="holidayConfigMainList.length && !config.disabled"
                  :selectOptions="holidayConfigMainList"
                  :deployObj="{
                    label: 'title',
                    value: 'id',
                  }"
                  field="holidayConfigMainIds"
                  :arr="inputFrom.holidayConfigMainIds || []"
                  @visibleChange="
                    visibleChangeCode($event, 'holidayConfigMainIds')
                  "
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '上级部门'">
                <MultipleSelect
                  v-show="parentDeptList.length && !config.disabled"
                  :selectOptions="parentDeptList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  field="parentDeptIds"
                  :arr="inputFrom.parentDeptIds || []"
                  @visibleChange="visibleChangeCode($event, 'parentDeptIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '岗位上级部门'">
                <MultipleSelect
                  v-show="parentDeptListPositions.length && !config.disabled"
                  :selectOptions="parentDeptListPositions"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  field="parentDeptIds"
                  :arr="inputFrom.parentDeptIds || []"
                  @visibleChange="visibleChangeCode($event, 'parentDeptIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '使用者部门'">
                <MultipleSelect
                  v-show="DeptList.length && !config.disabled"
                  :selectOptions="DeptList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  field="userDeptId"
                  :arr="inputFrom.deptIds || []"
                  @visibleChange="visibleChangeCode($event, 'userDeptId')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '档案位置部门'">
                <MultipleSelect
                  v-show="DeptList.length && !config.disabled"
                  :selectOptions="DeptList"
                  :deployObj="{
                    label: 'nameUrl',
                    value: 'id',
                  }"
                  field="deptIds"
                  :arr="inputFrom.deptIds || []"
                  @visibleChange="visibleChangeCode($event, 'deptIds')"
                ></MultipleSelect>
              </template>
              <template
                v-else-if="
                  config.title.indexOf('部门') >= 0 &&
                  config.title != '使用者部门' &&
                  config.title != '上级部门' &&
                  config.title != '调出部门' &&
                  config.title != '档案位置部门'
                "
              >
                <MultipleSelect
                  v-show="DeptList.length && !config.disabled"
                  :selectOptions="DeptList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  field="deptIds"
                  :arr="inputFrom.deptIds || []"
                  @visibleChange="visibleChangeCode($event, 'deptIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title.indexOf('事业部') >= 0">
                <MultipleSelect
                  v-show="businessUnitList.length"
                  :selectOptions="businessUnitList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  :arr="inputFrom.businessUnitIds || []"
                  @visibleChange="visibleChangeCode($event, 'businessUnitIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '车间'">
                <MultipleSelect
                  v-show="workshopIdsList.length"
                  :selectOptions="workshopIdsList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  :arr="inputFrom.workshopIds || []"
                  @visibleChange="visibleChangeCode($event, 'workshopIds')"
                ></MultipleSelect>
              </template>
              <template
                v-else-if="
                  config.title.indexOf('班组') >= 0 &&
                  config.title != '调出班组' &&
                  config.title != '车间班组'
                "
              >
                <MultipleSelect
                  v-show="teamIdsList.length"
                  :selectOptions="teamIdsList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  :arr="inputFrom.teamIds || []"
                  @visibleChange="visibleChangeCode($event, 'teamIds')"
                ></MultipleSelect>
              </template>
              <template
                v-else-if="
                  config.title.indexOf('岗位') >= 0 &&
                  config.title != '岗位来源单号' &&
                  config.title != '调出岗位'
                "
              >
                <MultipleSelect
                  v-show="positionList.length"
                  :selectOptions="positionList"
                  :deployObj="{
                    label: 'positionAbr',
                    value: 'id',
                  }"
                  :arr="inputFrom.positionIds || []"
                  @visibleChange="visibleChangeCode($event, 'positionIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '调出部门'">
                <MultipleSelect
                  v-show="orgDeptIdsList.length && !config.disabled"
                  :selectOptions="orgDeptIdsList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  field="orgDeptIds"
                  :arr="inputFrom.orgDeptIds || []"
                  @visibleChange="visibleChangeOrgCode($event, 'orgDeptIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '调出班组'">
                <MultipleSelect
                  v-show="orgTeamIdsList.length"
                  :selectOptions="orgTeamIdsList"
                  :deployObj="{
                    label: 'deptName',
                    value: 'id',
                  }"
                  :arr="inputFrom.orgTeamIds || []"
                  @visibleChange="visibleChangeOrgCode($event, 'orgTeamIds')"
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '调出岗位'">
                <MultipleSelect
                  v-show="orgPositionIdsList.length"
                  :selectOptions="orgPositionIdsList"
                  :deployObj="{
                    label: 'positionAbr',
                    value: 'id',
                  }"
                  :arr="inputFrom.orgPositionIds || []"
                  @visibleChange="
                    visibleChangeOrgCode($event, 'orgPositionIds')
                  "
                ></MultipleSelect>
              </template>
              <template v-else-if="config.title == '班次'">
                <MultipleSelect
                  v-show="classesList.length"
                  :selectOptions="classesList"
                  :deployObj="{
                    label: 'name',
                    value: 'id',
                  }"
                  :arr="inputFrom.classesIds || []"
                  @visibleChange="visibleChangeCode($event, 'classesIds')"
                ></MultipleSelect>
              </template>
              <template v-else>
                <template v-if="config.typeCode">
                  <template v-if="config.field.indexOf('Name') != -1">
                    <el-select
                      v-model="
                        inputFrom[
                          `${config.field.slice(0, config.field.length - 4)}`
                        ]
                      "
                      size="mini"
                      clearable
                      placeholder=""
                      @change="getTableList"
                    >
                      <el-option
                        v-for="item in codeData.find(
                          (ele) => ele.code == config.typeCode
                        ).children"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                  <template v-if="config.field.indexOf('Value') != -1">
                    <el-select
                      v-model="
                        inputFrom[
                          `${config.field.slice(0, config.field.length - 5)}`
                        ]
                      "
                      size="mini"
                      clearable
                      placeholder=""
                      @change="getTableList"
                    >
                      <el-option
                        v-for="item in codeData.find(
                          (ele) => ele.code == config.typeCode
                        ).children"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                  <template v-else>
                    <el-select
                      v-model="inputFrom[config.field]"
                      size="mini"
                      clearable
                      placeholder=""
                      @change="getTableList"
                    >
                      <el-option
                        v-for="item in codeData.find(
                          (ele) => ele.code == config.typeCode
                        ).children"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </template>
                <template v-else-if="config.typeCodes">
                  <template v-if="config.field.indexOf('Name') != -1">
                    <MultipleSelect
                      :selectOptions="
                        codeData.find((ele) => ele.code == config.typeCodes)
                          .children
                      "
                      :deployObj="deployCode"
                      :arr="
                        inputFrom[
                          `${config.field.slice(0, config.field.length - 4)}`
                        ] || []
                      "
                      @visibleChange="
                        visibleChangeCode(
                          $event,
                          `${config.field.slice(0, config.field.length - 4)}`
                        )
                      "
                    ></MultipleSelect>
                  </template>
                  <template v-else>
                    <MultipleSelect
                      :selectOptions="
                        codeData.find((ele) => ele.code == config.typeCodes)
                          .children
                      "
                      :deployObj="deployCode"
                      :arr="inputFrom[`${config.field}`] || []"
                      @visibleChange="visibleChangeCode($event, config.field)"
                    ></MultipleSelect>
                  </template>
                </template>
                <template v-else-if="config.number">
                  <el-input
                    clearable
                    @keyup.enter.native="getTableList"
                    v-model="inputFrom[column.field]"
                    @clear="getTableList"
                    @blur="
                      () => {
                        getTableList()
                      }
                    "
                    oninput="value=value.replace(/[^\d.]/g,'')"
                  ></el-input>
                </template>
                <template v-else>
                  <el-input
                    clearable
                    @keyup.enter.native="getTableList"
                    v-model="inputFrom[column.field]"
                    @clear="getTableList"
                    @blur="
                      () => {
                        getTableList()
                      }
                    "
                  ></el-input>
                </template>
              </template>
            </template>
            <template v-else>
              <slot :name="config.slotSearch" :item="row"></slot>
            </template>
          </template>
          <template v-else>
            <template v-if="column.title === '操作' && _rowIndex !== 0">
              <slot :name="config.slotName" :item="row"></slot>
            </template>
            <template v-else-if="column.title === '操作' && _rowIndex === 0">
              <el-button
                status="primary"
                type="primary"
                size="mini"
                @click="clear(column, row, rowIndex)"
                >重置</el-button
              >
            </template>
            <template v-else>
              <template v-if="config.slotName">
                <slot :name="config.slotName" :item="row"></slot>
              </template>
              <template v-else>
                {{ row[column.field] }}
              </template>
            </template>
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager
      style="display: block; margin-top: 20px"
      v-if="isPager"
      :loading="loading1"
      :current-page="tablePage1.currentPage"
      :page-size="tablePage1.pageSize"
      :total="tablePage1.totalResult"
      :layouts="[
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Sizes',
        'Total',
      ]"
      :page-sizes="[20, 50, 100]"
      @page-change="handlePageChange1"
    >
    </vxe-pager>
  </div>
</template>

<script>
import http from '@/api/ajax/aixos'
import { downloadFile } from '@/utils/exportData.js'
import {
  getDeptDropdown,
  queryTeamPageList,
  queryAllShfitManage,
  getAllPositionByDeptId,
  queryCommunicationOrderDropdown,
} from '@/api/ajax/index'
import { queryHolidayMainPageList, getUserMenuBtn } from '@/api/ajax/request'
import { number } from 'echarts'
export default {
  name: 'baseDataList',
  props: {
    leftPx: {
      type: String,
      default: '85px',
    },
    tableHeader: {
      type: Array,
      require: true,
    },
    noSearch: {
      type: Boolean,
      require: false,
    },
    exportFlag: {
      type: Boolean,
      require: false,
    },
    // dataList: {
    //   type: Array,
    //   require: true,
    // },
    //isTree控制是否是树型结构
    isTree: {
      type: Boolean,
      default: false,
    },
    //是否展示分页
    isPager: {
      type: Boolean,
      default: false,
    },
    //是否展示操作
    // isOperate: {
    //   type: Boolean,
    //   default: false,
    // },
    url: {
      type: String,
      require: true,
    },
    searchForm: {
      type: Object,
      default: {},
    },
    mergeRow: {
      type: String,
      default: '',
    },
    diaHeight: {
      type: String,
      default: '',
    },
    isColor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      customConfig: {
        storage: true,
        checkMethod: ({ column }) => {
          if (column.title === '序号' || column.title === '操作') {
            return false
          } else {
            return true
          }
        },
      },
      dateList: {},
      orgDeptIdsList: [],
      orgTeamIdsList: [],
      orgPositionIdsList: [],
      workshopIdsList: [],
      deployCode: {
        label: 'name',
        value: 'code',
      },
      isSearchFlag: true,
      copyText: '',
      headerStyle: {
        // background: 'rgb(238,241,246)',
        height: '20px',
        color: '#606266',
        padding: '6px 0',
        // lineHeight: '22px',
      },
      recruitmentMethodList: [],
      allAlign: null,
      options: {
        minHeight: ' ',
        height: ' ',
        maxHeight: ' ',
        //初始化菜单
        tableMenu: {
          // className: 'my-menus',
          body: {
            options: [
              [
                {
                  code: 'rest',
                  name: '刷新',
                },
                // {
                //   code: 'edit',
                //   name: '编辑',
                // },
                // {
                //   code: 'del',
                //   name: '删除',
                // },
                {
                  code: 'copy',
                  name: '复制',
                },
                {
                  code: 'paste',
                  name: '粘贴',
                },
              ],
            ],
          },
        },
        tableLoading: false, //整个组件loading
        dataList: [], //list接口数据
        // 默认树形标识是code 和parentCode 更改的话在父组件传递 和url平级
        treeConfig: {
          rowField: 'code',
          parentField: 'parentCode',
        },
        url: '', // 请求接口
      },

      loading1: false,
      tablePage1: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
      },
      inputFrom: {},
      DeptList: [],
      businessUnitList: [],
      teamIdsList: [],
      classesList: [],
      positionList: [],
      useTime: [],
      guestArrivalTime: [],
      applyDate: [], // 申请时间
      createDate: [], // 创建时间
      updateDate: [], // 修改时间
      parentDeptList: [],
      parentDeptListPositions: [],
      holidayConfigMainList: [],
    }
  },
  watch: {
    searchForm: {
      handler(val, old) {
        // item1为新值，item2为旧值
        this.getTableList()
      },
      deep: true,
    },
  },
  computed: {
    menu() {
      let flag = this.tableHeader.findIndex((ele) => ele.title == '操作')
      return flag
    },
    btnType() {
      return this.$store.state.wdstore.btnManage.btnStr
    },
    codeData() {
      return this.$store.state.wdstore.code
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTableList()
      this.$refs.xTable1.connect(this.$refs.xToolbar1)
    })
  },
  activated() {
    this.getUserMenuBtn()
  },
  created() {
    this.__calculateHeight()
    let findDept = this.tableHeader.find(
      (ele) => ele.title.indexOf('部门') >= 0
    )
    let shiye = this.tableHeader.find((ele) => ele.title.indexOf('事业部') >= 0)
    let banzu = this.tableHeader.find((ele) => ele.title == '班组名称')
    let className = this.tableHeader.find((ele) => ele.title == '班次')
    let positionName = this.tableHeader.find((ele) => ele.title == '岗位')
    if (findDept) {
      this.getDeptDropdown({ level: '2,3' })
    }
    if (shiye) {
      this.getDeptDropdown({ level: '2' })
    }
    // if (banzu) {
    //   this.queryTeamPageList()
    // }
    if (className) {
      this.queryAllShfitManage()
    }
    if (this.tableHeader.find((ele) => ele.title == '通知单号')) {
      this.queryCommunicationOrderDropdown()
    }
    if (this.tableHeader.find((ele) => ele.title == '上级部门')) {
      this.getParentDeptList()
    }
    if (this.tableHeader.find((ele) => ele.title == '岗位上级部门')) {
      this.getParentDeptListPositions()
    }
    if (this.tableHeader.find((ele) => ele.title == '调出部门')) {
      this.getOrgDeptIds()
    }
    if (this.tableHeader.find((ele) => ele.title == '档案位置部门')) {
      this.getDeptDropdown({ level: '2,3' })
    }
    if (this.tableHeader.find((ele) => ele.title == '节假日配置')) {
      this.getholidayMainList()
    }
  },
  methods: {
    // 获取按钮权限
    async getUserMenuBtn() {
      let path = ''
      if (this.$route.fullPath.indexOf('?') > 0) {
        path = this.$route.fullPath.split('?')[0]
      } else {
        path = this.$route.fullPath
      }
      const res = await getUserMenuBtn({
        path: path,
      })
      if (res.code == 200) {
        let btnData = {
          btnStr: res.data.btnStr || '',
          menuId: res.data.menuId || '',
        }
        localStorage.setItem('btnManage', JSON.stringify(btnData))
        this.$store.commit('wdstore/setBtnManage', btnData)
      }
    },
    //导出模板
    exportData() {
      let params = {
        page: this.tablePage1.currentPage,
        limit: this.tablePage1.pageSize,
        ...this.inputFrom,
        ...this.searchForm,
      }
      console.log(params)
      downloadFile(params, this.$route.meta.title)
    },
    visibleChangeCode(e, field) {
      this.inputFrom[field] = e
      if (field == 'deptIds') {
        if (e.length == 0) {
          this.inputFrom.positionIds = []
          this.positionList = []
          this.inputFrom.teamIds = []
          this.teamIdsList = []
        } else if (e.length == 1) {
          let flag = false
          this.tableHeader.map((item) => {
            if (item.title.indexOf('班组') >= 0) {
              console.log(3333)
              flag = true
            }
          })
          if (flag) {
            this.getTeamIds()
          }
          this.getAllPositionByDeptId(1)
        }
      } else if (field == 'teamIds') {
        if (e.length == 0) {
          this.inputFrom.positionIds = []
          this.positionList = []
        } else {
          let flag = false
          this.tableHeader.map((item) => {
            if (item.title.indexOf('部门') >= 0) {
              flag = true
            }
          })
          if (flag) {
            this.getAllPositionByDeptId(1)
          }
        }
      }
      if (field == 'parentDeptId') {
        if (e.length != 1) {
          this.DeptList = []
        } else {
          this.getDeptDropdown({ parentId: e[0] })
        }
      }
      if (field == 'businessUnitIds') {
        if (e.length != 1) {
          this.workshopIds = []
          this.workshopIdsList = []
        } else {
          this.getDeptDropdownWord({ parentId: e[0] })
        }
      }
      if (field == 'workshopIds') {
        if (e.length != 1) {
          this.inputFrom.teamIds = []
          this.teamIdsList = []
        } else {
          this.getDeptDropdownTeam({ parentId: e[0] })
        }
      }
      this.getTableList()
    },
    visibleChangeOrgCode(e, field) {
      this.inputFrom[field] = e
      if (field == 'orgDeptIds') {
        if (e.length == 0) {
          this.inputFrom.orgTeamIds = []
          this.orgTeamIdsList = []
          this.inputFrom.orgPositionIds = []
          this.orgPositionIdsList = []
        } else {
          this.getOrgTeamIds()
        }
      } else if (field == 'orgTeamIds') {
        if (e.length == 0) {
          this.inputFrom.orgPositionIds = []
          this.orgPositionIdsList = []
        } else {
          this.getOrgPositionIdsList()
        }
      }
      this.getTableList()
    },
    datesChangeSend(e, text) {
      if (!e) {
        if (text == 'sendStartTime') {
          this.inputFrom.sendStartTime = ''
          this.inputFrom.sendEndTime = ''
        } else if (text == 'dealTime') {
          this.inputFrom.dealStartTime = ''
          this.inputFrom.dealEndTime = ''
        }
      } else {
        if (text == 'sendStartTime') {
          this.inputFrom.sendStartTime = e[0]
          this.inputFrom.sendEndTime = e[1]
        } else if (text == 'dealTime') {
          this.inputFrom.dealStartTime = e[0]
          this.inputFrom.dealEndTime = e[1]
        }
      }
      this.getTableList()
    },
    datesChange(e, text) {
      let length = text.length
      let start =
        text.substring(0, length - 4) + 'Start' + text.substring(length - 4)
      let end =
        text.substring(0, length - 4) + 'End' + text.substring(length - 4)
      if (!e) {
        this.inputFrom[start] = ''
        this.inputFrom[end] = ''
      } else {
        this.inputFrom[start] = e[0]
        this.inputFrom[end] = e[1]
      }
      this.getTableList()
    },
    getDate(e, text) {
      switch (text) {
        case '创建时间':
          this.inputFrom.createStartTime = !e ? '' : e[0]
          this.inputFrom.createEndTime = !e ? '' : e[1]
          break
        case '修改时间':
          this.inputFrom.updateStartTime = !e ? '' : e[0]
          this.inputFrom.updateEndTime = !e ? '' : e[1]
          break
        case '申请时间':
          this.inputFrom.applyStartTime = !e ? '' : e[0]
          this.inputFrom.applyEndTime = !e ? '' : e[1]
          break
        case '用车时间':
          this.inputFrom.useStartTime = !e ? '' : e[0]
          this.inputFrom.useEndTime = !e ? '' : e[1]
          break
        case '客人到达时间':
          this.inputFrom.guestArrivalStartTime = !e ? '' : e[0]
          this.inputFrom.guestArrivalEndTime = !e ? '' : e[1]
          break
        case '委外时间':
          this.inputFrom.outsourceStartDate = !e ? '' : e[0]
          this.inputFrom.outsourceEndDate = !e ? '' : e[1]
          break
        case '设备返厂时间':
          this.inputFrom.returnRepairStartDate = !e ? '' : e[0]
          this.inputFrom.returnRepairEndDate = !e ? '' : e[1]
          break
        case '考勤日期':
          this.inputFrom.startDate = !e ? '' : e[0]
          this.inputFrom.endDate = !e ? '' : e[1]
          break
        case '统计日期':
          this.inputFrom.startDate = !e ? '' : e[0]
          this.inputFrom.endDate = !e ? '' : e[1]
          break
        case '试用到期时间':
          this.inputFrom.probationaryStartDate = !e ? '' : e[0]
          this.inputFrom.probationaryEndDate = !e ? '' : e[1]
          break
      }
      this.getTableList()
    },
    // 节假日日期配置
    async getholidayMainList() {
      const res = await queryHolidayMainPageList({
        page: 1,
        limit: 999,
      })
      this.holidayConfigMainList = res.data.list
    },
    // 获取部门下拉
    async getDeptDropdown(params) {
      const res = await getDeptDropdown(params)
      this.DeptList = res.data
      if (params.level == 2) {
        this.businessUnitList = res.data
      }
    },
    // 通过事业部id查询
    async getDeptDropdownWord(params) {
      const res = await getDeptDropdown(params)
      this.workshopIdsList = res.data
    },
    // 通过车间id查询班组
    async getDeptDropdownTeam(params) {
      const res = await getDeptDropdown(params)
      this.teamIdsList = res.data
    },
    async getParentDeptList() {
      const res = await getDeptDropdown({ level: 2 })
      this.parentDeptList = res.data
    },
    async getParentDeptListPositions() {
      const res = await getDeptDropdown({ level: '1,2,3' })
      this.parentDeptListPositions = res.data
    },
    // 获取调出部门树
    async getOrgDeptIds() {
      const res = await getDeptDropdown()
      this.orgDeptIdsList = res.data
    },
    // 获取调出班组
    async getOrgTeamIds() {
      const res = await getDeptDropdown({
        parentId: this.inputFrom.orgDeptIds[0],
      })
      this.orgTeamIdsList = res.data
      if (this.orgTeamIdsList.length == 0) {
        this.getOrgPositionIdsList()
      }
    },
    // 根据调出部门id获取岗位
    async getOrgPositionIdsList() {
      if (!this.inputFrom.orgDeptIds || !this.inputFrom.orgTeamIds) {
        this.orgPositionIdsList = []
      } else {
        let params = {}
        if (
          this.inputFrom.orgDeptIds &&
          this.inputFrom.orgDeptIds.length == 1
        ) {
          params.deptId = this.inputFrom.orgDeptIds[0]
        } else if (
          this.inputFrom.orgTeamIds &&
          this.inputFrom.orgTeamIds.length == 1
        ) {
          params.deptId = this.inputFrom.orgTeamIds[0]
        }
        const res = await getAllPositionByDeptId(params)
        this.orgPositionIdsList = res.data
      }
    },
    async getTeamIds() {
      const res = await getDeptDropdown({
        parentId: this.inputFrom.deptIds[0],
      })
      this.teamIdsList = res.data
      if (this.teamIdsList.length == 0) {
        this.getAllPositionByDeptId(1)
      }
    },
    async queryAllShfitManage() {
      const res = await queryAllShfitManage()
      this.classesList = res.data
    },
    // 根据部门id查询岗位
    async getAllPositionByDeptId(val) {
      if (this.inputFrom.deptIds && !this.inputFrom.deptIds.length) {
        return (this.inputFrom.positionIds = [])
      }
      let params = {}
      if (val == 1) {
        params.deptId = this.inputFrom.deptIds[0]
      }
      const res = await getAllPositionByDeptId(params)
      this.positionList = res.data
    },
    // 菜单右键
    contextMenuClickEvent({ menu, row, column }) {
      switch (menu.code) {
        case 'rest':
          // 示例
          this.getTableList()
          break
        case 'edit':
          // 示例
          this.$emit('edit', row)
          break
        case 'del':
          // 示例
          this.$emit('del', row)
          break
        case 'copy':
          // 示例
          if (row && column) {
            this.copyToClipboard(this.copyText)
          }

          break
        case 'paste':
          this.inputFrom[column.field] = this.copyText
          this.inputFrom = { ...this.inputFrom }
        default:
        // this.$XModal.message(`点击了 ${menu.name} 选项`)
      }
    },

    handleContextmenu(e) {
      if (e.target.className !== 'el-input__inner') {
        this.copyText = e.target.textContent
      }
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        // 检查浏览器是否支持剪贴板 API
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.$message({ message: '内容已复制到剪贴板', type: 'success' })
          })
          .catch((err) => {
            console.error('无法复制内容到剪贴板:', err)
          })
      } else {
        // 如果浏览器不支持剪贴板 API，可以使用其他方法，比如创建一个临时的 textarea 并选中复制
        const textArea = document.createElement('textarea')
        textArea.style.position = 'fixed'
        textArea.style.top = 0
        textArea.style.left = 0
        textArea.style.width = '2em'
        textArea.style.height = '2em'
        textArea.style.padding = 0
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'
        textArea.style.background = 'transparent'
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          const successful = document.execCommand('copy')
          const msg = successful ? '成功复制' : '复制失败'
          this.$message({
            message: msg,
            type: successful ? 'success' : 'warning',
          })
        } catch (err) {}

        document.body.removeChild(textArea)
      }
    },
    getDeptIds(e) {
      this.inputFrom.deptIds = e
      this.getTableList()
    },
    // 联络通知单状态下拉
    async queryCommunicationOrderDropdown() {
      const res = await queryCommunicationOrderDropdown()
      this.recruitmentMethodList = res.data.checkStatusList?.map((item) => {
        return {
          name: item,
          code: item,
        }
      })
    },
    getTableList(pageType) {
      this.options.tableLoading = true
      let params = {
        // type: 1,
        limit: this.tablePage1.pageSize,
        ...this.inputFrom,
        ...this.searchForm,
        visitPath: this.$route.path,
      }
      if (pageType == 2) {
        params.page = this.tablePage1.currentPage
      } else {
        this.tablePage1.currentPage = 1
        params.page = 1
      }
      http.post(this.url, params).then((data) => {
        let dataList = data.data.list || data.data
        this.options.dataList = dataList
        this.tablePage1.totalResult = data.data.total
        // if (data.data.pageNum !== 1 && data.data.size === 0) {
        //   this.tablePage1.currentPage -= 1
        //   this.getTableList()
        // }
        let kongString = 'id'
        let obj = {}
        obj[kongString] = null
        if (this.options.dataList) this.options.dataList.unshift(obj) // 插入一个空对象
        this.options.tableLoading = false
        // 修改错位问题

        this.$nextTick(() => {})
        this.$emit('clearUpdate')
      })

      this.$refs.xTable1.clearCheckboxRow()
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.tablePage1.currentPage = currentPage
      this.tablePage1.pageSize = pageSize
      this.getTableList(2)
    },
    //拿到多选选中的数据
    selectChangeEvent(checked) {
      console.log(checked.rowIndex, checked.checked, 89898)
      let records = []
      if (checked.rowIndex == 0) {
        if (checked.checked) {
          // console.log(324234)
          // this.$refs.xTable1.toggleAllCheckboxRow()
          for (var i = 0; i < this.options.dataList.length; i++) {
            this.$refs.xTable1.setCheckboxRow(this.options.dataList[i], true)
          }
        } else {
          for (var i = 0; i < this.options.dataList.length; i++) {
            this.$refs.xTable1.setCheckboxRow(this.options.dataList[i], false)
          }
        }
      }
      records = this.$refs.xTable1.getCheckboxRecords()
      this.$emit('selectArr', records)
    },
    radioChangeEvent({ row }) {
      this.$emit('selectRadio', row)
    },
    clear() {
      this.createDate = []
      this.updateDate = []
      this.useTime = []
      this.guestArrivalTime = []
      this.applyDate = []
      this.inputFrom = {}
      this.dateList = {}
      this.$emit('clear')
      this.getTableList()
    },
    cellClick(scope) {
      if (scope.rowIndex == 0) return
      this.$emit('dataReceived', scope.row)
    },

    cellDblclick(scope) {
      this.$emit('ablclickFn', scope.row)
    },
    __calculateHeight() {
      // 根据屏幕高度算表格高度
      if (window.innerHeight < 800) {
        this.options.maxHeight = 350
        this.options.height = '100%'
        this.options.minHeight = '96%'
      } else {
        this.options.maxHeight = 500
        this.options.height = '100%'
        this.options.minHeight = '96%'
      }
    },
    // 合并行
    mergeRowMethod({ row, _rowIndex, column, visibleData }) {
      if (!this.mergeRow) return
      // console.log(column.field)
      const fields = [this.mergeRow]
      const cellValue = row[column.field]
      if (cellValue && fields.includes(column.field)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.field] === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.field] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    rowStyle({ row }) {
      if (row.redFlag) {
        return {
          // backgroundColor: '#F56C6C',
          color: '#F56C6C',
        }
      }
      if (row.contractStatus == '0602' && this.isColor) {
        return {
          // backgroundColor: '#F56C6C',
          color: '#F56C6C',
        }
      }
      if (row.status == '4101' && this.isColor) {
        return {
          // backgroundColor: '#67C23A',
          color: '#67C23A',
        }
      }
      if (row.status == '4103' && this.isColor) {
        return {
          // backgroundColor: '#F56C6C',
          color: '#F56C6C',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mytable-scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 5px;
  // }
}
.baseDataList {
  position: relative;
  height: calc(100% - 100px);
  ::-webkit-scrollbar {
    // fixed固定，改变底部滑轮样式
    position: fixed;
    bottom: 0;
  }
  ::v-deep .el-card__body {
    height: 100%;
  }
  ::v-deep tbody {
    tr:nth-child(1) {
      background-color: #fff;
      // height: 48px !important;
      td {
        height: 40px !important;
      }

      .c--tooltip {
        padding: 0;
      }
      .vxe-tree-cell {
        padding: 0;
      }

      .vxe-cell {
        // &:nth-child(1) {
        //   height: 100%;
        // }
        padding: 0 !important;
        .vxe-cell--tree-node {
          width: 100%;
          .vxe-tree-cell {
            flex: 1;
          }
        }
      }
      .el-input__inner {
        border: 0;
        &:focus {
          border: 2px solid #409eff;
          border-radius: 0;
        }
      }
    }
  }
}
.buttons {
  margin-bottom: 20px;
}
::v-deep .vxe-table .vxe-body--column.col--ellipsis {
  height: 50px;
  line-height: 34px;
}

//::v-deep .vxe-table .vxe-body--column.col--ellipsis {
//  height: 34px;
//  line-height: 34px;
//}

::v-deep .vxe-cell--tree-node {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

::v-deeep .vxe-cell {
  width: 100%;
  display: flex !important;
  align-items: center;
}
::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .vxe-body--column {
  border-left: 1px solid rgb(244, 245, 247) !important;
  border-bottom: 1px solid rgb(244, 245, 247) !important;
}
::v-deep th.vxe-header--column {
  border-left: 1px solid #ccc !important;
  border-bottom: 2px solid rgb(244, 245, 247) !important;
}

::v-deep .vxe-table--resizable-bar {
  border: 1px solid rgb(63, 158, 255) !important;
}
::v-deep .vxe-table--fixed-right-wrapper {
  height: 99% !important;
}
::v-deep .vxe-table--fixed-left-wrapper {
  height: 99% !important;
}
::v-deep .vxe-pager {
  height: 0;
}
::v-deep .el-range-separator {
  width: fit-content;
}
.export_btn {
  position: absolute;
  top: -38px;
}
.vxe_toolbar_box {
  margin-top: -51px;
  width: 200px;
  float: right;
}
</style>
