export default {
  // 开启命名空间
  namespaced: true,
  // 放数据的地方
  state: {
    user: '',
    editableTabs: [
      //默认tabs
      {
        text: '首页', //label
        id: '000', // name 唯一
        url: '/defaultPage/defaultPage', // 路径
      },
    ],
    newTabs: {},
    loginInfo: JSON.parse(localStorage.getItem('loginInfo')) || {},
    taskCount: 0,
  },
  // 相当于组件中计算属性
  getters: {},
  // 这个是唯一修改state中数据地方
  mutations: {
    getTaskCount(state, taskCount) {
      state.taskCount = taskCount
    },
    login(state, user) {
      state.user = user
      localStorage.setItem('userInfo', user)
    },
    // 退出
    logout(state) {
      state.user = ''
      localStorage.setItem('userInfo', '')
      localStorage.removeItem('_codeData')
      state.editableTabs = [
        //默认tabs
        {
          text: '首页', //label
          id: '000', // name 唯一
          url: '/defaultPage/defaultPage', // 路径
        },
      ]
    },
    // pushAbleTabs(state) {},
    // setCount(state) {}
  },
  // 写异步的ajax的地方
  actions: {
    // store.commit()
    // getData(store){}
  },
}
