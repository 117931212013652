import Vue from 'vue'
// 引入element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//  公共样式
import '@/assets/scss/common.scss'
import App from './App'
// 引入路由
import router from '@/router'
// 引入状态管理
import store from '@/store'
// 引入icon
import '@/assets/icon/iconfont.css'
import '@/assets/iconcont/iconfont.css'
import Print from 'vue-print-nb'
Vue.use(Print)
//引入echarts
import * as echarts from 'echarts'
//vue全局注入echarts
Vue.prototype.$echarts = echarts
// 右键操作菜单
import Contextmenu from 'vue-contextmenujs'
Vue.use(Contextmenu)
import '@/utils/directive'
// import directive from '@/utils/directive'
// Vue.use(directive)
// import Rsa from "@/utils/rsa.js" //引入RSA加密方法；
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
// ...
// excel样式
import 'handsontable/dist/handsontable.full.min.css'
// 公共组件引入
import myDialog from '@/components/myDialog.vue'
import baseCard from '@/components/baseCard.vue'
import TableData from '@/components/tableData/index.vue'
import MultipleSelect from '@/components/multipleSelect/index.vue'
import NoticeMultipleSelect from '@/components/multipleSelect/noticeMultipleSelect.vue'
Vue.component('mydialog', myDialog)
Vue.component('baseCard', baseCard)
Vue.component('TableData', TableData)
Vue.component('MultipleSelect', MultipleSelect)
Vue.component('NoticeMultipleSelect', NoticeMultipleSelect)
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const requireComponent = require.context(
  '@/components/base',
  false,
  /\.vue$/
  // 找到components文件夹下以.vue命名的文件
)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)

  const componentName = capitalizeFirstLetter(
    fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})
import $oss from '@/assets/lib/oss'
Vue.use($oss)

//qs
import qs from 'qs'
Vue.prototype.$qs = qs

// 使用element UI
Vue.use(ElementUI)
// 过滤器
import * as custom from '@/utils/util'
// 加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
})

Vue.config.productionTip = false

Object.keys(custom).forEach((key) => {
  Vue.filter(key, custom[key])
})
/**
 * 1.地址栏输入 重定向到登录?首页:登录
 */
// 路由拦截器
router.beforeEach((to, from, next) => {
  localStorage.setItem('fromUrl', from.meta.esName || null)
  localStorage.setItem('toUrl', to.path)
  let haveUrls
  NProgress.start()
  // 默认页面不做权限控制 直接方行
  if (to.fullPath == ('/login' || '/' || '/index')) {
    haveUrls = true
  }
  if (to.path == '/login') {
    if (localStorage.getItem('accessToken')) {
      console.log('还有token')
      return
    }
  }
  if (to.matched.length != 0) {
    if (to.meta.requireAuth && haveUrls) {
      // 判断是否有权限跳转该路由
      if (localStorage.getItem('userInfo')) {
        // 通过vuex state?? 获取当前的user是否存在
        localStorage.setItem('fromRoute', JSON.stringify(from))
        localStorage.setItem('toRoute', JSON.stringify(to))
        next()
      } else {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath,
          }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    } else {
      console.log('重定向 如果当前无权限访问该页面直接转首页或登录')
      if (localStorage.getItem('userInfo')) {
        // 判断是否登录
        if (to.path != '/' && to.path != '/login' && haveUrls) {
          //判断是否要跳到登录界面
          next()
        } else {
          /**
           * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
           */
          let list = JSON.parse(localStorage.getItem('_menutile'))
          if (list) {
            let data = list.find((ele) => ele.url == to.path)
            if (data) {
              if (
                store.state.user.editableTabs.find((item) => item.id == data.id)
              ) {
                // console.log('相同')
              } else {
                data.url = to.fullPath
                store.state.user.editableTabs.push(data)
              }
              store.state.user.newTabs = data
            } else {
              if (to.path == '/defaultPage/defaultPage') {
                store.state.user.newTabs = {
                  id: '000',
                  url: '/defaultPage/defaultPage',
                  icon: 'icon',
                  children: null,
                }
              }
            }
          }
          next()
        }
      } else {
        console.log('无信息无权限 跳转登录页面')
        if (!haveUrls) {
          next({
            path: '/login',
            // 将跳转的路由path作为参数，登录成功后跳转到该路由
          })
          console.log('暂无权限，如需要查看该页面请重新登录')
        } else {
          next()
        }
      }
    }
  } else {
    // next({
    //   path: '/login',
    //   query: {
    //     redirect: to.fullPath,
    //   }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
    // })
  }
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   store, //使用store vuex状态管理
//   components: {
//     App,
//   },
//   template: '<App/>',
//   data: {
//     // 空的实例放到根组件下，所有的子组件都能调用
//     Bus: new Vue(),
//   },
// })
//runtime

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue(),
  },
}).$mount('#app')
