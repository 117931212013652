/* 引入jsencrypt实现数据RSA加密 */
// import  JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 引入encryptlong实现数据RSA加密 */
// import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。
import JSEncrypt from 'jsencrypt-ext' //分段加密解密
const CryptoJS = require('crypto-js') //引用AES源码js

let publicKey = localStorage.getItem('pubKey')

export default {
  /* JSEncrypt加密 */
  encrypt(data) {
    if (!publicKey) {
      publicKey = localStorage.getItem('pubKey')
    }
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
  },
  // aes
  //aes加密
  aesEncrypt(k, i, text) {
    const key = CryptoJS.enc.Utf8.parse(k)
    const iv = CryptoJS.enc.Utf8.parse(i)
    // const t = CryptoJS.enc.Utf8.parse(text);
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    let str
    if (typeof text === 'string') {
      str = encrypted.toString()
    } else {
      str = encrypted.toString()
    }
    return str
  },
  // aes解密
  aesDecrypt(k, i, base64) {
    const key = CryptoJS.enc.Utf8.parse(k)
    const iv = CryptoJS.enc.Utf8.parse(i)
    const encrypted = CryptoJS.AES.decrypt(base64, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    return encrypted.toString(CryptoJS.enc.Utf8)
  },
}
