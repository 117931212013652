/**
 * oss对象存储封装
 *
 * 作者：朱柯欣
 * 最后一次更新时间：2021年3月2日
 *
 * function ossPut(){} 普通上传
 * function ossUploadFile(){} 分片上传
 * function ossDelete（）{} 删除文件
 * function deleteMulti(){} 删除多个文件
 */
import oss from 'ali-oss'

const ossConfig = {
  environment: 'dev', //当前接口环境
  fileName: {
    dev: 'wx_mall_dev/',
    prod: 'wx_mall/',
  },
}

const ossClient = new oss({
  region: 'oss-cn-shenzhen',
  accessKeyId: 'LTAI4FdmdMuLx1Av5y29suKg',
  accessKeySecret: 'fuGDW9dk5ucEMvtQNAWTz1U7UTAeL1',
  bucket: 'webplus-cn-shenzhen-s-5d71f33334cffe1418b7ea6c',
  secure: true,
})

/**
 * 创建随机字符串
 * @param num
 * @returns {string}
 */
function randomString(num) {
  const chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  let res = ''
  for (let i = 0; i < num; i++) {
    var id = Math.ceil(Math.random() * 35)
    res += chars[id]
  }
  return res
}

function dateFormat(dateObj, format) {
  const date = {
    'M+': dateObj.getMonth() + 1,
    'd+': dateObj.getDate(),
    'h+': dateObj.getHours(),
    'm+': dateObj.getMinutes(),
    's+': dateObj.getSeconds(),
    'q+': Math.floor((dateObj.getMonth() + 3) / 3),
    'S+': dateObj.getMilliseconds(),
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (const k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? date[k]
          : ('00' + date[k]).substr(('' + date[k]).length)
      )
    }
  }
  return format
}

const $ossClient = {
  list: function (prefix, marker, maxKeys) {
    return new Promise((resolve, reject) => {
      // 列举前缀为'my-'且在'my-object'之后的文件。
      ossClient
        .list({
          prefix: ossConfig.fileName[ossConfig.environment] + prefix, //'my-'
          marker: marker, //'my-object'
          'max-keys': maxKeys,
        })
        .then((res) => {
          let nextMarker = res.nextMarker
          return resolve({
            code: 200,
            data: res.objects,
            nextMarker: nextMarker,
          })
        })
        .catch(function (err) {
          return reject(console.error('error: %j', err))
        })
    })
  },
  //普通上传
  ossPut: function (name, file, prefix, index) {
    const date = dateFormat(new Date(), 'yyyyMMdd') // 当前时间
    const dateTime = dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
    const randomStr = randomString(4)
    const extensionName = file.name.substr(file.name.indexOf('.')) // 文件扩展名
    const fileName =
      ossConfig.fileName[ossConfig.environment] +
      prefix +
      dateTime +
      '_' +
      randomStr +
      extensionName // 文件名字（相对于根目录的路径 + 文件名）
    return new Promise((resolve, reject) => {
      ossClient
        .put(fileName, file.raw, {
          headers: {
            'Cache-Control': 'max-age=2592000',
          },
        })
        .then((r1) => {
          if (r1.res.statusCode === 200) {
            let url = r1.url.replace(
              /webplus-cn-shenzhen-s-5d71f33334cffe1418b7ea6c.oss-cn-shenzhen.aliyuncs.com/,
              'cdn.k9iris.com'
            )
            // url = url.replace('https',"http");
            return resolve({ code: 200, data: url, index: index })
          } else {
            return reject({ code: 400, msg: '上传图片失败！' })
          }
        })
        .catch(function (err) {
          return reject(console.error('error: %j', err))
        })
    })
  },
  //分片上传
  ossUploadFile: function (option) {
    const file = option.file
    return new Promise((resolve, reject) => {
      const date = dateFormat(new Date(), 'yyyyMMdd') // 当前时间
      const dateTime = dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
      const randomStr = randomString(4)
      const extensionName = file.name.substr(file.name.indexOf('.')) // 文件扩展名
      const fileName =
        ossConfig.fileName[ossConfig.environment] +
        'video/' +
        dateTime +
        '_' +
        randomStr +
        extensionName // 文件名字（相对于根目录的路径 + 文件名）
      try {
        // let fileName1 = "wx_mall/video/20191113/20191113165406_siag.mp4";
        ossClient
          .multipartUpload(fileName, option.file, {
            progress: function (p) {
              const e = {}
              e.percent = Math.floor(p * 100)
              option.onProgress(e)
            },
          })
          .then((res) => {
            if (res.res.statusCode === 200) {
              let url = res.res.requestUrls[0]
              let value = url.lastIndexOf('?uploadId=')
              let result = url.substring(0, value)
              let url1 = result.replace(
                /webplus-cn-shenzhen-s-5d71f33334cffe1418b7ea6c.oss-cn-shenzhen.aliyuncs.com/,
                'cdn.k9iris.com'
              )
              // url1 = url1.replace('https',"http");
              return resolve({
                code: 200,
                data: url1,
              })
            } else {
              return reject({
                code: 400,
                msg: '上传失败',
              })
            }
          })
      } catch (e) {
        // 捕获超时异常
        if (e.code === 'ConnectionTimeoutError') {
          console.log('Woops,超时啦!')
          return reject({ msg: '上传图片失败！' })
        }
        console.log(e)
      }
    })
  },
  //删除文件
  ossDelete: function (url, prefix) {
    let pre = ''
    if (prefix) {
      pre = prefix
    }
    let index = url.lastIndexOf('/')
    let name = url.substring(index + 1, url.length)
    return new Promise((resolve, reject) => {
      let fileName = ossConfig.fileName[ossConfig.environment] + pre + name
      ossClient.delete(fileName).then((res) => {
        console.log(res)
      })
      let result = ossClient.delete(fileName)

      return resolve(result)
    })
  },
  //删除多个文件
  deleteMulti: function (files, prefix) {
    let filesList = []
    files.forEach((item) => {
      let index = item.lastIndexOf('/')
      let name =
        ossConfig.fileName[ossConfig.environment] +
        prefix +
        item.substring(index + 1, item.length)
      filesList.push(name)
    })
    return new Promise((resolve, reject) => {
      let result = ossClient.deleteMulti(filesList, { quiet: true })
      return resolve(result)
    })
  },
}

export default $ossClient
