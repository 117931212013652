<template>
  <!-- <div>
    <button @click="connect">连接</button>
    <button @click="disconnect">断开</button>
    <input v-model="message" @keyup.enter="sendMessage" />
    <button @click="sendMessage">发送消息</button>
    <ul>
      <li v-for="(msg, index) in messages" :key="index">{{ msg }}</li>
    </ul>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      message: '',
      messages: [],
      socket: null,
    }
  },
  created() {
    this.connect()
  },
  methods: {
    connect() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        console.log('WebSocket is already connected.')
        return
      }
      this.socket = new WebSocket(
        process.env.VUE_APP_WEBSOCKETURL +
          '/CGP-OA/websocket/' +
          this.$store.state.user.loginInfo.code
      )
      this.setupWebSocket()
    },
    disconnect() {
      if (this.socket) {
        this.socket.close()
      }
    },
    setupWebSocket() {
      // 监听WebSocket的open事件
      this.socket.addEventListener('open', (event) => {
        console.log('WebSocket connection opened:', event)
      })

      // 监听WebSocket的message事件
      this.socket.addEventListener('message', (event) => {
        // console.log('WebSocket message received:', event)
        let data = JSON.parse(event.data)
        // console.log(data, 'websocket内容')
        if (!data.type) {
          this.$store.commit('user/getTaskCount', data.taskCount)
        } else {
          this.$notify({
            title: data.type == 5801 ? '您有新的待办消息' : '你有新的公告消息',
            // dangerouslyUseHTMLString: true,
            message: data.taskDescription,
            position: 'bottom-right',
            onClick: () => {
              if (data.type == 5801) {
                if (this.$route.path != '/allAtters/pendMatters') {
                  this.$router.push({
                    path: '/allAtters/pendMatters',
                  })
                }
              } else if (data.type == 5802) {
                if (this.$route.path != '/notice/noticeForm') {
                  this.$router.push({
                    path: '/notice/noticeForm',
                    query: {
                      type: 'view',
                      id: data.id,
                    },
                  })
                }
              }
            },
          })
        }
      })

      // 监听WebSocket的close事件
      this.socket.addEventListener('close', (event) => {
        console.log('WebSocket connection closed:', event)
      })

      // 监听WebSocket的error事件
      this.socket.addEventListener('error', (event) => {
        console.error('WebSocket error observed:', event)
      })
    },
    sendMessage() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(this.message)
        this.message = ''
      } else {
        console.error('WebSocket is not open. Cannot send message.')
      }
    },
  },
  beforeDestroy() {
    this.disconnect()
  },
}
</script>
