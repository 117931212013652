import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import wdstore from './modules/wdstore'
import nav from './modules/nav'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    // 登录
  },
  actions: {},
  modules: {
    user,
    wdstore,
    nav,
  },
})
