<template>
  <el-container class="index-con">
    <el-header class="index-header">
      <navcon
        @close="closeAll()"
        @closeOther="closeOther()"
        @searchMenu="searchMenu"
        :menuList="menuList"
      ></navcon>
    </el-header>
    <el-container>
      <el-aside :class="showclass">
        <div style="height: 100%; width: 100%; overflow: hidden">
          <div style="height: 100%; width: 103%; overflow: scroll">
            <leftnav
              @clickMenu="clickMenu"
              :asideshow="showclass"
              :menuList="menuList"
            ></leftnav>
          </div>
        </div>
      </el-aside>
      <div class="navAesize" title="收缩侧边栏"></div>
      <el-main class="main_view">
        <div @contextmenu.prevent="onContextmenu" class="mainTabs">
          <el-tabs
            class="indexTabs"
            v-model="editableTabsValue"
            type="card"
            @tab-remove="removeTab"
            @tab-click="tabClick"
          >
            <el-tab-pane
              v-for="item in $store.state.user.editableTabs"
              :closable="item.id == '000' ? false : true"
              :key="item.id"
              :label="item.text"
              :name="item.id"
              v-loading="loading"
            >
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="main-body">
          <keep-alive :include="cacheList">
            <router-view></router-view>
          </keep-alive>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// 导入组件
import navcon from '../components/navcon.vue'
import leftnav from '../components/leftnav.vue'
import { getUserMenuBtn, saveTraceAccessRecord } from '@/api/ajax/request'
import { postAction } from '@/api/ajax/index'

import store from '@/store'
export default {
  name: 'index',
  data() {
    return {
      cacheList: [], // tabs路由缓存
      menuList: [],
      showclass: 'asideshow', //侧边是否展开
      showtype: false, //侧边栏状态
      editableTabsValue: '000', //当前tab的选中 string
      loading: false,
      contextMenuId: '',
      contextMenuData: [
        {
          label: '关闭左侧页面',
          icon: 'iconfont icon-zhihang',
          onClick: () => {
            this.onRunCodeNewTab(1)
          },
        },
        {
          label: '关闭右侧页面',
          icon: 'iconfont icon-sql_exec_new',
          onClick: () => {
            this.onRunCodeNewTab(2)
          },
        },
        {
          label: '只保留当前页面',
          icon: 'iconfont icon-sql_exec_new',
          onClick: () => {
            this.onRunCodeNewTab(3)
          },
        },
        {
          label: '关闭全部',
          icon: 'iconfont icon-sql_exec_new',
          onClick: () => {
            this.onRunCodeNewTab(4)
          },
        },
      ],
    }
  },
  // 注册组件
  components: {
    navcon,
    leftnav,
  },
  watch: {
    a: {
      handler(val, old) {
        this.editableTabsValue = val.id
      },
      deep: true,
    },
    '$store.state.nav.isOpenNav': {
      handler(value) {
        if (!value) {
          this.showclass = 'asideshow'
        } else {
          // setTimeout(() => {
          this.showclass = 'aside'
          // }, 300)
        }
      },
      immediate: true,
    },
    '$store.state.user.editableTabs': {
      handler(value) {
        if (value) {
          this.cacheList = value.map((item) => {
            return item.url.split('?')[0]
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    a() {
      return this.$store.state.user.newTabs
    },
  },

  created() {
    this.postAction()
    // 监听
    // this.$root.Bus.$on('$store.state.nav.isOpenNav', (value) => {
    //   if (value) {
    //     this.showclass = 'asideshow'
    //   } else {
    //     // setTimeout(() => {
    //     this.showclass = 'aside'
    //     // }, 300)
    //   }
    // })
  },
  mounted() {
    this.dragControllerDiv()
  },
  methods: {
    async postAction() {
      let params = {
        userId: JSON.parse(localStorage.getItem('loginInfo')).id,
        parentNumber: '0',
      }
      const res = await postAction(params)
      this.menuList = res.data
      let list = []
      let fn = (arr) => {
        if (JSON.stringify(arr) == '[]' || !arr) return
        arr.forEach((item) => {
          if (item.children && item.children.length) {
            fn(item.children)
          } else {
            item.id += ''
            list.push(item)
          }
        })
      }
      fn(res.data)
      localStorage.setItem('_menutile', JSON.stringify(list))
      localStorage.setItem('menu', JSON.stringify(this.menuList))
    },

    dragControllerDiv() {
      var resize = document.getElementsByClassName('navAesize')
      var left = document.getElementsByClassName('asideshow')
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function (e) {
          //颜色改变提醒
          resize[i].style.background = '#818181'
          var startX = e.clientX //303
          resize[i].left = resize[i].offsetLeft
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            var endX = e.clientX
            // resize[i].style.width = endX
            var moveLen = resize[i].left + (endX - startX) // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
            // var maxT = box[i].clientWidth - resize[i].offsetWidth // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

            if (moveLen > 300) moveLen = 300 // 左边区域的最小宽度为64px
            if (moveLen < 200) moveLen = 200 // 左边区域的最小宽度为64px
            // if (moveLen > maxT - 500) moveLen = maxT - 500 //右边区域最小宽度为500px
            store.commit('nav/getNavWidth', moveLen)
            resize[i].style.left = moveLen // 设置左侧区域的宽度
            for (let j = 0; j < left.length; j++) {
              left[j].style.width = moveLen + 'px'
              // mid[j].style.width = box[i].clientWidth - moveLen - 10 + 'px'
            }
          }
          // 鼠标松开事件
          document.onmouseup = function (evt) {
            //颜色恢复
            resize[i].style.background = '#d6d6d6'
            document.onmousemove = null
            document.onmouseup = null
            resize[i].releaseCapture && resize[i].releaseCapture() //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          }
          resize[i].setCapture && resize[i].setCapture() //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false
        }
      }
    },
    onContextmenu(event) {
      let ids = event.target.id
      if (ids) {
        this.contextMenuId = ids.split('-')[1]
        this.$contextmenu({
          items: this.contextMenuData,
          event, // 鼠标事件信息
          customClass: 'custom-class', // 自定义菜单 class
          zIndex: 999, // 菜单样式 z-index
          minWidth: 150, // 主菜单最小宽度
        })
        return false
      } else {
      }
    },
    onRunCodeNewTab(type) {
      let tabIndex
      if (type === 1) {
        tabIndex = this.$store.state.user.editableTabs.findIndex(
          (item) => item.id == this.contextMenuId
        )
        if (tabIndex == 0) {
        } else {
          this.$store.state.user.editableTabs.splice(1, tabIndex - 1)
        }
      } else if (type == 2) {
        tabIndex = this.$store.state.user.editableTabs.findIndex(
          (item) => item.id == this.contextMenuId
        )
        if (tabIndex == this.$store.state.user.editableTabs.length - 1) {
        } else {
          this.$store.state.user.editableTabs.splice(
            tabIndex + 1,
            this.$store.state.user.editableTabs.length - 1
          )
        }
      } else if (type == 3) {
        let obj = this.$store.state.user.editableTabs.find(
          (item) => item.id == this.contextMenuId
        )
        if (obj) {
          this.$store.state.user.editableTabs = [
            {
              text: '首页', //label
              id: '000', // name 唯一
              url: '/defaultPage/defaultPage', // 路径
            },
          ]
          this.$store.state.user.editableTabs.push(obj)
        }
      } else if (type == 4) {
        let obj = this.$store.state.user.editableTabs.find(
          (item) => item.id == this.contextMenuId
        )
        if (obj) {
          this.$store.state.user.editableTabs = [
            {
              text: '首页', //label
              id: '000', // name 唯一
              url: '/defaultPage/defaultPage', // 路径
            },
          ]
          this.$router.push({
            path: '/defaultPage/defaultPage',
          })
        }
      }
    },
    closeOther() {
      this.loading = true
      let fullpath = {}
      //  本地目录 和 模拟请求目录 差'/'
      fullpath.path = this.$route.path.slice(1)
      fullpath.name = this.$route.name
      let data = this.$store.state.user.editableTabs.filter((item) => {
        item.url == fullpath.path && item.text == fullpath.name
      })
      // 第一位只能是首页
      if (data[0].url != '/defaultPage/defaultPage' && data[0].text != '首页') {
        data.unshift({
          text: '首页',
          id: '000',
          url: '/defaultPage/defaultPage',
        })
      }
      this.$store.state.user.editableTabs = data
      this.editableTabsValue = data[1].id
      this.$router.push({ path: '' + data[1].url }, () => {})
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    closeAll() {
      this.loading = true
      this.$store.state.user.editableTabs = [
        {
          text: '首页',
          id: '000',
          url: '/defaultPage/defaultPage',
        },
      ]
      this.editableTabsValue = '000'
      this.$router.push({ path: '/defaultPage/defaultPage' }, () => {})
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 获取按钮权限
    async getUserMenuBtn(data) {
      // if (JSON.stringify(data) == '{}' || !data) return
      // console.log(data, '获取按钮权限')
      // const res = await getUserMenuBtn({
      //   menuId: data.id,
      // })
      // if (res.code == 200) {
      //   let btnData = {
      //     btnStr: res.data.btnStr || '',
      //     menuId: res.data.menuId || '',
      //   }
      //   localStorage.setItem('btnManage', JSON.stringify(btnData))
      //   this.$store.commit('wdstore/setBtnManage', btnData)
      // }
      // 上传当前菜单
      // if (data.id == '000') return
      // let params = {
      // eventName: 'MENU_VISIT',
      // path: data.url.split('?')[0],
      // platform: 'PC',
      // accessType: '1',
      // menuId: data.id,
      // }
      // const res2 = await saveTraceAccessRecord(params)
    },
    searchMenu(data) {
      // 搜索返回
      // this.clickMenu(data)
    },
    // 点击侧边项
    clickMenu(routeData) {
      // this.$route.meta.keepAlive = ture
      let data = routeData
      if (data == undefined) data = this.$store.state.user.newTabs
      if (!data && this.$store.state.user.editableTabs.length) return
      let urlData
      // 判断tabs是否已经包含该点击项 包含赋值并选中 不包含则添加
      this.$store.state.user.editableTabs.forEach((item) => {
        if (data.id == item.id && data.text == item.text) {
          urlData = data
          return
        }
      })
      if (urlData) {
        this.editableTabsValue = data.id + ''
      } else {
        if (data && JSON.stringify(data) !== '{}') this.addTab(data)
      }
      console.log(this.$store.state.user.editableTabs, 666666)
      // 按钮权限
      this.getUserMenuBtn(data)
    },
    // 添加tab项
    addTab(data) {
      // this.$store.state.user.editableTabs.push({
      //   text: data.text + '',
      //   id: data.id + '',
      //   url: data.url,
      // })
      this.editableTabsValue = data.id + ''
    },
    // tab点击事件
    tabClick(e) {
      console.log(e, 'tab点击事件')
      let data = this.$store.state.user.editableTabs.filter((item) => {
        return item.text == e.label && item.id == e.name
      })
      let url = '' + data[0].url
      this.$router.push({ path: url }, () => {})
      this.getUserMenuBtn(data[0])
    },
    // tab删除
    removeTab(targetName) {
      this.removeTabAfter(targetName)
    },
    removeTabAfter(targetName) {
      let tabs = this.$store.state.user.editableTabs
      // 判断当前的路由id 遍历如果相等 更新nextTab(下一次选中的tab) 如果有就跳转更新.
      let activeName = this.editableTabsValue
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.id === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.id
              let url = '' + nextTab.url
              this.$router.push({ path: url }, () => {})
              this.getUserMenuBtn(nextTab)
            }
          }
        })
      }
      this.editableTabsValue = activeName
      this.$store.state.user.editableTabs = tabs.filter(
        (tab) => tab.id !== targetName
      )
    },
  },

  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {
    // 弹出登录成功
    // this.$message({
    //   message: "登录成功",
    //   type: "success",
    // });
  },
}
</script>
<style lang="scss" scoped>
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  // .el-tabs.indexTabs {
  //   height: calc(100% - 60px) !important;

  //   .el-tabs__content {
  //     overflow: auto;
  //     height: calc(100% - 60px) !important;
  //   }
  // }
}

.aside {
  width: 64px !important;
  // height: 100%;
  background-color: #fff;
  margin: 0px;
}

.asideshow {
  //  width: 300px !important;
  // height: 100%;
  background-color: #fff;
  margin: 0px;
  padding: 0;
}

.index-header {
  height: 60px !important;
  background-color: #2c68f6;
}
.index-main {
  width: 100%;
  padding: 0px;

  // border-left: 2px solid #333;
}

.tab1Title {
  color: #000;
  font-size: 21px;
  font-weight: bold;
  // border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.el-main {
  padding: 0;
}

.main-body {
  height: calc(100vh - 105px) !important;
  width: 100%;
  overflow: scroll;
  background-color: #f4f4f4;
  padding: 10px 4px 4px 10px;

  ::v-deep .el-card {
    border-radius: 4px;
  }
}
.mainTabs {
  background-color: #fff;
  ::v-deep .el-tabs__header {
    margin: 0 !important;
    padding: 0;
  }
}

.navAesize {
  width: 4px;
  background-color: #d6d6d6;

  &:hover {
    cursor: w-resize;
  }
}
</style>
