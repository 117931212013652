export default {
  // 开启命名空间
  namespaced: true,
  // 放数据的地方
  state: {
    isOpenNav: false,
    navWidth: 0,
  },
  getters: {},
  mutations: {
    changeNav(state) {
      state.isOpenNav = !state.isOpenNav
      console.log(state.isOpenNav)
    },
    getNavWidth(state, wid) {
      state.navWidth = state.navWidth + wid
    },
  },

  actions: {},
}
