<template>
  <div>
    <el-dialog
      :title="title"
      :modal="true"
      :close-on-click-modal="false"
      :visible.sync="visible"
      :append-to-body="true"
      @close="__dialogClose"
      :width="width"
      ref="dialog"
      :modal-append-to-body="false"
      v-dialogDrag
      :top="__reCalcTop()"
    >
      <!-- <el-dialog
      v-dialogDrag
      :modal="true"
      :close-on-click-modal="false"
      :visible.sync="visible"
      :append-to-body="true"
      :title="title"
      @close="__dialogClose"
    > -->
      <transition name="el-fade-in-linear">
        <div
          v-if="this.visible"
          class="el-card__mdgView"
          style="overflow-x: hidden"
          v-bind:style="{ 'max-height': __reCalcHeight() }"
        >
          <slot></slot>
        </div>
      </transition>
    </el-dialog>
  </div>
</template>

<script>
import EventBus from '@/utils/EventBus'
export default {
  data() {
    return {
      visible: false,
      top: null,
    }
  },
  props: {
    title: {
      type: String,
      default: ' ',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '100',
    },
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    __reCalcWidth() {
      if (this.width) {
        return this.width
      }
      if (window.innerWidth >= 1300) {
        return '1100px'
      }
      return '80%'
    },
    __reCalcHeight() {
      if (this.height) {
        return this.height
      }
      return window.innerHeight * 0.7 + 'px'
    },
    __reCalcTop() {
      if (this.top) {
        return this.top
      }
      return window.innerHeight * 0.1 + 'px'
    },
    __dialogClose() {
      EventBus.$emit('dialogClose', true)
      if (this.$listeners['dialogClose']) {
        this.$emit('dialogClose')
      }
    },
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    getVisible() {
      return this.visible
    },
  },
}
</script>
<style scoped>
.draggable-dialog {
  position: absolute !important; /* 确保对话框可以脱离文档流并自由移动 */
  cursor: move; /* 当鼠标悬停在对话框上时显示移动光标 */
}

.draggable-dialog .el-dialog__header {
  cursor: move; /* 在标题栏上也显示移动光标 */
}
::v-deep .el-dialog {
  position: absolute !important; /* 确保对话框可以手动定位 */
}
::v-deep .el-dialog {
  border-radius: 8px;
  left: 20%;
  right: 20%;
}
</style>
